Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.updateMethod = 'PUT';
exports.patchUpdateMethod = 'PATCH';
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfdebitskycard3";
exports.labelBodyText = "cfdebitskycard3 Body";

exports.btnExampleTitle = "CLICK ME";
exports.get25HrDescriptionEndPoint = 'bx_block_cf25hourskycard/hours_sky_cards/show_description';
exports.update25DesciptionEndPoint = 'bx_block_cf25hourskycard/hours_sky_cards/update_description';
exports.updateDebitDescriptionEndPoint = 'bx_block_cfdebitskycard3/debit_sky_cards/update';
exports.updateCustomDescriptionEndPoint = 'bx_block_custom_forms/custom_forms/update_default_custom_sky_card'
exports.getCustomCardDescriptionEndPoint = 'bx_block_custom_forms/custom_forms/default_custom_sky_card';
exports.getDebitCardDescriptionEndPoint = 'bx_block_cfdebitskycard3/debit_sky_cards/default_debit_sky_card';
// Customizable Area End