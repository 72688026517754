import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@material-ui/core";
import {FilterList, MoreVert, Search, AttachFile} from '@material-ui/icons';
import { AddLegtTypeButton, CSSPropertiesMap, CustomDashboardButton, CustomTypography, GeneralParentBox, SearchTextFieldActiveBooking, StatusDot, StyledChip } from "../../../components/src/MuiCustomComponents";
import { Send_Icon } from "../../../components/src/assets";


// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderThreads=() =>{
    const messageArray = [1,1,1,1];
    return <Box className="booking_section" style={webStyles.threadParent}> 
      {this.renderMessageSearhAndFilter()}
      <Box style={webStyles.messageParent}>
        {messageArray.map((_messageOption,msgIndex)=>this.renderMessageOption(msgIndex))}
      </Box>
    </Box>
   }

   renderMessageSearhAndFilter=()=>{
    return <Box className="dash_flex_row" style={{...webStyles.messageAndSend,...webStyles.search_header}}>
      <SearchTextFieldActiveBooking
        fullWidth
        style={webStyles.filterTextfield}
        placeholder="Search By Name & ID..."
        inputProps={{style:{fontSize:'1.3rem'}}}
        InputProps={{
          endAdornment:(
            <InputAdornment position="end">
                <Search style={{color:'#757677',fontSize:'2rem'}}/>
            </InputAdornment>
          )
        }}
      />
      {this.renderFilter()}
    </Box>
   }

   renderFilter=()=>{
    const {isThreadFilterMenuOpen,threadOptions} = this.state;
    return <>
      <CustomDashboardButton className="filterThread" style={webStyles.filterButton} ref={this.threadFilterRef} onClick={()=>this.handleThreadMenuOpenClose(true)}>
              <FilterList style={{fontSize:'2.4rem'}}/>
      </CustomDashboardButton>
      <Popper className="threadMenuPopper" open={isThreadFilterMenuOpen} anchorEl={this.threadFilterRef.current} role={undefined} transition disablePortal placement="bottom-end" style={{marginTop:'0.5rem'}}>
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: 'bottom-end' }}
        >
          <Paper style={webStyles.threadMenu}>
            <ClickAwayListener onClickAway={(_event)=>this.handleThreadMenuOpenClose()}>
              <MenuList autoFocusItem={isThreadFilterMenuOpen} id="menu-list-grow"
               >
                {threadOptions.map(({innerColor,option,outerColor})=> <MenuItem style={webStyles.eachMenuFlex}>
                  <Box>{option}</Box>
                  {innerColor && <StatusDot outerColor={outerColor as string} innerColor={innerColor}/>}
                  
                </MenuItem>
                )}
                
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>    
    </>
   }

   renderMessageOption=(msgIndex:number)=>{
    const borderTop = msgIndex !== 0 ? '1px solid #393D42' : '';
    return <Box style={{...webStyles.eachMessageContainer,borderTop}}>
      <Box style={webStyles.messageBox1}>
          <Box style={webStyles.messageBox1}>
             <StatusDot outerColor="#1B441B" innerColor="#1EC611"/>
             <Avatar style={webStyles.avatar}/>
             {this.renderNameAndLastMessage()}
          </Box>
      </Box>

      <Box style={{...webStyles.nameAndLastMessage,alignItems:'center'}}>
        <Box>11:49</Box>
        <StyledChip label='New'/>
      </Box>

    </Box> 
   }

   renderNameAndLastMessage=()=>{
    return <Box style={webStyles.nameAndLastMessage}>
      <Box style={webStyles.personName}>Jacob Jonas</Box>
      <Box style={webStyles.lastMessageStyle}>the problem is with flight...</Box>
    </Box>
   }

   renderChatContainer=()=>{
    return <Box style={{...webStyles.chatParent,...webStyles.chat_area,...webStyles.inboxChild}}>
        <Box style={webStyles.chat_header}>{this.renderChatContainerHeader()}</Box>
        <Box style={{flexGrow:1}}>{this.renderChatBox()}</Box>
        <Box style={webStyles.chat_footer}>{this.renderMessageTextFieldAndSend()}</Box>
    </Box>
  }

   renderChatContainerHeader=()=>{
    return <Box style={webStyles.chatHeader}>
      <Box style={webStyles.messageBox1}>
        <Avatar style={webStyles.avatar}/>
        <Box style={webStyles.personName}>John Smith</Box>
        <StyledChip label='Guest User' style={webStyles.guestChip}/>
      </Box>

      <Box style={webStyles.messageBox1}>
        <AddLegtTypeButton style={webStyles.closedButtonStyle}>Mark As Closed</AddLegtTypeButton>
        {this.renderChatMenuOptions()}
      </Box>
    </Box>
   }

   renderChatMenuOptions=()=>{
    const {isChatMenuOpen} = this.state;
    return <>
      <IconButton className="chatThread" ref={this.chatMenuOptionRef} onClick={()=>this.handleChatMenuOpenClose(true)}><MoreVert color="primary"/></IconButton>
      <Popper className="chatPopper" open={isChatMenuOpen} anchorEl={this.chatMenuOptionRef.current} role={undefined} transition disablePortal placement="bottom-end" style={{marginTop:'0.5rem'}}>
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: 'bottom-end' }}
        >
          <Paper style={webStyles.threadMenu}>
            <ClickAwayListener onClickAway={(_event)=>this.handleChatMenuOpenClose()}>
              <MenuList autoFocusItem={isChatMenuOpen} id="menu-list-grow"
               >
                <MenuItem style={webStyles.eachMenuFlex}>Mark as Admin</MenuItem>
                <MenuItem style={webStyles.eachMenuFlex}>Mark as Done</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>    
    </>
   }



   renderChatBox=()=>{
    const text = [
      {text:'I’m not able to register my account!',isMine:false},
      {text:'+1 855-935-9538',isMine:false},
      {text:'johnsmith@gmai.com',isMine:false}, 
      {text:'I am checking on it',isMine:true},
    ];
    return <Box style={webStyles.textBoxParent}>
      {text.map((message)=>this.renderTextMessage(message))}
    </Box>
  }

   renderMessageTextFieldAndSend=()=>{
    return <Box style={{...webStyles.messageAndSend}}>
      <SearchTextFieldActiveBooking
          fullWidth
          style={{...webStyles.filterTextfield,borderRadius:"2.5rem"}}
          placeholder="Type your message here..."        
          inputProps={{style:{fontSize:'1rem'}}}
          InputProps={{
            endAdornment:(
              <InputAdornment position="end">
                <IconButton>
                  <AttachFile style={{color:'white',fontSize:'2rem',cursor:'pointer'}}/>
                </IconButton>
              </InputAdornment>
            )
          }}
      />
      <Button style={webStyles.sendIconParent}><Send_Icon/></Button>
    </Box>
   }

   renderTextMessage=({text,isMine}:{text:string,isMine:boolean})=>{
    const textStyle = isMine ? webStyles.myText : webStyles.otherText;
    return <Box style={{...webStyles.textBox,...textStyle}}>
      {text}
    </Box>
   }

   renderHeaderContainer=() =>{
    return <Box className="dash_flex_row">
      <CustomTypography style={{color:'white'}}>Inbox</CustomTypography>
    </Box>
   }

   renderChatParent=()=>{
    return <GeneralParentBox style={{...webStyles.inboxParent}}>
      <Grid container style={{...webStyles.fullHeight,rowGap:"1.5rem"}}>
          <Grid xs={12} sm={12} md={5} lg={4} xl ={4}><Box style={{...webStyles.inboxChild,...webStyles.threadContainer}}>{this.renderThreads()}</Box></Grid>
          <Grid style={webStyles.app_cont} xs={12} sm={12} md={7} lg={8} xl ={8}><Box style={{borderLeft:'1px solid #3E4247',...webStyles.wrapper}}> {this.renderChatContainer()}</Box></Grid>
      </Grid>
    </GeneralParentBox>
   }


  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
     <Box className="booking_section chat_container_parent">
       {this.renderHeaderContainer()}
       {this.renderChatParent()}
     </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start



const webStyles:CSSPropertiesMap = {
  inboxParent:{
    padding : '1rem 0rem',
  },
  fullHeight:{
    height:"100%"
  },
  inboxChild:{
    padding : '0rem 1rem'
  },
  filterButton:{
    height:'3.5rem',
    width:'3.5rem',
    borderRadius:"50%",
    minWidth:'auto'
  },
  filterTextfield:{
    height:"2rem",
    flexDirection:'inherit',
    backgroundColor:'#1A1C1E',
  },
  messageParent:{
    backgroundColor:'#1A1C1E',
    borderRadius:"10px"
  },
  avatar:{
    height:'3rem',
    width:'3rem'
  },
  eachMessageContainer:{
    padding:'1rem',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  messageBox1:{
    display:"flex",
    columnGap:'1rem',
    alignItems:'center'
  },
  nameAndLastMessage:{
    display:'flex',
    flexDirection:'column',
    rowGap:'0.2rem'
  },
  personName:{
    fontSize:'1.3rem',
    fontWeight:'bold'
  },
  lastMessageStyle:{
    color:"#64748B"
  },
  chatParent:{
     display:'flex' ,
     flexDirection:'column',
     rowGap:'1rem',
  },
  chatHeader:{
    backgroundColor:'#1A1C1E',
    padding:'1rem',
    display:'flex',
    justifyContent:'space-between',
    borderRadius:'10px'
  },
  guestChip:{
    fontSize:'1rem',
    fontWeight:100,
    padding:"0.5rem 0.6rem",
    backgroundColor:'#363229',
    color:"#FFD27E"
  },
  closedButtonStyle:{
    fontSize:"1rem",
    height:'2.8rem',
    border:'1px solid #94A3B8',
    color:'white'
  },
  textBoxParent:{
    display:'flex',
    flexDirection:'column',
    rowGap:'0.4rem',
  },
  textBox:{
    padding:'1rem',
    width:'fit-content',
    wordWrap: 'break-word'

  },  
  otherText:{
    backgroundColor:'#1A1C1E',
    borderRadius:"0px 10px 10px 10px",
  },
  myText:{
    backgroundColor:'#FFD27E',
    color:'black',
    alignSelf:'end',
    borderRadius:"10px 0px 10px 10px",
  },
  messageAndSend:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    columnGap:'1.5rem'
  },
  sendIconParent:{
    backgroundColor : '#FFE1A7',
    padding:'0.9rem 1.5rem',
    borderRadius:"2rem"
  },
  threadMenu:{
    backgroundColor:'#2D3034',
    color:'white'
  },
  eachMenuFlex:{
    display:'flex',
    justifyContent:'space-between',
    width:'100%',
    columnGap : "1rem"
  },
  app_cont:{
    height:'75vh',
    display:'flex'
  },
  wrapper:{
    flexGrow:1,
    width:"100%",
    display:'flex',
    overflow:'hidden'
  },
  chat_area:{
    flexGrow:1,
    display:'flex',
    overflow:'auto'
  },
  chat_footer:{
    position:'sticky',
    bottom:0,
    paddingTop:'1rem',
    backgroundColor:'black'
  },
  chat_header:{
    position:'sticky',
    top:0,
    paddingBottom:'1rem',
    backgroundColor:"black"
  },
  threadParent :  {
    display : 'flex',
    flexGrow : 1,
    overflow : 'auto',
    rowGap:0
  },
  threadContainer : {
    height : "75vh",
    padding : "0 1rem",
    display: 'flex',
    overflow : 'auto'
  },
  search_header : {
    position : 'sticky',
    top : 0,
    paddingBottom : '1rem',
    backgroundColor : 'black',
    zIndex:1
  }
};
// Customizable Area End
