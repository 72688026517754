import React from "react";
const userProfile = require("../assets/user-profile.png");
const logo = require("../assets/image_logo.svg");


const LogoutIcon =  ()=>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M11.6265 9.74683L13.3331 8.04016L11.6265 6.3335" stroke="url(#paint0_linear_5547_18683)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.50684 8.04004H13.2868" stroke="url(#paint1_linear_5547_18683)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.84017 13.3332C4.8935 13.3332 2.50684 11.3332 2.50684 7.99984C2.50684 4.6665 4.8935 2.6665 7.84017 2.6665" stroke="url(#paint2_linear_5547_18683)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <linearGradient id="paint0_linear_5547_18683" x1="12.4798" y1="6.3335" x2="12.4798" y2="9.74683" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFD078"/>
        <stop offset="1" stop-color="#FFE3AF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_5547_18683" x1="9.89684" y1="8.04004" x2="9.89684" y2="9.04004" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFD078"/>
        <stop offset="1" stop-color="#FFE3AF"/>
      </linearGradient>
      <linearGradient id="paint2_linear_5547_18683" x1="5.1735" y1="2.6665" x2="5.1735" y2="13.3332" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFD078"/>
        <stop offset="1" stop-color="#FFE3AF"/>
      </linearGradient>
    </defs>
  </svg>


const BookingIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M18.91 14.6924C18.91 15.9824 19.97 17.0324 21.26 17.0324C21.26 20.7824 20.32 21.7224 16.57 21.7224H7.19C3.44 21.7224 2.5 20.7824 2.5 17.0324V16.5724C3.79 16.5724 4.85 15.5124 4.85 14.2224C4.85 12.9324 3.79 11.8724 2.5 11.8724V11.4124C2.51 7.66241 3.44 6.72241 7.19 6.72241H16.56C20.31 6.72241 21.25 7.66241 21.25 11.4124V12.3524C19.96 12.3524 18.91 13.3924 18.91 14.6924Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.2111 6.7225H7.12109L10.0511 3.7925C12.4411 1.4025 13.6411 1.4025 16.0311 3.7925L16.6311 4.3925C16.0011 5.0225 15.8511 5.9525 16.2111 6.7225Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87891 6.72266L9.87891 21.7227" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="5 5"/>
</svg>

const SelectedBookingIcon = ()=><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 0.25H9.75C10.575 0.25 11.25 0.925 11.25 1.75V13.75L6 11.5L0.75 13.75V1.75C0.75 0.925 1.425 0.25 2.25 0.25ZM6 9.865L9.75 11.5V2.5C9.75 2.0875 9.4125 1.75 9 1.75H3C2.5875 1.75 2.25 2.0875 2.25 2.5V11.5L6 9.865Z" fill="white"/>
</svg>

const ClientsIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.14414 11C9.05405 10.9921 8.94595 10.9921 8.84685 11C6.7027 10.9369 5 9.39797 5 7.50395C5 5.57046 6.78378 4 9 4C11.2072 4 13 5.57046 13 7.50395C12.991 9.39797 11.2883 10.9369 9.14414 11Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.1432 5C17.281 5 19 6.34571 19 8C19 9.62 17.3471 10.94 15.2865 11C15.1983 10.9914 15.0992 10.9914 15 11" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.46371 14.1142C3.5121 15.4294 3.5121 17.5726 5.46371 18.8797C7.68145 20.3734 11.3185 20.3734 13.5363 18.8797C15.4879 17.5645 15.4879 15.4213 13.5363 14.1142C11.3266 12.6286 7.68952 12.6286 5.46371 14.1142Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 19C18.4601 18.85 18.8946 18.56 19.2524 18.13C20.2492 16.96 20.2492 15.03 19.2524 13.86C18.901 13.44 18.4728 13.16 18.0192 13" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const SelectedInvoiceIcon = () => <svg width="12" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.225 6.175C2.5225 5.7325 1.975 5.275 1.975 4.5625C1.975 3.745 2.7325 3.175 4 3.175C5.065 3.175 5.5975 3.58 5.7925 4.225C5.8825 4.525 6.13 4.75 6.445 4.75H6.67C7.165 4.75 7.5175 4.2625 7.345 3.7975C7.03 2.9125 6.295 2.1775 5.125 1.8925V1.375C5.125 0.7525 4.6225 0.25 4 0.25C3.3775 0.25 2.875 0.7525 2.875 1.375V1.87C1.42 2.185 0.25 3.13 0.25 4.5775C0.25 6.31 1.6825 7.1725 3.775 7.675C5.65 8.125 6.025 8.785 6.025 9.4825C6.025 10 5.6575 10.825 4 10.825C2.7625 10.825 2.125 10.3825 1.8775 9.7525C1.765 9.46 1.51 9.25 1.2025 9.25H0.9925C0.49 9.25 0.1375 9.76 0.325 10.225C0.7525 11.2675 1.75 11.8825 2.875 12.1225V12.625C2.875 13.2475 3.3775 13.75 4 13.75C4.6225 13.75 5.125 13.2475 5.125 12.625V12.1375C6.5875 11.86 7.75 11.0125 7.75 9.475C7.75 7.345 5.9275 6.6175 4.225 6.175Z" fill="white"/>
</svg>

const SelectedAccountIcon = ()=><svg width="12" height="14" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.75179 9L4.13304 11.4563C4.09554 11.6188 4.01429 11.75 3.88929 11.85C3.76429 11.95 3.61429 12 3.43929 12C3.20179 12 3.00804 11.9062 2.85804 11.7188C2.70804 11.5312 2.66429 11.325 2.72679 11.1L3.25179 9H1.20804C0.958042 9 0.758042 8.90313 0.608042 8.70938C0.458042 8.51562 0.414292 8.3 0.476792 8.0625C0.514292 7.8875 0.601792 7.75 0.739292 7.65C0.876792 7.55 1.03304 7.5 1.20804 7.5H3.62679L4.37679 4.5H2.33304C2.08304 4.5 1.88304 4.40313 1.73304 4.20938C1.58304 4.01562 1.53929 3.8 1.60179 3.5625C1.63929 3.3875 1.72679 3.25 1.86429 3.15C2.00179 3.05 2.15804 3 2.33304 3H4.75179L5.37054 0.54375C5.40804 0.38125 5.48929 0.25 5.61429 0.15C5.73929 0.05 5.88929 0 6.06429 0C6.30179 0 6.49554 0.09375 6.64554 0.28125C6.79554 0.46875 6.83929 0.675 6.77679 0.9L6.25179 3H9.25179L9.87054 0.54375C9.90804 0.38125 9.98929 0.25 10.1143 0.15C10.2393 0.05 10.3893 0 10.5643 0C10.8018 0 10.9955 0.09375 11.1455 0.28125C11.2955 0.46875 11.3393 0.675 11.2768 0.9L10.7518 3H12.7955C13.0455 3 13.2455 3.09688 13.3955 3.29063C13.5455 3.48438 13.5893 3.7 13.5268 3.9375C13.4893 4.1125 13.4018 4.25 13.2643 4.35C13.1268 4.45 12.9705 4.5 12.7955 4.5H10.3768L9.62679 7.5H11.6705C11.9205 7.5 12.1205 7.59688 12.2705 7.79063C12.4205 7.98438 12.4643 8.2 12.4018 8.4375C12.3643 8.6125 12.2768 8.75 12.1393 8.85C12.0018 8.95 11.8455 9 11.6705 9H9.25179L8.63304 11.4563C8.59554 11.6188 8.51429 11.75 8.38929 11.85C8.26429 11.95 8.11429 12 7.93929 12C7.70179 12 7.50804 11.9062 7.35804 11.7188C7.20804 11.5312 7.16429 11.325 7.22679 11.1L7.75179 9H4.75179ZM5.12679 7.5H8.12679L8.87679 4.5H5.87679L5.12679 7.5Z" fill="white"/>
</svg>

const SelectedClientIcon = ()=><svg width="12" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C4.3425 0 3 1.3425 3 3C3 4.6575 4.3425 6 6 6C7.6575 6 9 4.6575 9 3C9 1.3425 7.6575 0 6 0ZM7.575 3C7.575 2.13 6.87 1.425 6 1.425C5.13 1.425 4.425 2.13 4.425 3C4.425 3.87 5.13 4.575 6 4.575C6.87 4.575 7.575 3.87 7.575 3ZM10.575 9.75C10.575 9.27 8.2275 8.175 6 8.175C3.7725 8.175 1.425 9.27 1.425 9.75V10.575H10.575V9.75ZM0 9.75C0 7.755 3.9975 6.75 6 6.75C8.0025 6.75 12 7.755 12 9.75V11.25C12 11.6625 11.6625 12 11.25 12H0.75C0.3375 12 0 11.6625 0 11.25V9.75Z" fill="white"/>
</svg>


const SkyCardIcon = ()=><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 2H4C2.89 2 2 2.89 2 4V15C2 16.11 2.89 17 4 17H8V22L12 20L16 22V17H20C21.11 17 22 16.11 22 15V4C22 2.89 21.11 2 20 2ZM20 15H4V13H20V15ZM4 10H20V5C20 4.45 19.55 4 19 4H5C4.45 4 4 4.45 4 5V10Z" fill="white"/>
</svg>



export {LogoutIcon, userProfile, logo, BookingIcon, ClientsIcon,SelectedBookingIcon,SelectedInvoiceIcon,SelectedAccountIcon,SelectedClientIcon,SkyCardIcon}