import * as React from "react";
// Customizable Area Start
import {
  Box,
  InputAdornment,
  Table,
  TableRow,
  TableContainer,
  TableHead,
  TableCell,
  Checkbox,
  Grid,
  PopperProps
} from "@material-ui/core";
import { theme as colors } from '../../../components/src/utils';
import {AutocompleteRenderInputParams} from "@material-ui/lab/Autocomplete"
import {
  GoldishSimpleButton, FilterTypeButton, SearchTextFieldActiveBooking, StyledEditDeleteMenu,
  FormSubmitButton, AddLegtTypeButton, MuiModal, PaymentLabel, StyledModalContent, CustomFormTextField,
  CustomDepartueAiportAutoCompplete, renderOptionFrom, CustomStyledPopper, StyledPagination, renderTableLoader, NoRecordFoundRow
} from '../../../components/src/MuiCustomComponents';
import "./OrderDetailsView.css";
import PhoneInput from "react-phone-input-2";
import { FilterList, Search, SwapVert, KeyboardArrowDown } from "@material-ui/icons";
import * as Yup from 'yup';
import { Formik } from "formik";
import {AnnoutSearchType, ClientTableAttrubite, UpcomingBookingType} from './types'
import {allFilter, tableHeaderData, clientsTableHeadData} from './assets';
import { AddNewClientModal } from "../../../components/src/ReUsableModal";

interface AutoCompleteMenuType {
  selected: boolean;
}

interface StatusObjType {
  title:string,
  textDotColor:string
  dotParentColor:string
  parentBackground:string
}

const phoneValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Phone number is required*'),
  fullName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s'-]+$/, "Invalid Full Name*")
    .min(1, 'Name cannot be empty*')
    .required('Full name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
  preferredAirport: Yup.object().shape({
    name: Yup.string()
      .required("Preffered Airport is required*")
      .min(1, "Preffered Airport can't be empty")
  })
    .nullable()
    .required('Preffered Airport is required*'),
  emailId: Yup.string()
    .trim()
    .email('Invalid email*')
    .required('Email is required*')
});


// Customizable Area End

import { configJSON, ViewProps } from "./OrderDetails";

const OrderDetailsView: React.FC<ViewProps> = ({
  // Customizable Area Start
  appliedFilter,
  upcomingBookings,
  totalPage,
  currentPage,
  applySorting,
  isLoading,
  navigationToBooking,
  pageHeading,
  airports,
  createNewProfile,
  anchorEl,
  isAddNewClientModalOpen,
  setIsNewClientModalOpen,
  moment,
  handleCurrentPage,
  handleApplyFilter,
  handleSearchChange,
  handleTripType,
  handleSorting,
  getVendorStatus,
  handleMenuOpen,
  handleClose,
  handleCheckboxChange,
  isClientTable,
  getSearchedAirports,
  focusedField,
  setFocusedField,
  clientForm
  // Customizable Area End
}) => {

  // Customizable Area Start






  const BookingsTable = () => {
    const BookingDataComponent = !isClientTable ? <UpcomingAndPrevBookingTableRowData /> : <ClientTableRowData />;
    const loaderSpan = !isClientTable ? 7 : 3.5
    return <TableContainer className="dash_table_flights" style={{ marginTop: '2rem' }}>
      <Table style={{ borderCollapse: "separate" }}>
        <BookingsHeaderRow handleSort={handleSorting} />
        <Box height={"15px"} />
        {isLoading ? <TableCell colSpan={loaderSpan} style={{ borderBottom: 'none' }}>{renderTableLoader()}</TableCell> : BookingDataComponent}
      </Table>
    </TableContainer>
  }

  const BookingsHeaderRow = ({ handleSort }: {handleSort:(value:string)=>void}) => {
    const mainColumnData = pageHeading() === 'Clients' ? [...clientsTableHeadData,{title:'Status',value:null}] : tableHeaderData
    return <TableHead>
      <TableRow className={"dash_table_head"} style={{ height: 'auto' }}>
        {mainColumnData && mainColumnData.map(({ title, value }: {title:string,value:string | null}) => {
          return <>
            <TableCell style={{ borderRadius: '0' }} >
              <Box className="order_detail_table_head_cell">
                <Box>{title}</Box>
             { value && <Box onClick={() => { handleSort(value) }} id="order_detail_view_menu_sort_button" data-testid="menu_sort_button" className={value === applySorting ? 'order_detail_view_menu_sorted' : ''}><SwapVert style={{ color: colors.GOLD_COLOR }} /></Box>}
              </Box>

            </TableCell>
          </>
        })}
      </TableRow>
    </TableHead>
  }




  const UpcomingAndPrevBookingTableRowData = () => {
    const isDataAvailable = upcomingBookings.length !== 0;
    return (
      <>
        {isDataAvailable ? upcomingBookings.map((elemData: UpcomingBookingType) => {
          const {
            attributes: {
              booking_no, departure_date, trip_type, amount,
              vendor: { name },
              vendor_status,
              payment_status,
              client,
              order_id,
              booking_date
            },
          } = elemData;

          return (
            <React.Fragment key={booking_no}>
              <TableRow className="dash_table_row order_details_row" onClick={() => navigationToBooking(order_id + '')} data-testid="redirect_booking">
                <TableCell className="dash_rounded-row-first-cell">{`#` + booking_no}</TableCell>
                <TableCell>{client}</TableCell>
                <TableCell data-testid="booking_date">{moment(booking_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                <TableCell>{moment(departure_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                <TableCell>{handleTripType(trip_type)}</TableCell>
                <TableCell>${amount}</TableCell>
                <TableCell>{name || '- - -'}</TableCell>
                <TableCell style={{ textTransform: 'capitalize' }} className={payment_status === 'paid' ? 'order_detail_view_paid' : 'order_detail_view_unpaid'}>{payment_status}</TableCell>
                <TableCell className={`dash_rounded-row-last-cell ${getVendorStatus(vendor_status).className}`}>{getVendorStatus(vendor_status).status}</TableCell>
              </TableRow>
              <Box height={"0.5rem"} />
            </React.Fragment>
          );
        }) : <NoRecordFoundRow><AddClientModal isFullWidth={true} /></NoRecordFoundRow>
        }
      </>
    );
  };

  const ClientTableRowData = () => {
    const isDataAvailable = upcomingBookings.length !== 0;
    return (
      <>
        {isDataAvailable ? upcomingBookings.map((elem: ClientTableAttrubite) => {
          const {
            attributes: {
              account_id, name, email_address, account_created, total_spend,
              status
            },
            id
          } = elem;
          
          const status_obj =  status === 'activated' ? {parentBackground:'#152622',title:'Active',textDotColor:'#34D399',dotParentColor:'#1B4739'} : {parentBackground:'#291C1E',title : 'Deactivated',textDotColor:'#F87171',dotParentColor:'#502C2D'}

          return (
            <React.Fragment key={id}>
              <TableRow className="dash_table_row order_details_row client_table_row" onClick={() => navigationToBooking(id)} data-testid="redirect_booking">
                <TableCell className="dash_rounded-row-first-cell">{account_id}</TableCell>
                <TableCell>{name || '- - -'}</TableCell>
                <TableCell>{email_address}</TableCell>
                <TableCell>{moment(account_created, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
                <TableCell>{'$' + total_spend}</TableCell>
                <TableCell className={`dash_rounded-row-last-cell`}><StatusBox status_obj={status_obj}/> </TableCell>
              </TableRow>
              <Box height={"0.5rem"} />
            </React.Fragment>
          );
        }) : <NoRecordFoundRow><AddClientModal isFullWidth={true} /></NoRecordFoundRow>
        }
      </>
    );
  };

  const StatusBox=({status_obj}:{ status_obj:StatusObjType})=>{
    return <Box style={{width:'50%',justifyContent:'center',borderRadius:'1.5rem',display:'flex',columnGap:'0.5rem',alignItems:'center',backgroundColor:status_obj.parentBackground,padding:"0.65rem 1.5rem"}}>
      <Box style={{borderRadius:"50%",width:'1rem',height:'1rem',backgroundColor:status_obj.dotParentColor,display:'flex',justifyContent:"center",alignItems:'center'}}>
         <Box style={{borderRadius:'50%',width:'0.5rem',height:'0.5rem',backgroundColor:status_obj.textDotColor}}/>
      </Box>
      <Box style={{color:status_obj.textDotColor}}>{status_obj.title} </Box>
    </Box>
  }

  const AddClientModal = ({ isFullWidth }: { isFullWidth: boolean }) => {

    return <>
      <AddLegtTypeButton style={{ fontSize: '0.9rem' }} fullWidth={isFullWidth} onClick={() => setIsNewClientModalOpen(true)} data-testid="add_new_client">+ Add New Client</AddLegtTypeButton>      
    </>
  }





  const PaginationComponent = () => {
    return <Box marginTop={'1rem'}>
      <StyledPagination count={totalPage} page={currentPage} data-testid="pagination_comp" onChange={(_event: React.ChangeEvent<unknown>, page: number) => { handleCurrentPage(page); }} shape="rounded" />
    </Box>

  }

  // Customizable Area End

  return (
    // Customizable Area Start
    <Box className='dash_main booking_main'>
      <Box className='order_detail_view_heading_parent'>
        <GoldishSimpleButton
          style={{
            color: colors.GOLD_COLOR,
            textTransform: "capitalize",
            width: "15rem",
            justifyContent: "start",
          }}
          className='cf-avinode-search'
        >
          {pageHeading()}
        </GoldishSimpleButton>

        <Box className='order_detail_view_search'>
          <SearchTextFieldActiveBooking
            fullWidth
            data-testid='search_field'
            placeholder={!isClientTable ? 'Enter Booking ref no, client, date etc...' : 'Enter Account ID, email address, client name'}
            onChange={handleSearchChange}
            style={{ position: "relative", left: "-55px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Search style={{ color: "#757677" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {!isClientTable ?
          <> <Box>
            <FilterTypeButton onClick={handleMenuOpen} data-testid='menu_opener'>
              <FilterList style={{ fontSize: "2rem" }} />
            </FilterTypeButton>
          </Box>
            <StyledEditDeleteMenu
              PaperProps={{
                style: {
                  width: "12rem",
                  marginTop: "6.2rem",
                  padding: "0px 10px",
                },
              }}
              id='simple-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Box className='order_detail_view_menu_box'>
                {allFilter.map((elem: string) => {
                  return (
                    <Box className='order_detail_view_menu_item'>
                      <Checkbox
                        checked={appliedFilter.includes(elem)}
                        data-testid='check_box'
                        style={{ color: "white" }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(event as unknown as React.MouseEvent<HTMLElement, MouseEvent>, elem)}
                      />
                      <Box style={{ textTransform: "capitalize" }}>{elem}</Box>
                    </Box>
                  );
                })}
                <FormSubmitButton
                  className='cf_avinode_apply_filter'
                  fullWidth
                  onClick={handleApplyFilter}
                  disabled={appliedFilter.length === 0}
                  data-testid='apply_filter'
                >
                  Apply Filters
                </FormSubmitButton>
              </Box>
            </StyledEditDeleteMenu> </> : <AddClientModal isFullWidth={false} />}
      </Box>
      <BookingsTable />
      <AddNewClientModal isOpen={isAddNewClientModalOpen} cancel={()=>setIsNewClientModalOpen(false)} airports={airports} getSearchedAirports={getSearchedAirports} setFocusedField={setFocusedField} focusedField={focusedField} createNewProfile={createNewProfile} clientForm={clientForm} register={true}>
</AddNewClientModal>
      <PaginationComponent />
    </Box>


    // Customizable Area End
  );
};

// Customizable Area Start
// Customizable Area End

export default OrderDetailsView;
