import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import {getStorageData} from '../../../framework/src/Utilities';
import {AdminListResponse,EachAdminRow} from "./assets";
import {handleNavigation} from "../../../components/src/Navigator";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    isLoading:boolean,
    sortedValue:string;
    currentPage:number;
    query:string;
    sort_type:'asc' | 'desc'
    adminListData : [] | EachAdminRow[],
    totalPage:number
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAdminListApiId:string = '';
    timer : NodeJS.Timeout | null = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            isLoading:false,
            sortedValue:'account_id',
            currentPage : 1,
            totalPage:0,
            query:'',
            sort_type : 'asc',
            adminListData : []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
      
      
            if (responseJson && responseJson.data) {
                if(apiRequestCallId === this.getAdminListApiId){
                    this.handleAdminListResponse(responseJson);
                }
            }
        }
        // Customizable Area End
    }



    // Customizable Area Start

    async componentDidMount(){
          this.getAdminList()  ;
    }

    handleSortChange=(sort:string)=>{
        this.setState({sortedValue:sort,currentPage:1});
        this.getAdminList(sort,undefined,1);
    }

    handleCurrentPage=(currentPage:number)=>{
        this.setState({currentPage});
        this.getAdminList(undefined,undefined,currentPage);
    }

    handleQuery=(query:string)=>{
        this.setState({query,currentPage:1});
        this.getAdminList(undefined,query,1);
    }

    handleQueryDebounce=(event:React.ChangeEvent<HTMLInputElement>)=>{
        const timeToHold = 1500;
        const value = event.target.value;
        if(this.timer){
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(()=>{
            this.handleQuery(value);
        },timeToHold)
    }

    handleAdminListResponse=(responseJson:AdminListResponse)=>{
        const {data,total_pages} = responseJson;
        this.setState({totalPage:total_pages,isLoading:false,adminListData:data.data});
    }


    getAdminList=async(sorted_column=this.state.sortedValue,query=this.state.query,currentPage=this.state.currentPage,number_of_items_per_page=10,sort_type=this.state.sort_type)=>{
        this.setState({isLoading:true});
        const params = `sort=${sorted_column}&sort_type=${sort_type}&query=${query}&page_number=${currentPage}&number_of_items_per_page=${number_of_items_per_page}`;
        const headers = {
          token : await getStorageData('token')
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
          
        this.getAdminListApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAdminListApiEndPoint + `?` + params
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      handleNaivigationToProfile=(account_id:number)=>{
        handleNavigation(`/AdminProfile?id=${account_id}`,this.props,this.send);
      }

      handleCreateAdminNavigation=()=>{
        handleNavigation(`/AdminProfile?type=create`,this.props,this.send);
      }

      handleRole=(userRole:string)=>{
        if(userRole === 'super_admin'){
            return 'Super Admin'
        }
        return userRole;
      }

      handleRoleColor=(userRole:string)=>{
        if(userRole === 'admin'){
          return '#F59E0B'
        }else{
          return '#34D399'
        }
      }

    // Customizable Area End
}