import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  MenuItem,
  FormControl
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {AddLegtTypeButton, CSSPropertiesMap, CustomFormSelectNew, CustomFormTextField, CustomLabel, CustomTypography, ErrorBoxes, GeneralParentBox, adminSelectProps, handleVendorChargedChange, Footer, FooterCancelSaveChanges, FormSubmitButton, SurityModal, SureModalButtonContainer, renderSelectCatOption, dollarTextFieldInputProps } from '../../../components/src/MuiCustomComponents';
import {DebitSkyCardIcon, DebitSkyCardSmallerIcon, SkyCardDebitCardIcon , SkyCardIcon } from '../../../components/src/assets';
import {theme as colors } from '../../../components/src/utils';
import {Formik, FormikProps, ArrayHelpers, FieldArray } from "formik";
import {AirplanemodeActive, KeyboardArrowDown, AttachMoney, ArrowBack, RemoveCircleOutline } from "@material-ui/icons";
import {card25Schema,debitCardSchema} from "../../../components/src/Validations";
import {AircraftCategoryType, Card25hrType, DebitFormInitValueType} from './Cf25hourskycardController';
import { get as lodashGet} from "lodash";


const eachTopUpValue = {top_up : null};

// Customizable Area End

import Cf25hourskycardController, {
  Props,
  configJSON,
} from "./Cf25hourskycardController";

export default class Cf25hourskycard extends Cf25hourskycardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderHeader=()=>{
    const {isViewStateOpen,isEditStateOpen,isAddStateOpen} = this.state;
    const isGeneralHeader = !isViewStateOpen && !isEditStateOpen && !isAddStateOpen;
    if(isGeneralHeader) return this.renderGeneralHeader();
    return this.renderViewEditSkyCardHead();
  }

  renderViewEditSkyCardHead=()=>{
    const {isViewStateOpen,isAddStateOpen,cardType, add25CardInitValue} = this.state;
    const {cardName} = add25CardInitValue;
    const cardTypeHead = cardType === 'debit' ? 'Debit Sky' : `${cardName || '25 Hour'} Sky`;
    const isEditHead = isViewStateOpen ? '' : 'Edit';
    const head = isAddStateOpen ? 'Add ' : isEditHead;
    const headEnd = isAddStateOpen ? '' : 'Details'
    return <CustomTypography
      style={{color:'white'}}
      className="header_back"
      onClick={()=> head === 'Edit' ? this.handleViewEditSkyCard(false,true,false) : this.handleViewEditSkyCard(false,false,false)}
    >
      <ArrowBack className="cf_avinod_arrow_search"/>{head} {cardTypeHead} Card {headEnd}
    </CustomTypography>
  }

  renderGeneralHeader=()=>{
    const {card25HrData,cardType} = this.state;
    const cardTypeHead = cardType === 'debit' ? 'Debit' : '25 Hour';
    return <Box className="dash_flex_row">
        <CustomTypography style={{color:'white'}}>{cardTypeHead} Sky Cards</CustomTypography>
        {cardType !== 'debit' && card25HrData.length !== 0 && this.renderAdd25SkyCardButton()}
    </Box>
  }

  renderAdd25SkyCardButton=()=>{
    return <AddLegtTypeButton className="addCardBtn" onClick={()=>this.handleViewEditSkyCard(true,false,false)} style={{ fontSize: '0.9rem',marginRight:"0.4rem",textTransform:'none',backgroundColor:'#1A1C1E'}}>+ Add New 25 hr Sky Card</AddLegtTypeButton>
  }

  renderBody=()=>{
    const {isViewStateOpen,isEditStateOpen, isAddStateOpen,card25HrData} = this.state;
    const isGeneralBody =  !isViewStateOpen && !isEditStateOpen && !isAddStateOpen
    if(isGeneralBody) return  this.renderGeneralBody();
    return this.renderCardFormsAndEdit();
  }

  renderGeneralBody=()=>{
    const {cardType,card25HrData} = this.state;
    if(cardType !== 'debit' && card25HrData.length === 0){
      return <Box style={webStyle.noCardButtonCont}>{this.renderAdd25SkyCardButton()}</Box>
    }
    return this.renderCards();
  }

  renderCards=()=>{
    const {card25HrData,cardType} = this.state;
       return <Grid container spacing={2}>
          {cardType === '25hour' && card25HrData && (card25HrData as Card25hrType[]).map((card_el)=> this.renderCard(card_el))}
          {cardType === 'debit' && this.renderDebitCard()}
        </Grid>
  }

  renderCardFormsAndEdit=()=>{
    const {cardType} = this.state;
    if(cardType === 'debit') return this.renderViewEditDebitSkyCardFormParent();
    return this.renderViewEdit25HrSkyCard()
  }

  renderViewEdit25HrSkyCard=()=>{
    return this.renderSkyCardForm();
  }

  renderViewEditDebitSkyCardFormParent=()=>{
    const {isEditStateOpen} = this.state;
    return <Box className="booking_section" padding={'0.5rem'}>
        <Box style={webStyle.genHead}>{isEditStateOpen ? 'Edit' : ''} Sky Card Top-Up Balance</Box>
        <CustomLabel style={{margin:0,fontWeight:"500"}}>Value & Convenience for Private Charter Jet Flights</CustomLabel>
        <Box style={webStyle.currentTopUp}>Current Top-Up Balances</Box>
        {this.renderViewEditDebitSkyCardForm()}
    </Box>
  }

  renderViewEditDebitSkyCardForm=()=>{
    const {debitSkyCardInitValue} = this.state;
    const {isViewStateOpen} = this.state;
    return <Formik
    initialValues={debitSkyCardInitValue}
    onSubmit={(values) => {this.addEditDebitkyCardTopUp(values)}}
    key={isViewStateOpen ? 'debit_view' : 'debit_edit'}
    validationSchema={debitCardSchema}
    innerRef={(formikProps) => {
      this.cardFormik = formikProps as FormikProps<unknown>
    }}
    enableReinitialize
    validateOnChange
    validateOnBlur
  >
    {(formikProps) => {
      const {values : {current_top_ups}} = formikProps;
      return (
        <FieldArray
        name="current_top_ups"
        render={({push,remove}: ArrayHelpers) => (
          <>
         {current_top_ups && <Grid container spacing={2} style={{rowGap : '0.2rem',marginTop : '-0.4rem'}}>
            {formikProps.values.current_top_ups.map((_formElem,formIndex:number) => {
              return (
                <>
                  {this.renderFormTextFieldAndDeleteField(remove,formIndex,formikProps)}
                </>
              );
            })}
           </Grid>}
           {!isViewStateOpen && <AddLegtTypeButton className="add_top_up" onClick={()=>push(eachTopUpValue)} style={{border:'1px dashed',marginTop:'1rem'}} fullWidth>+ Add Top-Up</AddLegtTypeButton>}
          </>
        )}
      />
      )}}
      </Formik>
  }

  renderFormTextFieldAndDeleteField=(remove:(formIndex : number)=>void, formIndex:number,formikProps: FormikProps<{current_top_ups :DebitFormInitValueType[]}>)=>{
    const {isViewStateOpen} = this.state;
    const textFieldStyle = isViewStateOpen ? webStyle.disableBg : [];
    const isOutlined = !isViewStateOpen ? 'outlined' : 'standard';
    const {values,setFieldValue,touched,errors} = formikProps;
    const keyName = `current_top_ups[${formIndex}].top_up`;
    return <Grid item lg={4} md={6} xs={12}>
       <Box style={webStyle.viewButtonParent}>
       <CustomFormTextField
            disabled={isViewStateOpen}
            autoComplete="off"
            placeholder="Add Top Up"
            size="medium"
            fullWidth
            isFocusedTypeInput = {true}
            initialBorderColor='#636F73'
            variant={isOutlined}
            style={textFieldStyle}
            value={lodashGet(values,keyName)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleVendorChargedChange(event,setFieldValue,keyName)
            }}  
            InputProps = {
              dollarTextFieldInputProps
            }
            isErrorTouch={lodashGet(touched,keyName) && lodashGet(errors,keyName)}
        />
        {!isViewStateOpen && <RemoveCircleOutline className="cancel_form" style={webStyle.removeIcon} onClick={()=>remove(formIndex)}/>}
       </Box> 
       <ErrorBoxes touched={lodashGet(touched,keyName)} errors={lodashGet(errors,keyName)} message={lodashGet(errors,keyName)}/>

    </Grid>  
  }


  renderCard=(cardData:Card25hrType)=>{
    return <Grid item lg={4} md={6} sm={12} xl={3}>
      <Box>
        <Grid container style={webStyle.cardParent}>
          <Grid xs={9} style={webStyle.cardContent}>{this.renderCardContent(cardData)}</Grid>
          <Grid xs={3} style={{...webStyle.cardImg25,...webStyle.skyCard25}}><SkyCardIcon/></Grid>
        </Grid>
      </Box>
    </Grid>
  }

  renderCardContent=(cardData:Card25hrType)=>{
    const {attributes:{aircraft_category,name,price}} = cardData;
    return <Box style={webStyle.cardContentStyle}>
        <Box style={{...webStyle.viewButtonParent,columnGap:'1rem'}}>
          <Box><SkyCardDebitCardIcon/></Box>
          <Box style={webStyle.cardHeading}>{name}</Box>
        </Box>

        <Box className="booking_section" style={{rowGap:"0.4rem"}}>
            <Box fontSize={'1.2rem'}>{aircraft_category}</Box>
            <Box style={webStyle.cardContentBottom}>
              <Box style={{...webStyle.viewButtonParent,color:colors.GOLD_COLOR}}>
                <Box color={'#94A3B8'} fontWeight={'bold'}>Price:</Box>
                <Box color={'#FFB833'} fontWeight={'bold'}>${price}</Box>
              </Box>

              <Box style={{...webStyle.viewButtonParent,marginTop:'-4px',color:colors.GOLD_COLOR}}>
              <Box className="view_card" onClick={()=>{this.handleViewEditSkyCard(false,true,false);this.handleViewCard(cardData)}} component={'span'} style={{textDecoration:"underline",cursor:'pointer'}}>View</Box>
                <Box component={'span'}>{'>'}</Box>
              </Box>
            </Box>
        </Box>
    </Box>
  }

  renderDebitCard=()=>{
    return <Grid item  sm={12} xl={3} lg={4} md={6}>
      <Box>
        <Grid style={webStyle.cardParent} container>
          <Grid xs={9} style={webStyle.cardContent}>{this.renderDebitCardContent()}</Grid>
          <Grid xs={3} style={{...webStyle.debitskyCard,...webStyle.cardImg25}}><DebitSkyCardIcon/></Grid>
        </Grid>
      </Box>
    </Grid>
  }

  renderDebitCardContent=()=>{
    return <Box style={webStyle.cardContentStyle}>
        <Box style={{...webStyle.viewButtonParent,columnGap:'1rem'}}>
          <Box><DebitSkyCardSmallerIcon/></Box>
          <Box style={webStyle.cardHeading}>Debit Sky Card</Box>
        </Box>

        <Box className="booking_section" style={{rowGap:"0.4rem"}}>
            <Box style={{...webStyle.cardContentBottom,justifyContent:'end'}}>
              <Box style={{...webStyle.viewButtonParent,marginTop:'-4px',color:colors.GOLD_COLOR}}>
              <Box onClick={()=> this.handleViewEditSkyCard(false,true,false)} className="view_debit_card" component={'span'} style={{textDecoration:"underline",cursor:'pointer'}}>View</Box>
                <Box component={'span'}>{'>'}</Box>
              </Box>
            </Box>
        </Box>
    </Box>
  }


  renderSkyCardForm=()=>{
    const {activeField, add25CardInitValue,isViewStateOpen, isEditStateOpen} = this.state;
    const textFieldStyle = isViewStateOpen ? webStyle.disableBg : {}
    const textSelectStyle = isViewStateOpen ? {...webStyle.disableBg,padding : '1rem'} : {background:'#1A1C1E'}
    const isOutlined = !isViewStateOpen ? 'outlined' : 'standard';
    const label = !isEditStateOpen ? 'Add' : 'Edit';
    const formLabel = isViewStateOpen ? '' : label;
    return   <Formik
    initialValues={add25CardInitValue}
    onSubmit={(values)=>{
      this.addEditDelete25SkyCard(values)
    }}
    validationSchema={card25Schema}
    key={isViewStateOpen ? 'view' : 'addEdit'}
    innerRef={(formikProps) => {
      this.cardFormik = formikProps as FormikProps<unknown>
    }}
    validateOnChange
    validateOnBlur

  >
        {({  touched, errors, setFieldValue, values, handleChange }) => (
            <Box className="booking_section sky_card_form" style={webStyle.viewEditFormParent}>
            <Box>
                  <CustomLabel
                   isFocused = {activeField === 'cardName'}
                   isError={this.handleError(touched.cardName as unknown as boolean,errors.cardName as unknown as boolean)}

                   >{formLabel} Sky Card Name</CustomLabel>
                  <CustomFormTextField
                    autoComplete="off"
                    placeholder="Enter Card Name"
                    className = 'cardName'
                    isErrorTouch={this.handleError(touched.cardName as unknown as boolean,errors.cardName as unknown as boolean)}
                    onFocus={()=>this.handleActiveField('cardName')}
                    onBlur={()=>this.handleActiveField('')}
                    onChange={handleChange}
                    size="medium"
                    value={values.cardName}
                    name = 'cardName'
                    fullWidth
                    isFocusedTypeInput = {true}
                    initialBorderColor='#636F73'
                    disabled={isViewStateOpen}
                    variant={isOutlined}
                    style = {textFieldStyle}
                  />
              <ErrorBoxes touched={touched.cardName} errors={errors.cardName} message={errors.cardName}/>
                </Box>

                <Box>
                  <CustomLabel
                   isError={this.handleError(touched.category as unknown as boolean,errors.category as unknown as boolean)}
                   isFocused = {activeField === 'category'}
                   >{formLabel === '' ? formLabel : 'Choose'} Aircraft Category</CustomLabel>
                  <FormControl
                        className="mui_select_unkownn"
                        fullWidth 
                    >
                      <CustomFormSelectNew
                        labelId='demo-simple-select-label'
                        className={`aircraft_cat`}
                        isError={this.handleError(touched.category as unknown as boolean,errors.category as unknown as boolean)}
                        inputProps={{IconComponent: !isViewStateOpen ? KeyboardArrowDown : ()=><></>}}
                        onFocus={()=>this.handleActiveField('category')}
                        onChange={(event:React.ChangeEvent<HTMLSelectElement>) => {
                          setFieldValue("category",event.target.value);
                        }}
                        onBlur={()=>this.handleActiveField('')}
                        renderValue={(selectedValue:string) => {
                          return (
                            <Box className='advance_search_select_box'>
                              <AirplanemodeActive />
                                <Box marginLeft='1rem'>{selectedValue}</Box>
                            </Box>
                          )
                        }}
                        value={values.category}
                        fullWidth
                        MenuProps={{...adminSelectProps,
                          className: "advance_search_picker",
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          style:{marginTop: '5px',maxHeight:'50vh'},
                          getContentAnchorEl: null,
                          disableScrollLock:true,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                        disabled={isViewStateOpen}
                        variant={isOutlined}
                        style = {textSelectStyle}
                        
                      >
                       <MenuItem value={'Category'} style={{display:'none'}}>Choose Aircraft Category</MenuItem>
                        {this.state.aircraftCategory && (this.state.aircraftCategory as AircraftCategoryType[]).map((category)=> <MenuItem value={category.label}>{renderSelectCatOption(category.label,category.value === values.category)}</MenuItem>)}
                      </CustomFormSelectNew>
                  </FormControl>
                  <ErrorBoxes touched={touched.category} errors={errors.category} message={errors.category}/>

                </Box>

                <Box>
                  <CustomLabel
                   isFocused = {activeField === 'price'}
                   isError={this.handleError(touched.price as unknown as boolean,errors.price as unknown as boolean)}

                   >{formLabel} Price</CustomLabel>
                  <CustomFormTextField
                    autoComplete="off"
                    placeholder="Add Price"
                    className = 'price'
                    isErrorTouch={this.handleError(touched.price as unknown as boolean,errors.price as unknown as boolean)}
                    onFocus={()=>this.handleActiveField('price')}
                    onBlur={()=>this.handleActiveField('')}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>)=>handleVendorChargedChange(event,setFieldValue,"price")}
                    size="medium"
                    value={values.price}
                    fullWidth
                    isFocusedTypeInput = {true}
                    initialBorderColor='#636F73'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney style={{fontSize:"2rem"}}/>
                        </InputAdornment>
                      ),
                    }}
                    disabled={isViewStateOpen}
                    variant={isOutlined}
                    style = {textFieldStyle}
                  />
              <ErrorBoxes touched={touched.price} errors={errors.price} message={errors.price}/>
                </Box>

               


              <Box className="profile_verification_text">
              </Box>
            </Box>
        )}

  </Formik>
  }

  renderFooter=()=>{
    const {isViewStateOpen,isEditStateOpen,isAddStateOpen} = this.state;
    return <>
    {!(!isViewStateOpen && !isEditStateOpen && !isAddStateOpen) && <Footer>{this.renderFooterHandler()}</Footer>}
    </>
  }

  renderFooterHandler=()=>{
    const {isViewStateOpen, isAddStateOpen} = this.state;
    const isEditHead = isViewStateOpen ? '' : 'Edit';
    const head = isAddStateOpen ? 'Add' : isEditHead;
    if(isViewStateOpen) return this.renderViewFooter();
    return <FooterCancelSaveChanges submit={()=>this.cardFormik?.handleSubmit()} cancel={()=> head === 'Edit' ? this.handleViewEditSkyCard(false,true,false) : this.handleViewEditSkyCard(false,false,false)}/>
  }

  renderViewFooter=()=>{
    const {cardType} = this.state;
    const is25HrCard = cardType === '25hour';
    return <Box className="profile_submission" style={{width : is25HrCard ? '40%' : '20%'}}>
      {is25HrCard && <AddLegtTypeButton onClick={()=>this.handleDeleteModal(true)} className = 'delete_card' style={{color:'#F87171',border:'1px solid #F87171',backgroundColor:'#1A1C1E'}} fullWidth>Delete Sky Card</AddLegtTypeButton>}
      <FormSubmitButton fullWidth className="edit_sky_card" onClick={()=>this.handleViewEditSkyCard(false,false,true)}>Edit</FormSubmitButton>
    </Box>
  }

  renderDeleteModal=()=>{
    const {isDeleteModalOpen,add25CardInitValue} = this.state;
    return <SurityModal isOpen={isDeleteModalOpen}>
        <SureModalButtonContainer cancel={()=>this.handleDeleteModal(false)} submit={()=>{this.addEditDelete25SkyCard(add25CardInitValue)}}/>
    </SurityModal>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
         {this.renderHeader()}
        <GeneralParentBox style={webStyle.mainParentStyle} className="card_parent">{this.renderBody()}</GeneralParentBox>
        {this.renderFooter()}
        {this.renderDeleteModal()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle : CSSPropertiesMap = {
    mainParentStyle:{
      marginTop:'1rem',
      marginBottom:'7rem',
      padding:'1rem'
    },
    cardHeading:{
      fontSize:"1.4rem",
      overflow:'hidden',
      textOverflow:'ellipsis',
      flex:3,
      width:'100px'
    },
    cardParent:{
      overflow:'hidden',
      borderRadius:"10px"
    },
    cardContent:{
      backgroundColor:'#3E4247'
    },
    cardImg25:{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      flexDirection:'column',
      borderRadius:'0px 10px 10px 0px',
      marginLeft:'-3px'
    },
    skyCard25:{
      background: 'linear-gradient(234.48deg, #064BB4 7.51%, #70D4FF 96.16%)',
    },
    debitskyCard:{
      background: 'linear-gradient(234.48deg, #FAB33E 7.51%, #F36749 96.16%)'
    },
    cardContentStyle:{
      height:"8.5rem",
      padding:'1rem',
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between'
    },
    cardContentBottom:{
      display:'flex',
      alignItems:'center',
      justifyContent : 'space-between'
    },
    viewButtonParent:{
      display:'flex',
      columnGap: "5px",
      alignItems : 'center'
    },
    viewEditFormParent:{
      backgroundColor : '#1A1C1E',
      padding : '1rem',
      borderRadius:'10px'
    },
    disableBg:{
      backgroundColor : '#2D3034',
      borderRadius:'5px',
      color:'white'
    },
    noCardButtonCont:{
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    },
    genHead:{
      color:'white',
      fontSize : '1.5rem',
      fontWeight:'bold'
    },
    currentTopUp:{
      padding : '1rem',
      backgroundColor : '#1A1C1E',
      fontSize:'1.2rem',
      borderRadius:'10px',
      fontWeight:'bold'
    },
    removeIcon:{
      color : '#F87171',
      fontSize : '2rem',
      cursor : 'pointer'
    }

};
// Customizable Area End
