import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./MasterLayout.css";
import {Box} from "@material-ui/core";
const starjetsImage = require("./starjets.svg");


interface ContainerProps {
    children: React.ReactNode;
  }

class MasterLayout extends Component<ContainerProps> {

    render() {
        return (
            <Box className = 'new_div'>
            <Box className = 'new_div_child'>
            <Box className = 'masterlay_logo_parent'><img className='masterlay_logo' src={starjetsImage}  /></Box>
            <Box className='masterlay_modal'>
                  {this.props.children}
            </Box>
       </Box>
       </Box>

        );
    }
}


export default MasterLayout;