import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Typography, styled } from "@material-ui/core";
import { CustomBox, CustomTypography, Footer, CustomDashboardButton } from "../../../components/src/MuiCustomComponents";

// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeading=()=>{
    const {handleIsTermsCondition} = this;
    if(handleIsTermsCondition()){
      return 'Terms & Conditions'
    }else{
      return 'Privacy Policy'
    }
  }

  renderFooter=()=>{
    return <Footer>
      <Box style={webStyles.footer}>
      <CustomDashboardButton size="large" variant="outlined" onClick={this.navigateToTermsCondsEdit} className="edit_terms_policy">
          Edit {this.renderHeading()}
      </CustomDashboardButton>
      </Box>
    </Footer>
  }



  // Customizable Area End

  render() {
    // Customizable Area Start
    const { termsConds , title } = this.state;
    return (
      <>
        <Box>
        <CustomTypography style={{color:'white'}}>{this.renderHeading()}</CustomTypography>
          <Grid container>
            <Grid item xs={12}>
              <CustomBox>
                <Box>
                  {/* <CustomSubHeadingText>{title}</CustomSubHeadingText> */}
                  <Box style={webStyles.Box} id="termsCondsList">
                    <Typography style={{...webStyles.text,wordBreak:'break-all'}} dangerouslySetInnerHTML={{ __html: termsConds?.attributes?.description }} />
                  </Box>
                </Box>
              </CustomBox>
            </Grid>
          </Grid>
          {this.renderFooter()}
        </Box>
          </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  Box: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "25px"
  },
  mainText:{
    marginBottom:"0px"
  },
  text: {
    color: "#AEAEB2",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Manrope",
  },
  footer: {
    display:'flex',
    justifyContent:'center',
    width:'100%'
  }
}
// Customizable Area End
