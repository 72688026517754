import React from "react";

// Customizable Area Start
import {Box,InputAdornment, Table ,  TableCell, TableContainer, TableHead, TableRow, Grid} from '@material-ui/core';
import {NoRecordFoundRow, GoldishSimpleButton, SearchTextFieldActiveBooking,AddLegtTypeButton, StyledPagination, renderTableLoader} from "../../../components/src/MuiCustomComponents";
import {theme as colors} from "../../../components/src/utils";
import {SwapVert, Search} from "@material-ui/icons";
import {EachAdminRow} from "./assets";



const headerArray = [
  {title:'Account ID',value:'account_id'},
  {title:'Name',value:'name'},
  {title:'Email ID',value:'email_address'},
  {title:'Job Title',value:'job'},
  {title:'Account Type',value:'account_type'}
]

// Customizable Area End

import AdminListController, {
  Props,
    configJSON,
} from "./AdminListController";

export default class AdminList extends AdminListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainContainer=()=>{
    return <Box className='dash_main booking_main'>
      {this.renderAdminListHeader()}
      {this.renderAdminListTable()}
      {this.renderPagination()}
    </Box>
  }




  renderAdminListHeader=()=>{
    return <Grid container justifyContent="space-between" style={{alignItems:'center',rowGap:'1rem'}}>
      <Grid item lg={2} sm = {3} >
      <GoldishSimpleButton
                  style={{
                    color: colors.GOLD_COLOR,
                    textTransform: "capitalize",
                    justifyContent: "start",
                    fontSize:"1.2rem",
                  }}
                  className='cf-avinode-search'
                >
              {configJSON.adminAccountHead}
            </GoldishSimpleButton>
      </Grid>
      <Grid item lg={6} justifyContent="center">
        <SearchTextFieldActiveBooking
          fullWidth
          data-testid='search_field'
          placeholder={'Enter name, email or account ID...'}
          className="search_admins"
          onChange={this.handleQueryDebounce}
          style={{ position: "relative", left: "-10px" ,width:'80%'}}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Search style={{ color: "#757677" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item lg={2} md={3} sm={12}>
        <AddLegtTypeButton onClick={this.handleCreateAdminNavigation} style={{fontSize:"1rem"}} fullWidth>+ Create New Admin</AddLegtTypeButton>
      </Grid>
    </Grid>
  }


  renderAdminListTable=()=>{
    return <TableContainer className="dash_table_flights" style={{marginTop:"2rem"}}>
      <Table style={{borderCollapse:"separate"}}>
        {this.renderTableHeadRow()}
        <Box height={'15px'}/>
        {this.renderTableCellContainer()}
      </Table>
    </TableContainer>
  }

  renderTableHeadRow=()=>{
    return <TableHead>
      <TableRow className="dash_table_head" style={{height:"auto"}}>
        {headerArray.map((elem)=>this.renderHeadCell(elem))}
      </TableRow>
    </TableHead>
  }

  renderHeadCell=(head:{title:string,value:string})=>{
    const {title,value} = head;
    const {sortedValue} = this.state;
    return <TableCell style={{borderRadius:"0"}}>
      <Box className="order_detail_table_head_cell">
            <Box>{title}</Box>
            <Box 
        id="order_detail_view_menu_sort_button" data-testid="menu_sort_button" 
        className={value === sortedValue ? 'order_detail_view_menu_sorted' : ''}
        onClick={()=>{this.handleSortChange(value)}} 
        >
          <SwapVert style={{color : colors.GOLD_COLOR}} /></Box>
      </Box>
    </TableCell>
  }

  renderTableCellContainer=()=>{
    const {isLoading} = this.state;
    return <>
      {!isLoading ? this.renderNotLoadingContent() :this.renderLoader()}
    </>
  }

  renderNotLoadingContent=()=>{
   const {adminListData} = this.state;
   return <>
    {adminListData.length !== 0 ? (adminListData as EachAdminRow[]).map((elem:EachAdminRow)=>this.renderEachRowInfo(elem)) : <NoRecordFoundRow>{null}</NoRecordFoundRow>}
   </>
  }

  renderLoader=()=>{
    const colspan = 3;
    return <TableCell colSpan={colspan} style={{borderBottom:'none'}}>
      {renderTableLoader()}
    </TableCell>
  }

  renderEachRowInfo=(rowInfo:EachAdminRow)=>{
    const {attributes:{account_id,email,full_name,job_title,account_type,account_number}} = rowInfo;
    return <>
      <TableRow className="dash_table_row order_details_row" onClick={()=>this.handleNaivigationToProfile(account_id)}>
        <TableCell className="dash_rounded-row-first-cell">{account_number}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{full_name}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{email}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{job_title || '- - -'}</TableCell>
        <TableCell className={`dash_rounded-row-last-cell`} style={{textTransform:'capitalize',color:this.handleRoleColor(account_type)}}>{this.handleRole(account_type)}</TableCell>
      </TableRow>
      <Box height={'0.5rem'}/>
    </>
  }

  renderPagination=()=>{
    const {totalPage, currentPage} = this.state;
    return <Box marginTop={'1rem'}>
      <StyledPagination 
        count={totalPage}
        page={currentPage}
        shape="rounded"
        className="pagination"
        onChange={(_event:React.ChangeEvent<unknown>,page:number)=>{this.handleCurrentPage(page);}}
        >
      </StyledPagination>
    </Box>
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
       <Box className="main_cont"> 
        {this.renderMainContainer()}
       </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const webStyle = {
  // custom style definitions
};
// Customizable Area End