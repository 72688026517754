import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import {getStorageData} from "../../../framework/src/Utilities";
import {handleNavigation} from "../../../components/src/Navigator";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    isJobTItleModalOpen:boolean;
    isJobTItleModalOpenForEdit:boolean;
    jobTItleData:any,
    editJobTItleData:any,
    message:string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class JobtitlesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAllJobTitleCallApiId:string = ''
    deleteJobTitleApiId:string = ''
    addJobTitleApiId:string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            isJobTItleModalOpen:false,
            jobTItleData:[],
            isJobTItleModalOpenForEdit:false,
            editJobTItleData:null,
            message:''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if(apiRequestCallId === this.getAllJobTitleCallApiId){
            this.handleJobTitleResponse(responseJson);
            }else if(apiRequestCallId === this.addJobTitleApiId){
              this.handleAddOrEDitJobTItleResponse(responseJson);
            }else if(apiRequestCallId === this.deleteJobTitleApiId){
              this.handleAddOrEDitJobTItleResponse(responseJson,true);
            }
          }
        // Customizable Area End
    }



    // Customizable Area Start

    async componentDidMount() {
        this.getAllJobTitleData();
      }

      handleJobTitleNavigation=()=>{
        handleNavigation('/',this.props,this.send);
      }


    handleJobTItleOpener=(isOpen:boolean,isEdit = false,editJobTItleData=null)=>{
        this.setState({isJobTItleModalOpen:isOpen,isJobTItleModalOpenForEdit:isEdit,editJobTItleData});
    }

    handleAddJobTItleAddorEdit=(values:any)=>{
      const {isJobTItleModalOpenForEdit,editJobTItleData} = this.state;
      
      const jobData = {
        data: {
          job_title : values.jobTitle
        }
      }
      if(!isJobTItleModalOpenForEdit){
        this.messageHandler('Job Added Successfully')
        this.addJobTItle(jobData);
      }else{
        this.messageHandler('Job Updated Successfully')
        this.addJobTItle(jobData,editJobTItleData.id,configJSON.updateJobTItleMehod);
      }
    }

    messageHandler=(alertMssage:string)=>{
      this.setState({message:alertMssage});
    }

    getAllJobTitleData=async()=>{

        const headers = {
          token : await getStorageData('token')
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
          
        this.getAllJobTitleCallApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAllJobTItleEndPoint
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      deleteJobTItle=async(id:any)=>{
        this.messageHandler('Job Deleted Successfully')
        const headers = {
          token : await getStorageData('token')
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteJobTitleApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAllJobTItleEndPoint +'/' +id
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.deleteJobTItleMehod
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      addJobTItle=async(data:any,id='',method = configJSON.exampleAPiMethod)=>{
        const headers = {
          token : await getStorageData('token'),
          'Content-type':'application/json'
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
          
        this.addJobTitleApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAllJobTItleEndPoint + '/'  + id
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(data)
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      handleJobTitleResponse=(responseJson:any)=>{
        if(responseJson.data){
            const { data} = responseJson;
            this.setState({jobTItleData:data});
        }
      }


      handleAddOrEDitJobTItleResponse=(responseJson:any,deleteMode=false)=>{
        const {message} = this.state;
        if(responseJson.meta.message){
          const {data} = responseJson;
          this.setState({jobTItleData:[...this.replaceOrAddElement(data,deleteMode)],isJobTItleModalOpen:false,isJobTItleModalOpenForEdit:false,editJobTItleData:null})
          this.handleJobTItleTriggerAlertMessage(message);
        }
      }



      replaceOrAddElement = (element:any, deleteMode = false) => {
        if (deleteMode) {
          const newData = this.state.jobTItleData.filter((item:any) => item.id !== element.id);
          return newData;
        }
      
        let replaced = false;
      
        const newData = this.state.jobTItleData.map((item:any) => {
          if (item.id === element.id) {
            replaced = true;
            return element;
          }
          return item;
        });
      
        if (!replaced) {
          newData.push(element);
        }
      
        return newData;
      }

      handleJobTItleTriggerAlertMessage=(mesage:string)=>{
        const gobTigerrer = new Message(getName(MessageEnum.AlertMessage));
        gobTigerrer.addData(getName(MessageEnum.AlertBodyMessage), mesage);
        this.send(gobTigerrer);
      }


    // Customizable Area End
}
