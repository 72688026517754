import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {getStorageData} from "../../../framework/src/Utilities";
import {FigureType,ClientTogglerType,TotalBookingTogglerType,revenueCardTitleAndStyleTypes, OutstandingPayment, BookingData, UpcomingBookingRoot, DashboardResponseHandle, TotalUpcomingBookTogglerType, downloadsTogglerType, AppMatricesCounts, UpcomingBookingAttribute,OutstandingPaymentData,} from './types';
import moment from "moment";
import {handleNavigation} from '../../../components/src/Navigator';


const initialValueCount = {
  count : 0,
  total : ''
}


// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    upcoming_booking : UpcomingBookingRoot[],
    outstanding_payments:OutstandingPayment[] | [],
    upcoming_booking_count:{
      all : FigureType,
      next_30 : FigureType,
      next_90 : FigureType,
      this_week : FigureType,
    },
    total_bookings:{
      mtd:FigureType,
      prev_60:FigureType,
      prev_90:FigureType,
      ytd:FigureType
    },
    total_client:{
      overall:FigureType,
      last_week:FigureType,
      last_month:FigureType,
      last_year:FigureType
    },
    app_metrics:{
      mtod:AppMatricesCounts,
      previous_90:AppMatricesCounts,
      all_time:AppMatricesCounts,
    }
  };
  totalBookingToggler : TotalBookingTogglerType;
  totalClientToggler: ClientTogglerType
  totalCandidates: string;
  totalUpcomingBookingToggler:TotalUpcomingBookTogglerType;
  totalDownloadsToggler:downloadsTogglerType
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  revenueCardTitleAndStyle:revenueCardTitleAndStyleTypes[];
  name:string | null | undefined;
  searchValue:string|null,
  bookings:BookingData[]
  selectedOption:BookingData|null,
  isDashboardDataLoading : boolean,
  sortDescending:boolean,
  sortType:string,
  outstandingSort:string,
  outstandingDescendingSort:boolean,
  focusSearchBar:boolean,
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  dashboardSearchAPICallId:string = "";
  timer:NodeJS.Timeout|null = null
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: {
        upcoming_booking:[],
        outstanding_payments:[],
        upcoming_booking_count:{
          all : initialValueCount,
          next_30 : initialValueCount,
          next_90 : initialValueCount,
          this_week : initialValueCount,
        },
        total_bookings:{
          mtd:initialValueCount,
          prev_60:initialValueCount,
          prev_90:initialValueCount,
          ytd:initialValueCount
        },
        total_client:{
          last_month:initialValueCount,
          last_week:initialValueCount,
          last_year:initialValueCount,
          overall:initialValueCount
        },
        app_metrics:{
          mtod:{ios:0,android:0,total:0},
          previous_90:{ios:0,android:0,total:0},
          all_time:{ios:0,android:0,total:0},
        }
      },
      totalBookingToggler:'mtd',
      totalClientToggler:'overall',
      totalUpcomingBookingToggler:'all',
      totalDownloadsToggler:"mtod",
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      name : null,
      revenueCardTitleAndStyle:[{color:"#CC8500",title:"Yesterdays"},{color:"#996300",title:"Last Weeks"},{color:"#664200",title:"Monthly"},{color:"#332100",title:"90 Day"}],
      searchValue:"",
      bookings:[],
      selectedOption:null,
      isDashboardDataLoading: true,
      sortDescending:false,
      sortType:"departure_date",
      outstandingSort:"date",
      outstandingDescendingSort:false,
      focusSearchBar:false,
      
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    await this.handleName();
    this.getDashboardDataAdmin();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId:unknown = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.handleDashboardData(webResponseJson);
        }

        if(webApiRequestCallId === this.dashboardSearchAPICallId){
          const accountIdOptions = webResponseJson.Account_Id.map((option:BookingData)=>{
            option.title = "Booking Number"
            return option
          })
          const bookingIdOptions = webResponseJson.Booking_Number.map((option:BookingData)=>{
            option.title = "Booking Number"
            return option
          })
          const clientNameOptions  = webResponseJson.Client_Name.map((option:BookingData)=>{
            option.title = "Booking Number"
            return option
          })
          this.setState({bookings:[...accountIdOptions,...bookingIdOptions,...clientNameOptions],loading:false})
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false,
            isDashboardDataLoading:false
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleDashboardData=(responseJson:DashboardResponseHandle)=>{
    const {upcoming_booking, outstanding_payments, upcoming_booking_count, total_bookings,total_client,app_metrics} = responseJson;
    const booking = upcoming_booking as []
    booking.reverse()
    this.setState({isDashboardDataLoading:false,dashboardData:{...this.state.dashboardData,upcoming_booking:booking,outstanding_payments,upcoming_booking_count,total_bookings,total_client,app_metrics}})
  }

  handleName=async()=>{
    const name = await getStorageData('name');
    this.setState({name})
  }

  getDashboardDataAdmin = async()=> {
    const header = {
      token :  await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleToggler=(togglerKey:'totalBookingToggler'| 'totalClientToggler' | 'totalUpcomingBookingToggler' | "totalDownloadsToggler"  ,toggler:string)=>{
    if(togglerKey === 'totalBookingToggler'){
      this.setState({totalBookingToggler:toggler as TotalBookingTogglerType})
    }else if(togglerKey === 'totalClientToggler'){
      this.setState({totalClientToggler:toggler as ClientTogglerType})
    }else if(togglerKey === "totalUpcomingBookingToggler"){
      this.setState({totalUpcomingBookingToggler:toggler as TotalUpcomingBookTogglerType})
    }
    else{
      this.setState({totalDownloadsToggler:toggler as downloadsTogglerType })
    }
  }

  handleDate=(date:string)=>{
    return moment(date).format('DD-MM-YYYY');
  }

  handleNavigationToBooking=(endPoint:string)=>{
    handleNavigation(endPoint,this.props,this.send);
  }

  handleVendor=(vendor:string)=>{
    if(vendor === 'vendor_pending'){
      return 'Vendor Pending'
    }else if(vendor === 'vendor_confirmed'){
      return 'Vendor Confirmed'
    }
    return 'Trip Cancelled'
  }

  handleVendorColor=(vendor:string) => {
    if(vendor === 'vendor_pending'){
      return '#FFB833';
    }else if(vendor === 'vendor_confirmed'){
      return '#20E70E'
    }
      return '#FE4023'
  }

  handleSearchValue = (event:React.ChangeEvent<HTMLInputElement>)=>{
    
    const timeToHold = 1500;
        const value = event.target.value?.trim();
        if(value){
          if(this.timer){
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(()=>{
            this.handleDebounceGlobalSearch(value)
        },timeToHold)
        } 
        else{
          if(this.state.searchValue && this.timer){
            this.setState({searchValue:"",bookings:[],loading:false},()=>{
              this.clearSearchTimer()
              return false
            })
          }
          return false
        }  

  }
  clearSearchTimer = ()=>{
    if(this.timer){
      clearTimeout(this.timer)
    }
  }
  
  handleDebounceGlobalSearch = (value:string)=>{
    this.setState({searchValue:value,loading:true},()=>this.handleDashboardSearch(value))
  }

  handleDashboardSearch =async (value:string)=>{
    
    
    const header = {
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.dashboardSearchAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.dashboardSearchAPIEndpoint}=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getAPIMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  handleNavigationToBookings = (selectedValue:BookingData)=>{
    
    handleNavigation(`OrderManagement?booking_id=${selectedValue?.id}`,this.props,this.send)
    

  }

  handleSelectBookingFromOptions = (option:BookingData)=>{
    this.setState({selectedOption:option})
  }

  getSelectedOptionTitle = (option:BookingData)=>{
    if(option.title === "Booking Number"){
      if(option.full_name){
        return `${option.booking_no} - ${option.full_name}`
      }
      else{
        return option.booking_no
      }

    }
    else{
      return `${option.client_id} - ${option.full_name}`
    }
  }

  handleSort = (value:keyof UpcomingBookingAttribute)=>{
    const {dashboardData:{upcoming_booking},sortDescending,sortType} = this.state
    const descendingSort = sortType === value ? !sortDescending :false
    const bookings = this.getSortedBookings(upcoming_booking, value, descendingSort);

    this.setState({dashboardData:{...this.state.dashboardData,upcoming_booking:[...bookings]},sortType:value,sortDescending:descendingSort})

  }

  handleOutstandingPaymentSort = (value:keyof OutstandingPaymentData)=>{
    const {dashboardData:{outstanding_payments},outstandingDescendingSort,outstandingSort} = this.state
    const descendingSort = outstandingSort === value ? !outstandingDescendingSort :false
    
    const bookings = outstanding_payments.sort((bookingOne,bookingTwo)=>{
      
      const firstSortValue = bookingOne.attributes.data[value]
      const secondValue = bookingTwo.attributes.data[value]
      if(descendingSort){
        return firstSortValue < secondValue ? 1 : -1
      }
      else{
        return firstSortValue > secondValue ? 1 : -1
      }

    })

    this.setState({dashboardData:{...this.state.dashboardData,outstanding_payments:[...bookings]},outstandingSort:value,outstandingDescendingSort:descendingSort})

  }
  getSortedBookings = (bookings: UpcomingBookingRoot[], value: keyof UpcomingBookingAttribute, descendingSort: boolean) => {
    return bookings.slice().sort((bookingOne, bookingTwo) => {
      const firstValue = value === "vendor" ? bookingOne.attributes[value].name:bookingOne.attributes[value]
      const secondValue = value === "vendor" ? bookingTwo.attributes[value].name:bookingTwo.attributes[value]
      const firstSortValue = value === "booking_amount" ? parseFloat(firstValue as string) : firstValue;
      const secondSortValue = value === "booking_amount" ? parseFloat(secondValue as string) : secondValue;
  
      if (descendingSort) {
        return firstSortValue < secondSortValue ? 1 : -1;
      } else {
        return firstSortValue > secondSortValue ? 1 : -1;
      }
    });
  }

  getCorrectAmount=(amountFigure:string | number)=>{
      if(typeof amountFigure === 'string'){
        return '$'+amountFigure;
      }
      return "$" + amountFigure.toLocaleString();
  }
  handleFocusSearchbar = ()=>{
    this.setState({focusSearchBar:true})

  }

  handleBlur = ()=>{
    this.setState({focusSearchBar:false,bookings:[],searchValue:""})
  }


  // Customizable Area End
}
