import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import { handleLogOut,handleNavigation } from '../../../components/src/Navigator'
import {getStorageData} from "../../../framework/src/Utilities"
import React from "react";


interface ClientAccount{
  id:string,
  client_name:string
}

interface BookingInvoice{
id:string,
client_name:string,
booking_id:string,
invoice_number:string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  screen?: React.ReactNode
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: string;
  drawerItems: [];
  role : string;
  name : string | null,
  photo :null|string,
  isMessageVisible : boolean,
  alertMessage:string,
  isUpcomingBookingTouched:boolean,
  filterType:string|null,
  searchValue:string,
  options:ClientAccount|BookingInvoice[],
  selectedOption:ClientAccount|BookingInvoice,
  loading:boolean,
  focus:boolean;
  isDeleteAccountModalOpen:boolean; 
  isAlertType: 'success' | 'fail'
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  globalSearchAPICallId: string = "";
  deleteAccountApiId:string=""
  timer : NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      role : 'admin',
      name :'',
      photo : null,
      isMessageVisible:false,
      alertMessage:'',
      isUpcomingBookingTouched:true,   
      filterType:"booking_no",
      searchValue:"",
      options:[],
      selectedOption:{id:"",client_name:""},
      loading:false,
      focus:false,
      isDeleteAccountModalOpen:false,
      isAlertType: 'success'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start


    if (getName(MessageEnum.NavigationMessage) === message.id) {
      this.handleUserNameAndPicture();
    }

    if (getName(MessageEnum.AlertMessage) === message.id) {
      let alertMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      let alertType = message.getData(getName(MessageEnum.AlertTypeMessage));
      console.log('alertType',alertType);
      this.handleMessageOpener(true,alertMessage, alertType);
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
    }
     else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id        
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      
      if (responseJson) {
        this.setState({ drawerItems: responseJson });

        this.handleDeleteAccountResponse(apiRequestCallId,responseJson);

        if(apiRequestCallId === this.globalSearchAPICallId)
        if(!responseJson.errors){
          this.setState({options:responseJson,loading:false})
        }
        
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleLogout=()=>{
    handleLogOut(undefined,this.props,this.send);
  }

  handleUserNameAndPicture=async()=>{
    const name = await getStorageData('name');
    const photo = await getStorageData('photo');
    this.setState({name:name,photo:photo});
  }

  handleMessageOpener =(isOpen:boolean,message = '', alertType: "success" | "fail" = "success")=>{
    this.setState({isMessageVisible:isOpen,alertMessage:message, isAlertType: alertType})
  }

  async componentDidMount() {
    this.handleUserNameAndPicture();  
  }

  handleUpcomingAndPreviousToched=(isTouched:boolean)=>{
    this.setState({isUpcomingBookingTouched:isTouched})
  }

  handleTermsConditionsEdit=()=>{
    const currentPath = window.location.pathname;
    if(currentPath === '/TermsConditionsEdit' || currentPath === '/TermsConditions'){
      return true;
    }
    return false;
  }

  handlePrivacyEdit=()=>{
    const currentPath = window.location.pathname;
    if(currentPath === '/PrivacyPolicy' || currentPath === '/PrivacyPolicyEdit'){
      return true;
    }
    return false;
  }

  handleCreateBooking=()=>{
    const currentPath = window.location.pathname;
    if(currentPath === '/cfavinodeintegration2' || currentPath === '/AdvancedSearch'){
      return true;
    }
    return false;
  }

  handleFAQs=()=>{
    const currentPath = window.location.pathname;
    if(currentPath === '/FAQ' || currentPath === '/AddFAQ' || currentPath === '/EditFAQ'){
      return true;
    }
    return false;
  }

  handlePreviousAndUpcomingHighlight=(mainPath:string,lastClicked:boolean)=>{
    const currentPath = window.location.pathname;
    if(currentPath === mainPath || (currentPath === '/OrderManagement' && lastClicked)){
      return true
    }
    return false;
  }

  handleAdminListHighlight=()=>{
    const currentPath = window.location.pathname;
    if(currentPath === '/AdminProfile' || currentPath === '/AdminList'){
      return true;
    }
    return false;
  }

  handleHighlightClientsTab = (currentTab:string)=>{
    const clientPath = window.location.pathname.startsWith(currentTab)||window.location.pathname.startsWith("/AdminConsole2")
    if(currentTab === "/"){
      return  window.location.pathname === '/';
    }
    return clientPath
  }

  handleFilterChange = (event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({filterType:event.target.value,options:[],searchValue:"",focus:false})
  }

  handleGlobalSearchValue = (event:React.ChangeEvent<HTMLInputElement>)=>{
    
    const timeToHold = 1500;
        const value = event.target.value?.trim();
        if(value && this.state.filterType){
          if(this.timer){
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(()=>{
            this.handleDebounceGlobalSearch(value)
        },timeToHold)
        } 
        else{
          if(this.state.searchValue && this.timer){
            this.setState({searchValue:"",options:[],loading:false},()=>{
              this.clearSearchTimer()
              return false

            })
          }
          return false
        }                                   
  }
  clearSearchTimer = ()=>{
    if(this.timer){
      clearTimeout(this.timer)
    }
  }

  handleDebounceGlobalSearch = (value:string)=>{
    this.setState({searchValue:value,loading:true},()=>this.handleGlobalSearch(value))
  }


  handleGlobalSearch =async (value:string)=>{

    const header = {
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.globalSearchAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.globalSearchAPIEndpoint}=${this.state.filterType}&query=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  deleteAccount=async()=>{
    const headers = {
      token : await getStorageData('token')
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAccountApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleGlobalSearchNavigation = (selectedOption:ClientAccount|BookingInvoice)=>{
    if(!selectedOption){
      return false
    }

    if(this.state.filterType === "account_id"||this.state.filterType==="client_name"){
      handleNavigation(`/AdminConsole2?client_id=${selectedOption.id}`,this.props,this.send)
    }
    else{
      handleNavigation(`OrderManagement?booking_id=${selectedOption.id}`,this.props,this.send)
    }
    this.setState({searchValue:"",options:[],focus:false,},()=>{
      this.navigations(selectedOption)
    })




  }

  handleFocusSearchbar = ()=>{
    this.setState({focus:true})

  }

  handleBlur = ()=>{
    this.setState({focus:false,options:[],searchValue:""})
  }

  navigations = (selectedOption:ClientAccount|BookingInvoice)=>{
    if(this.state.filterType === "account_id"||this.state.filterType==="client_name"){
      handleNavigation(`/AdminConsole2?client_id=${selectedOption.id}`,this.props,this.send)
    }
    else{
      handleNavigation(`OrderManagement?booking_id=${selectedOption.id}`,this.props,this.send)
    }

  }


  handleDeleteAccountModal=(isOpen=false)=>{
    this.setState({isDeleteAccountModalOpen:isOpen});
  } 
  
  handleDeleteAccountResponse=async(apiRequestCallId:string,responseJson : {message : string})=>{
    if(apiRequestCallId === this.deleteAccountApiId){
        const {message} = responseJson;
        this.handleDeleteAccountModal();
        if(message === 'Your account has been deleted successfully') {
          this.handleLogout();
        }
    }
  }

  // Customizable Area End
}
