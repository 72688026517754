import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {getStorageData} from "../../../framework/src/Utilities";
import {handleNavigation} from "../../../components/src/Navigator"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
    systemSettingsValues:{
      markup_percentage:null | number,
      domestic_segment_fee:null | number,
      international_segment_fee:null | number,
      landing_fee:null | number,
      payment_processing_fee:null | number,
      tax:null | number
    },
    isEditMode:boolean,
    touchedLabel:null | string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
   getSystemSettingsApiCallId:string = ''
   formikForm: any = null;
   postSystemSettingsApiCallId:string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      systemSettingsValues:{
          markup_percentage:null,
          domestic_segment_fee:null,
          international_segment_fee:null,
          landing_fee:null,
          payment_processing_fee:null,
          tax:null
      },
      isEditMode:false,
      touchedLabel:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getSystemSettingsApiCallId){
        this.handleGetSystemSettingsResponse(responseJson);
      }else if(apiRequestCallId === this.postSystemSettingsApiCallId){
        this.handleGetSystemSettingsResponse(responseJson);
        this.handleTriggerAlertMessage();
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    this.getSettingInfo();     
  }

  handleEditMode=(isEdit:boolean)=>{
    this.setState({isEditMode:isEdit});
  }

  handleActiveFocusedLabel=(label:string | null)=>{
    this.setState({touchedLabel:label})
  }

  handleGoBack=()=>{
    const {isEditMode} = this.state;
    if(isEditMode){
      this.setState({isEditMode:false});
    }else{
      handleNavigation('/',this.props,this.send);
    }
  }

  handleGetSystemSettingsResponse=(responseJson:any)=>{
      if(responseJson.meta.message){
        const {data:{attributes:{domestic_segment_fee,markup_percentage,international_segment_fee,landing_fee,payment_processing_fee,tax}}} = responseJson
        this.setState({
          systemSettingsValues:{
            domestic_segment_fee:domestic_segment_fee,
            international_segment_fee:international_segment_fee,
            landing_fee:landing_fee,
            markup_percentage:markup_percentage,
            payment_processing_fee,
            tax
        }})
      }
  }


  getSettingInfo= async()=>{
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      
    this.getSystemSettingsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSystemSettingsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEditSystemSettings= async(values:any)=>{
    const dataToSend = {data:values};
    const headers = {
      'token' : await getStorageData('token'),
      'Content-type':'application/json'
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postSystemSettingsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSystemSettingsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToSend)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTriggerAlertMessage=()=>{
      this.setState({isEditMode:false});
      const saveAlertMessage = new Message(getName(MessageEnum.AlertMessage));
      saveAlertMessage.addData(getName(MessageEnum.AlertBodyMessage), "Changes saved successfully");
      this.send(saveAlertMessage);
  }

  // Customizable Area End
}
