import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  FormControl,
  Button,
  PopperProps,
  TextFieldProps
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceSearch.css";
import {FlightTakeoff, Flight, AddOutlined, RemoveOutlined, KeyboardArrowDown, FlightLand, AirplanemodeActive,DateRangeOutlined,AccountCircleOutlined, SyncAlt, DeleteOutline} from "@material-ui/icons";
import { TimePickerNew, StyledGrid, SearchStyledPopper,FormSubmitButton, PassengerBox, CustomMuiButtonPassenger, CustomFormAircraftSelect, CustomDateFormTextField, ButtonTogglerFormContainer, CustomFormTabButton, CustomFormTabPanel, SearchStyledAutocomplete, SearchStyledTextField, AddLegtTypeButton, HorizantalRow, renderOptionFrom} from "../../../components/src/MuiCustomComponents";
import DatePicker from "react-datepicker";
import {theme as colors} from "../../../components/src/utils";
import {Formik, FormikProps , FieldArray, ArrayHelpers} from 'formik';
import {oneWayFormSchema, roundTripSchema, multiLegSchema} from "../../../components/src/Validations";
import lodashGet from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';
import {AirportCategoryType,PopularAiportType,OneWayFormTypes, FormikMethodType} from './AdvancedSearchController';





type OneWayFormikPropType = FormikProps<OneWayFormTypes>;
interface autoCompleteMenuType {
  selected:boolean
}


interface AircraftCategoryType{
  aircraftCategory : string
}

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const oneWayGeneralIntialValues = {
      fromLocation :  null,
      toLocation :  null,
      departure : null,
      passengers : 1,
      fromAirportResults : [...this.state.airports],
      toAirportResults : [...this.state.airports],
      isCalenderOpen:false,
      isTimePickerOpen:false
    }

    const aircraftInitialValue = {aircraftCategory:  'Aircraft Category'}

    const oneWayInitialVlaues = {
      ...oneWayGeneralIntialValues, 
      ...aircraftInitialValue
    }
    
    const twoWayFormInitialValues = {
      ...oneWayGeneralIntialValues,
      ...aircraftInitialValue,
      leg1Departure : null,
      leg1Passengers : 1,
      isCalenderOpen1:false,
      isTimePickerOpen1:false
    }
    
    const multiLegFormInitialValues = {
      ...aircraftInitialValue,
      trips: [
        { ...oneWayGeneralIntialValues },
        { ...oneWayGeneralIntialValues }
      ],
    };
    
    const {selectedTab} = this.state;
    const tomorrow = this.state.tomorrowDate;


const renderDateTextField = (formikProps: OneWayFormikPropType,departureDateKey:string,calenderOpenKey:string,timePickerOpenKey:string,selectedIndex=0) => {
  const { values, setFieldValue, touched, errors } = formikProps;
  const {focusIndex} = this.state;
  const zIndex =  focusIndex === selectedIndex ? (50 - selectedIndex) : 0; 
  return (
    <>
      <DatePicker
        locale="en-GB"
        selected={values[departureDateKey as unknown as keyof OneWayFormTypes] as unknown as Date}
        open={lodashGet(values,calenderOpenKey)}
        onChange={(date) => setFieldValue(departureDateKey, date)}
        onInputClick={()=>{setFieldValue(calenderOpenKey,true);this.handleFocusIndex(selectedIndex)}}
        onClickOutside={()=> !lodashGet(values,timePickerOpenKey) && setFieldValue(calenderOpenKey,false)}
        minDate={tomorrow as Date}
        key={uuidv4()}
        className="advance_search_date_pick"
        value={this.formatDate(lodashGet(values,departureDateKey))}
        timeInputLabel="Time"
        dateFormat={"MM/yyyy | h:mm aa"}
        showTimeInput
        placeholderText="Add date & time"
        customTimeInput={<TimePickerNew 
          setFunction = {(value:Date)=>setFieldValue(departureDateKey,value)}
          dateValue={lodashGet(values,departureDateKey)}
        />}
        popperProps={{style:{zIndex:zIndex-1}}}
        customInput={
          <CustomDateFormTextField
          key={uuidv4()}
            placeholder="Add date & time"
            className={`login_test_password ${
              lodashGet(touched,departureDateKey) && lodashGet(errors,departureDateKey)
                ? "login_border_error"
                : ""
            }`}
            InputProps={{
              style: {backgroundColor : 'black',zIndex : zIndex},
              disableUnderline:true,
              startAdornment: (
                <InputAdornment position="start">
                  <DateRangeOutlined />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        }
      />
       {lodashGet(touched,departureDateKey) && lodashGet(errors,departureDateKey) && (
        <Box className="login_valid_error" id="profile_error_properties">
          {lodashGet(errors,departureDateKey)}
        </Box>
      )}
    </>
  )
}

const renderSubmit = (submitFunction:()=>void) => {
  return (
    <FormSubmitButton
      onClick={() => submitFunction()}
      fullWidth
      style={{ fontWeight: "bolder" }}
      className="advance_search_test_submit"
    >
      Search
    </FormSubmitButton>
  )
}

const renderOption = (option: string,isSelected:boolean) => {
  return (
    <>
      <Box className={`advance_search_option ${isSelected && 'advance_search_selected_option'}`}>
        <Box>
          <Flight style={{ color: `${!isSelected ? '#34D399' : 'black'}`, fontSize: "1.2rem" }} />
        </Box>
        <Box>{option}</Box>
      </Box>
    </>
  )
}


const renderPassenger = (formikProps: OneWayFormikPropType,passengerKey:string) => {
  const { values, setFieldValue } = formikProps

  return (
    <PassengerBox>
      <Box marginLeft="10px">
        <AccountCircleOutlined />
      </Box>
      <Box>
        <Box className="advance_search_passenger_placeholder" color={colors.GRAY_COLOR_FORM}>Passengers</Box>
        <Box className="advance_search_passenger">
          <CustomMuiButtonPassenger
            disabled={lodashGet(values,passengerKey) === 1}
            onClick={() => {
              setFieldValue(passengerKey, lodashGet(values,passengerKey) - 1)
            }}
            className="advance_search_pass_button_test"
          >
            <RemoveOutlined style={{ color: "white", fontSize: "1.3rem" }} />
          </CustomMuiButtonPassenger>
          <Box>{lodashGet(values,passengerKey)}</Box>
          <CustomMuiButtonPassenger
            onClick={() => {
              setFieldValue(passengerKey, lodashGet(values,passengerKey) + 1)
            }}
            className="advance_search_pass_button_test"
          >
            <AddOutlined style={{ color: "white", fontSize: "1.3rem" }} />
          </CustomMuiButtonPassenger>
        </Box>
      </Box>
    </PassengerBox>
  )
}

    const renderSelect = (formikProps:FormikProps<AircraftCategoryType>) => {
      const {values : {aircraftCategory}, setFieldValue, touched, errors} = formikProps;

      return (
        <Box className='advance_search_select_cont'>
                <FormControl
                  fullWidth
                  className={`mui_select_unkownn login_test_password ${
                    touched.aircraftCategory && errors.aircraftCategory
                      ? "login_border_error"
                      : ""
                  }`}
                >
                  <CustomFormAircraftSelect
                    fullWidth
                    style={{height:'4.5rem'}}
                    inputProps={{
                      IconComponent: KeyboardArrowDown,
                    }}
                    id='aircraftCategory'
                    onChange={(event) => {
                      setFieldValue("aircraftCategory",event.target.value);
                    }}
                    onFocus={()=>this.handleFocusIndex(null)}
                    value={aircraftCategory}
                    renderValue={(selectedValue:unknown) => {
                      return (
                        <Box className='advance_search_select_box'>
                          <AirplanemodeActive />
                         
                            <Box marginLeft='1rem'>{selectedValue as string}</Box>
                       
                        </Box>
                      )
                    }}
                    MenuProps={{
                      style:{marginTop: '5px',maxHeight:'65vh'},
                      getContentAnchorEl: null,
                      className: "advance_search_picker",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      disableScrollLock:true
                    }}
                    variant='outlined'
                  >
                    {this.state.aircraft && (this.state.aircraft as AirportCategoryType[]).map((aircraftType) => {
                      return (
                        <MenuItem value={aircraftType.label}>
                          {renderOption(aircraftType.label, aircraftCategory === aircraftType.value)}
                        </MenuItem>
                      )
                    })}
                  </CustomFormAircraftSelect>
                </FormControl>
                {touched.aircraftCategory && errors.aircraftCategory &&  (
        <Box className="login_valid_error" id="profile_error_properties">
          {errors.aircraftCategory}
        </Box>
      )}
        </Box>
      )
    }


const renderOneWayForm = (
  formikProps: OneWayFormikPropType,
  fromLocationKey:string,
  toLocationKey:string,
  fromAirportResults:string,
  toAirportResults:string,
  departureDateKey:string,
  passengerKey:string,
  calenderOpenKey:string,
  timePickerOpenKey:string,
  formikMethodType:string,
  isDisable= false,
  selectedIndex=0
  ) => {
  return (
    <>      
            <Grid container style={{rowGap:'1.5rem',width:'99.5%',marginLeft:'0.16rem'}}>
              <Grid container item md = {12} sm={12} lg={10}  spacing={2} style={{margin:'0',rowGap:'1.5rem'}}>
              <Grid item md = {6} sm={6} lg={4}>
                {renderFromFlight(formikProps,fromLocationKey,fromAirportResults,formikMethodType,isDisable,selectedIndex)}
              </Grid>
              <Grid
                item
                md={undefined}
                style={{ position: "relative", padding: "0px", height: "6rem" }}
              >
                {renderExhangeIcon(formikProps,fromLocationKey,toLocationKey,selectedIndex)}
              </Grid>
              <Grid item md = {6} sm={6} lg={4}>
                {renderToFlight(formikProps,toLocationKey,toAirportResults,formikMethodType,isDisable,selectedIndex)}
              </Grid>
              <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth} item md = {12} sm={12} lg={4}>
                {renderDateTextField(formikProps,departureDateKey,calenderOpenKey,timePickerOpenKey,selectedIndex)}
              </StyledGrid>
              </Grid>
              <Grid container item md = {12} sm={12} lg={2} spacing={2} style={{margin:'0',rowGap:'1.5rem'}}>
              <Grid item md = {12} sm={12} lg={12}>
                {renderPassenger(formikProps,passengerKey)}
              </Grid>
              </Grid>
            </Grid>
          </>
  )
}

const labelProvider=(value:{code:string,city:string,countryCode:string})=>{
  if(value){
     return `${value.code} - ${value.city}, ${value.countryCode}`;
  }
}

const renderFromFlight = (formikProps: FormikProps<OneWayFormTypes>,fromLocationKey:string,optionsKey:string,formTypeMethod:string,isDisable:boolean,selectedIndex:number) => {
  const { values, setFieldValue, touched, errors } = formikProps;
  const {isAirportloading,focusIndex} = this.state;
  const zIndex = focusIndex === selectedIndex ? 50 - selectedIndex : 0
  return (
    <>
      <SearchStyledAutocomplete
        loading={isAirportloading}
        options={lodashGet(values,optionsKey)}
        onFocus={() => {this.handleFocusIndex(selectedIndex)}}
        onBlur={() => {this.handleFocusIndex(null)}}
        filterOptions={(options:PopularAiportType) => options}
        PopperComponent={(props:PopperProps)=>(<SearchStyledPopper {...props} zIndex={zIndex-1} paddingTop={'1rem'} marginTop={'-10px'}/>)}
        style={{ zIndex:zIndex, position: 'relative',backgroundColor:'black' }}
        id="fromLocation"
        onChange={(_event: React.ChangeEvent<{}>, newValue: unknown) => {
          setFieldValue(fromLocationKey, newValue)
        }}  
        ListboxProps={{ id: "flight_list_container" }}
        disabled={isDisable}
        getOptionLabel={(option:{name:string}) => `${option.name}`}
        value={lodashGet(values,fromLocationKey)}
        renderOption={(option: PopularAiportType,{selected}:autoCompleteMenuType) => renderOptionFrom(option,selected)}
        renderInput={(params:TextFieldProps) => (
          <SearchStyledTextField
            {...params}
            className={`login_test_password ${this.conditionHandler(lodashGet(touched,fromLocationKey) && lodashGet(errors,fromLocationKey),"login_border_error","")}`}
            isValueSelected = {lodashGet(values,fromLocationKey)}
            variant="outlined"
            id="airporst-search"
            placeholder="From"
            onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
               this.handleFormikMethodAndList(formTypeMethod as FormikMethodType, optionsKey ,selectedIndex,event.target.value);
            }}
            label={labelProvider(lodashGet(values,fromLocationKey))}
            value={lodashGet(values,fromLocationKey)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <FlightTakeoff
                    color="action"
                    style={{ marginRight: 5, color: "white", marginLeft: -5 }}
                  />
                  {(params.InputProps)!.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {lodashGet(touched,fromLocationKey) && lodashGet(errors,fromLocationKey) && (
        <Box className="login_valid_error" id="profile_error_properties">
          {lodashGet(errors,fromLocationKey)}
        </Box>
      )}
    </>
  )
}

const renderToFlight = (formikProps: OneWayFormikPropType,toLocationKey:string,optionKey:string ,formMethodType:string,isDisable:boolean,selectedIndex:number) => {
  const { values, touched, errors } = formikProps
  const {isAirportloading,focusIndex} = this.state
  const zIndex =  focusIndex === selectedIndex ? 50 - selectedIndex : 0;
  return (
    <>
      <SearchStyledAutocomplete
        options={lodashGet(values,optionKey)}
        filterOptions={(options:PopularAiportType) => options}
        onFocus={() => {this.handleFocusIndex(selectedIndex)}}
        onBlur={() => {this.handleFocusIndex(null)}}
        style={{
          border:lodashGet(touched,toLocationKey) && lodashGet(errors,toLocationKey)
          ? "1px solid #F87171"
          : ""
          ,zIndex:zIndex, position: 'relative',backgroundColor:'black'}}
        loading={isAirportloading}
        ListboxProps={{ id: "flight_list_container" }}
        PopperComponent={(props:PopperProps)=>(<SearchStyledPopper {...props} zIndex={zIndex-1} paddingTop={'1rem'} marginTop={'-10px'}/>)}
        disabled={isDisable}
        getOptionLabel={(option:{name:string}) => `${option.name}`}
        renderOption={(option: PopularAiportType,{selected}:autoCompleteMenuType) => renderOptionFrom(option,selected)}
        id="toLocation"
        onChange={(_event: React.ChangeEvent<{}>, newValue: unknown) => {
          formikProps.setFieldValue(toLocationKey, newValue);
          this.handleMultiLegDependency(formikProps,selectedIndex,newValue);
        }}
        value={lodashGet(values,toLocationKey)}
        renderInput={(params:TextFieldProps) => (
          <SearchStyledTextField
            {...params}
            className={`login_test_password`}
            id = 'to_airports'
            isValueSelected = {lodashGet(values,toLocationKey)}
            variant="outlined"
            label={labelProvider(lodashGet(values,toLocationKey))}
            placeholder="To"
            onChange={async (event:React.ChangeEvent<HTMLInputElement>) => {
              this.handleFormikMethodAndList(formMethodType as FormikMethodType, optionKey,selectedIndex,event.target.value);
           }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <FlightLand
                    color="action"
                    style={{ marginRight: 5, color: "white", marginLeft: -5 }}
                  />
                  {(params.InputProps)!.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
     {lodashGet(touched,toLocationKey) && lodashGet(errors,toLocationKey) && (
        <Box className="login_valid_error" id="profile_error_properties">
          {lodashGet(errors,toLocationKey)}
        </Box>
      )}
    </>
  )
}

const renderExhangeIcon = (formikProps: OneWayFormikPropType,fromLocationKey:string,toLocationKey:string,selectedIndex=0) => {
  const { setFieldValue, values, validateForm } = formikProps;
  const {focusIndex} = this.state;
  const zIndex =  focusIndex === selectedIndex ? 51 - selectedIndex : 0;
  return (
    <Box
      style={{zIndex:zIndex+1}}
      onClick={() => {
        let fromLocation = lodashGet(values,fromLocationKey);
        setFieldValue(fromLocationKey,lodashGet(values,toLocationKey));
        setFieldValue(toLocationKey, fromLocation);
        validateForm();
      }}
      className="advance_search_exhange"
    >
      <SyncAlt />
    </Box>
  )
}

const renderOneWayFormParentFormik =()=>{
  const {oneWayStateValue} = this.state;
  return <Formik
  enableReinitialize
  key={3}
  initialValues={this.conditionHandler(oneWayStateValue,oneWayStateValue,oneWayInitialVlaues) as OneWayFormTypes}
  innerRef={(formikProps) => {
    this.oneWyFormikMethods = formikProps;
  }}
  onSubmit={(values) => {
    this.handleSubmitFunction(values);
  }}
  validationSchema={oneWayFormSchema}
  validateOnChange
  validateOnBlur
>
  {(formikProps: OneWayFormikPropType) => (
    <>
    <Box className="advance_search_form_parent advance_search_margin">
    <Box className = 'advance_search_margin_select'>{renderSelect(formikProps as unknown as FormikProps<AircraftCategoryType>)}</Box>
    {renderOneWayForm(formikProps,'fromLocation','toLocation','fromAirportResults','toAirportResults','departure','passengers','isCalenderOpen','isTimePickerOpen','oneWyFormikMethods')}
    </Box>
    <Box className="advance_search_button_leg_cont" width={'98.5%'} marginLeft={'0.16rem'}>
    {renderSubmit(formikProps.handleSubmit)}
    </Box>
    </>)}
    </Formik>
}

const renderMultiLegForm = () => {
  const {multiLegFormValues} = this.state;
  return (
    <Formik
      initialValues={this.conditionHandler(multiLegFormValues,multiLegFormValues, multiLegFormInitialValues as unknown as string) as OneWayFormTypes}
      validationSchema={multiLegSchema}
      key={1}
      onSubmit={(values) => {
       this.handleSubmitFunction(values);
      }}
      innerRef={(formikProps) => {
        this.multiLegFormikMethods = formikProps;
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
    >
      {(formikProps) => (
        <>
        <Box>
          <Box marginTop={'2.3rem'}>{renderSelect(formikProps as unknown as FormikProps<AircraftCategoryType>)}</Box>
          <FieldArray name="trips"  
          render={(arrayHelpers:ArrayHelpers)=>(
            <>
              <Box className="advance_search_form_parent advance_search_margin">
                {(formikProps as unknown as {values:{trips:OneWayFormTypes[]}}).values.trips?.length > 0 && (formikProps as unknown as {values:{trips:OneWayFormTypes[]}}).values.trips.map((_trip:unknown, index:number) => (
                  <React.Fragment key={index}>
                    {index != 0 && renderHorizantalRow()}
                    <Box key={index}>
                      {renderLegHeader(arrayHelpers.remove as unknown as ()=>void,index)}
                      {renderTripSegment(formikProps, index)}
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
              <Box className="advance_search_button_leg_cont" width={'98.5%'} marginLeft={'0.16rem'}>
                {renderAddLegButton(arrayHelpers.push as unknown as ()=>void,(formikProps as unknown as {values:{trips:OneWayFormTypes[]}}).values.trips?.length)}
                {renderSubmit(()=>{setTimeout(()=> formikProps.setFieldTouched('trips[1].fromLocation',true));formikProps.handleSubmit()})}
              </Box>
            </>
          )}
          
          />
        </Box>  
        </>
      )}
    </Formik>
  );
};

const renderHorizantalRow = () => {
  return (
      <Box className="advance_search_horizantal_row">
          <HorizantalRow  />
      </Box>
  )
}

const renderLegHeader = (remove:(remIndex:number)=>void,index:number)=>{
  return <Box className="advance_search_leg_header">
      <Box className="advance_leg_head" marginLeft={'0.16rem'}>Leg {index+1}</Box>
      {index  > 1 && <Button className="advance_search_remove_leg" onClick={() => remove(index)}><DeleteOutline style={{marginRight:"5px",fontSize:'large'}}/> Remove Leg</Button>}
  </Box>
}

const renderAddLegButton = (push:(formVal:OneWayFormTypes)=>void,index:number)=>{
  return <AddLegtTypeButton className="advance_search_add_leg_but" disabled = {index === 10} onClick={() => {push({ ...oneWayGeneralIntialValues }); setTimeout(()=>this.scrollToBottom()) }} fullWidth><AddOutlined/> Add Leg</AddLegtTypeButton>
}

const renderTripSegment = (formikProps:OneWayFormikPropType, index:number) => {
  return renderOneWayForm(
    formikProps,
    `trips[${index}].fromLocation`,
    `trips[${index}].toLocation`,
    `trips[${index}].fromAirportResults`,
    `trips[${index}].toAirportResults`,
    `trips[${index}].departure`,
    `trips[${index}].passengers`,
    `trips[${index}].isCalenderOpen`,
    `trips[${index}].isTimePickerOpen`,
    'multiLegFormikMethods',
    false,
    index
  );
};


const renderTwoWayFormParent = ()=>{
  const {roundTripStateValue} = this.state;
  return <Formik
  initialValues={this.conditionHandler(roundTripStateValue,roundTripStateValue,twoWayFormInitialValues) as OneWayFormTypes}
  enableReinitialize
  onSubmit={(values) => {
    this.handleSubmitFunction(values);
  }}
  innerRef={(formikProps) => {
    this.roundTripFormikMethods = formikProps;
  }}
  validationSchema={roundTripSchema}
  validateOnChange
  key={2}
  validateOnBlur
>
  {(formikProps: OneWayFormikPropType) => (
    <>
    <Box className="advance_search_form_parent advance_search_margin" id="advance_search_two_way_parent_form">
    <Box className = 'advance_search_margin_select'>{renderSelect(formikProps as unknown as FormikProps<AircraftCategoryType>)}</Box>
    {renderOneWayForm(formikProps,'fromLocation','toLocation','fromAirportResults', 'toAirportResults','leg1Departure','leg1Passengers','isCalenderOpen','isTimePickerOpen','roundTripFormikMethods',undefined,0)}
    {renderOneWayForm(formikProps,'toLocation','fromLocation','fromAirportResults', 'toAirportResults','departure','passengers','isCalenderOpen1','isTimePickerOpen1','roundTripFormikMethods',true,1)}
    </Box>
    <Box className="advance_search_button_leg_cont" width={'98.5%'} marginLeft={'0.16rem'}>
    {renderSubmit(formikProps.handleSubmit)}
    </Box>
    </>)}
    </Formik>
}

const renderformAccordingly=()=>{
  const {selectedTab} = this.state;
  if(selectedTab === 0){
    return renderOneWayFormParentFormik();
  }else if(selectedTab === 1){
    return renderTwoWayFormParent();
  }else{
    return renderMultiLegForm();
  }
}


    return (
      <Box className="advance_search_main">
        <ButtonTogglerFormContainer className="advance_search_button_container">
          <CustomFormTabButton
              selected={selectedTab === 0}
              onClick={()=>this.formToggler(0)}
              className="advance_search_one_way_button"
          >
              One Way
          </CustomFormTabButton>
          <CustomFormTabButton
              selected={selectedTab === 1}
              onClick={()=>this.formToggler(1)}
              className="advance_search_round_trip_button"
          >
              Round Trip
          </CustomFormTabButton>
          <CustomFormTabButton
              onClick={()=>this.formToggler(2)}
              selected={selectedTab === 2}
              className="advance_search_mutli_trip_button"
              style={{background : this.conditionHandler(selectedTab == 2, 'linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%)','#3E4247') as string}}
          >
              Multi Leg
          </CustomFormTabButton>
        </ButtonTogglerFormContainer>
        <CustomFormTabPanel>
        {renderformAccordingly()}
        </CustomFormTabPanel>
     </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
