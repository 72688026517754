import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {FormikProps} from 'formik'
import { getStorageData } from "../../../framework/src/Utilities";
import { handleTriggerAlertMessage } from "../../../components/src/Navigator";
const formInitValue = {price:null,category:'Category',cardName: null,id : null};

export interface AircraftCategoryType{
    "label": string,
    "value": string
}

interface AircraftCategoryResType{
  categories : AircraftCategoryType[]
}

export interface Card25hrType{
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "aircraft_category":string,
    "name": string,
    "about": string,
    "price": string
  }
}

interface Card25HrResType{
  data : Card25hrType[]
}

interface FormInitValueType{
  price:null |string,
  category:null | string,
  cardName:null | string,
  id : null | number
}

interface AddEdit25HrResType{
  data : Card25hrType
  meta : {message : string}
}

type CardType = null | 'debit' | '25hour';

interface DebitSkyCardResType{
	"data": {
		"id":string,
		"type": string,
		"attributes": {
			"id": number,
			"debit_sky_card_id": null | string,
			"name": string,
			"current_top_ups": DebitFormInitValueType[],
			"remaining_balance": string
		}
	}
}

export interface DebitFormInitValueType{
  "id"?: number,
  "top_up": string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeField:string,
  add25CardInitValue:FormInitValueType,
  isViewStateOpen:boolean,
  isEditStateOpen:boolean
  isAddStateOpen:boolean
  viewCardId:string | null
  isDeleteModalOpen:boolean,
  aircraftCategory: [] | AircraftCategoryType[],
  card25HrData : [] | Card25hrType[],
  cardType : CardType,
  debitSkyCardInitValue : {current_top_ups : DebitFormInitValueType[]}
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cf25hourskycardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cardFormik: null | FormikProps<unknown> = null;
  getAircarftCategroyApiId:string=''
  get25HrSkyCardsApiId:string=''
  addEditDeleteSkyCardApiId:string=''
  getDebitSkyCardApiId:string=''
  addEditDebitCardTopApiId:string =''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeField:'',
      add25CardInitValue:formInitValue,
      isViewStateOpen:false,
      isEditStateOpen:false,
      isAddStateOpen:false,
      viewCardId:null,
      isDeleteModalOpen:false,
      aircraftCategory:[],
      card25HrData: [],
      cardType:null,
      debitSkyCardInitValue :{current_top_ups : [{top_up:'0'}]}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson && !responseJson.errors){
        switch(apiRequestCallId){
          case this.getAircarftCategroyApiId:
          this.handleAircarftCatResponse(responseJson);  
          break;

          case this.get25HrSkyCardsApiId:
          this.handle25HrSkyCardResponse(responseJson);
          break;

          case this.getDebitSkyCardApiId:
          this.handleDebitCardResponse(responseJson);
          break;

          case this.addEditDeleteSkyCardApiId:
          this.handleAddEdit25HrResponse(responseJson);
          break;

          case this.addEditDebitCardTopApiId:
          this.handleAddEditDebitTopUp(responseJson);
          break;

        }
      }
    }
    
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.handleCardType();
    this.getAircraftCategory();

  }

  handleCardType=()=>{
    const {location:{pathname}} = window;
    let cardType:CardType = null;
    if(pathname === '/Manage25hourskycard'){
      cardType = '25hour';
      this.get25HrSkyCards();
    } 
    else if(pathname === '/Managedebitskycard3'){
      cardType = 'debit';
      this.getDebitSkyCard();
    } 
    this.setState({cardType});
  }

  handleActiveField=(activeField:string)=>{
    this.setState({activeField});
  }

  handleError=(touched:boolean,error:boolean)=>{
    return touched && error
  }

  handleViewEditSkyCard=(isAdd=this.state.isAddStateOpen,isView=this.state.isViewStateOpen,isEdit=this.state.isEditStateOpen)=>{
    if(!isView && !isEdit) this.setState({add25CardInitValue:formInitValue});
    this.setState({isAddStateOpen:isAdd,isViewStateOpen:isView,isEditStateOpen:isEdit});
  }

  handleDeleteModal=(isOpen:boolean)=>{
    this.setState({isDeleteModalOpen:isOpen});
  }

  getAircraftCategory=async()=>{
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     this.getAircarftCategroyApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAircraftApiEndPoint
    );

     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAircarftCatResponse=(responseJson : AircraftCategoryResType)=>{
    if(responseJson.categories){
      this.setState({aircraftCategory:responseJson.categories});
    }
  }

  getDebitSkyCard=async()=>{
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     this.getDebitSkyCardApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDebitSkyCardEndPoint
    );
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addEditDebitkyCardTopUp=async(formData:{current_top_ups: DebitFormInitValueType[]})=>{
    const handleData = this.handleDebitSkyCardData(formData);
    const headers = {
      'token' : await getStorageData('token'),
      'Content-type' : configJSON.exampleApiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addEditDebitCardTopApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addDebitTopUpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(handleData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

    handleDebitSkyCardData=(formData : {current_top_ups: DebitFormInitValueType[]})=>{
      const {current_top_ups} = formData;
      let top_ups = current_top_ups.map((each_top)=> this.manageEachTop(each_top))
      let dataToSend = {
        data : {
          debit_top_ups_attributes : top_ups
        }
      }
      return dataToSend
    }

    manageEachTop=(each_top_up:DebitFormInitValueType)=>{
      const {top_up,id} = each_top_up;
      const top_up_id = id ? {id} : {};
      return {amount : top_up,...top_up_id};
    }

    handleAddEditDebitTopUp=(responseJson:DebitSkyCardResType)=>{
      if(responseJson.data){
        const {data:{attributes:{current_top_ups}}} = responseJson;
        this.setState({debitSkyCardInitValue:{current_top_ups}});
        this.handleViewEditSkyCard(false,true,false);
        handleTriggerAlertMessage(this.send);
      }
    }

  get25HrSkyCards=async()=>{
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     this.get25HrSkyCardsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.get25HrSkyCardEndPoint
    );

     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handle25HrSkyCardResponse=(responseJson:Card25HrResType)=>{
      if(responseJson.data){
        this.setState({card25HrData:responseJson.data});
      }
  }

  handleDebitCardResponse=(responseJson : DebitSkyCardResType)=>{
    if(responseJson.data){
      const {data:{attributes : {current_top_ups}}} = responseJson;
      this.setState({debitSkyCardInitValue : {current_top_ups}})
    }
  }


  addEditDelete25SkyCard=async(formData:FormInitValueType)=>{
    const {isAddStateOpen,isDeleteModalOpen} = this.state;
    const {id} = formData;
    const handleData = this.handleFormData(formData);
    const endPoint =  configJSON.get25HrSkyCardEndPoint;
    const cardId =  isAddStateOpen ? '' : `/${id}`;
    const addEditMethod = isAddStateOpen ? configJSON.exampleAPiMethod : configJSON.updateCardMethod;
    const method = isDeleteModalOpen ? configJSON.deleteCardMethod : addEditMethod;
    const headers = {
      'token' : await getStorageData('token'),
      'Content-type' : configJSON.exampleApiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addEditDeleteSkyCardApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + cardId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(handleData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}


  handleFormData=(values:FormInitValueType)=>{
    const {cardName,category,price} = values;
    return {
      "data":{
          aircraft_category: category,
          price,
          name:cardName
      }
  }
  }

  handleAddEdit25HrResponse=(responseJson:AddEdit25HrResType)=>{
    const {isEditStateOpen} = this.state;
    if(responseJson.meta.message){
      this.get25HrSkyCards();
      {isEditStateOpen && this.handleViewCard(responseJson.data)}
      handleTriggerAlertMessage(this.send,responseJson.meta.message);
      this.handleViewEditSkyCard(false,isEditStateOpen ? true : false,false);
      this.setState({isDeleteModalOpen:false});
    }
  }

  handleViewCard=(value:Card25hrType)=>{
    const {attributes:{aircraft_category,name,price,id}} = value;
    this.setState({add25CardInitValue:{
      cardName:name,
      category:aircraft_category,
      price,
      id
    }});
  }

  // Customizable Area End
}
