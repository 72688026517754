Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "adminconsole2";
exports.labelBodyText = "adminconsole2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getClientDetailsAPIEndpoint = "bx_block_admin/clients"
exports.resetPasswordApiEndpoint = "bx_block_forgot_password/passwords/reset_password"
exports.httpPostMethod = "POST"
exports.getAllJobTItleEndPoint = "bx_block_admin/job_titles"
exports.updateJobTItleMehod = 'PUT'
exports.deleteJobTItleMehod = 'DELETE'
exports.head = 'Job Titles'
exports.popularSeachApi = 'bx_block_cfavinodeintegration2/airports/popular_airports';
exports.editPersonalInfoAPIEndpoint = "/bx_block_profile/profile/update_profile";
exports.patchMethod = "PATCH"
exports.airportsAPIEdnpoint = "bx_block_cfavinodeintegration2/airports?query"
exports.airportsContentType = "application/json"
exports.getAdminListApiEndPoint = "bx_block_admin/admins/admin_list";
exports.adminAccountHead = 'Admin Accounts'
exports.submitPassengerInfoAPIEndpoint="bx_block_shopping_cart/passenger_details/add_new_passenger"
exports.deletePassengerAPIEndpoint = "bx_block_shopping_cart/passenger_details/remove_passenger?passenger_id"
exports.deletePassengerMethod = "DELETE"
exports.updatePassengerMethod = "PUT"
exports.updatePassengerAPIEndpoint = "bx_block_shopping_cart/passenger_details/update_passenger"
exports.deactivateAccountAPIEndpoint = "bx_block_admin/admins/deactivate_user"
exports.updateEmailAPIEndpoint = "/bx_block_admin/clients/update_client_email?"
exports.createCardAPIEndpoint = "/bx_block_content_management/cards"
exports.makePrimaryPaymentMethodAPIEndpoint = "/bx_block_cfpaymentgateway33/payments/make_primary"
exports.deleteCardAPIEndpoint = "/bx_block_content_management/cards"
exports.updateCardDetailsAPIEndpoint = "bx_block_content_management/cards"
exports.httpPutMethod = "PUT"

// Customizable Area End