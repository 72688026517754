
import {getStorageData, removeStorageData} from '../../framework/src/Utilities';
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";

const handleNavigation=(screenEndPoint:string,navigationProps:any = {},send:(msg:Message)=>void, dataToSend:any= null)=>{
  const msg: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  msg.addData(getName(MessageEnum.NavigationPropsMessage), navigationProps);
  dataToSend && msg.addData(getName(MessageEnum.NavigationScreenNameMessage), dataToSend);
  msg.addData(getName(MessageEnum.NavigationTargetMessage), screenEndPoint);
  send(msg);
  }

  const handleTriggerAlertMessage=(sender:any,message = "Changes saved successfully")=>{
    const messageTriger = new Message(getName(MessageEnum.AlertMessage));
    messageTriger.addData(getName(MessageEnum.AlertBodyMessage), message);
    sender(messageTriger);
  }


  const clearToken = async()=>{
   await removeStorageData('token');
   await removeStorageData('name');
   await removeStorageData('photo');
  }

  const handleLogOut=async(outRoute = '/EmailAccountLoginBlock',navigationProps:any = {},send:(msg:Message)=>void, dataToSend:any= null)=>{
    const role = await getStorageData('role');
    const isAdminRoute = role === 'user' ? '' :  '?role=admin';
    clearToken();
    setTimeout(()=> handleNavigation(outRoute + isAdminRoute,navigationProps,send,dataToSend))
  }

  export {handleNavigation, clearToken, handleTriggerAlertMessage,handleLogOut}