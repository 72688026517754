// Customizable Area Start
import React from "react";
import { Radio, TextField, styled, Box, Checkbox, Button, Popper, Select, InputLabel, MenuItem, Modal, Badge, Typography, Menu, Accordion, Switch, Grid, TableRow, TableCell, Paper, InputAdornment, LinearProgress, Chip, useTheme, makeStyles } from "@material-ui/core";
import { ErrorOutline,KeyboardArrowDown, Flight, AccessTime, CheckOutlined, Close, Check} from "@material-ui/icons";
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {theme as colors} from "./utils";
import DateFnsUtils from '@date-io/moment';
import moment from 'moment';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import  Pagination  from "@material-ui/lab/Pagination";
import { TouchedMessage } from "../../blocks/cfcustomskycards/src/assets";
import { CvvModalIcon } from "./assets";
import { DeleteSvg } from "../../blocks/user-profile-basic/src/assets";

interface CSSPropertiesMap{
  [key: string]: React.CSSProperties;
}
const GreySkeleton = styled(Skeleton)({
  backgroundColor:'#9e9e9e'
})

// This textfield is for forms in authentication screens
const CustomFormTextField = styled(TextField)(({ isFocusedTypeInput = false,isErrorTouch = false,height,borderType = '1px solid', initialBorderColor = '#636E72',password=false,padding,client }:any) => ({
  '& .MuiInputBase-root': {
      color: "white",
      border: !isFocusedTypeInput ? "1px solid #CBD5E1" :password?"1px solid #FFD17A": "none",
      padding:padding?padding: "10px",
      paddingLeft:"10px",
      borderRadius: "0.5rem",
      height:height||'4rem',
      fontSize:'1.1rem',
      background:client?"rgba(0,0,0,1)":"inherit"
  },
  
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          borderColor: isErrorTouch ? '#F87171' : initialBorderColor,
           
      },
      '&:hover fieldset': {
          borderColor: isErrorTouch ? '#F87171' : initialBorderColor,
      },
      '&.Mui-focused fieldset': {
          borderColor:'#FFD27E', // Change border color to yellow when focused
          border: borderType
      },
  },
  '& .MuiInput-underline:after': {
      borderBottom: "none"  
  },
  '& .MuiInput-underline:before': {
      display: "none"  
  },
  '& .MuiInput-underline:hover:before': {
      display: "none"  // Removes the underline hover effect
  },
  '& .MuiInput-underline.Mui-disabled:before': {
      display: "none"  // Removes the underline for disabled state
  },
  '& input[type="number"]': {
    '-moz-appearance': 'textfield',
  },
}));

const CustomTextField = styled(TextField)(({ isFocusedTypeInput = false,isErrorTouch = false,height,showError=false,actionType="show",section="admin",editInfo=false,padding=false}:any) => ({
  '& .MuiInputBase-root': {
      color: "white",
      border:actionType==="show"?"none": showError?"1px solid #F87171":!isFocusedTypeInput? "1px solid #CBD5E1" : "1px solid #FFD17A ",
      padding: padding?"0px":"10px",
      paddingLeft:"10px",
      borderRadius: "0.5rem",
      minHeight:height||'4rem',
      fontSize:'1.1rem',
      background:actionType!=="show" && section === "client"?"#2D3034":editInfo?"inherit":"#3E4247",
      
  },
  '& .MuiOutlinedInput-root': {
      '& fieldset': {
          borderColor: isErrorTouch ? '#F87171' : '#636E72',
          // borderBottom: 'none',  
      },
      '&:hover fieldset': {
          borderColor: isErrorTouch ? '#F87171' : '#636E72',
      },
      '&.Mui-focused fieldset': {
          borderColor: '#FFD27E', // Change border color to yellow when focused
      },
  },
  '& .MuiInput-underline:after': {
      borderBottom: "none"  
  },
  '& .MuiInput-underline:before': {
      display: "none"  
  },
  '& .MuiInput-underline:hover:before': {
      display: "none"  // Removes the underline hover effect
  },
  '& .MuiInput-underline.Mui-disabled:before': {
      display: "none"  // Removes the underline for disabled state
  },
  '& input[type="number"]': {
    '-moz-appearance': 'textfield',
  },
}));

const StatusGeneralIconContainer =()=>{
  return <Box className={"modal_check_icon_cont"} component='span' style={{padding:'1.70rem 1.5rem',marginTop:"-0.5rem"}}>
  <CheckOutlined className={"modal_check"} />
</Box>
}

const StatusGeneralParentContainer=({children,styles}:{children:React.ReactNode,styles:React.CSSProperties})=>{
  return <Box  className={"modal_check_icon_cont"}  component='span' style={{padding:"1rem",...styles}}>
    {children}
  </Box>
}

const ErrorIconContainer =()=>{
  return <Box className={"modal_check_icon_cont"} component='span' style={{padding:"1.2rem",marginTop:'-2.7rem'}}>
  <ErrorOutline style={{fontSize:'4rem',color:'black'}}/>
</Box>
}

const CvvIconContainer=()=>{
  return <Box className={"modal_check_icon_cont"} component='span' style={{padding:"1.2rem",marginTop:'-0.7rem'}}>
  <CvvModalIcon/>
</Box>
}

const SurityModal=({isOpen,children}:{isOpen:boolean,children:React.ReactNode})=>{
  return <MuiModal open={isOpen}>
      <StatusModal className="status_update_modal">
      <MuiResuableStatusModalBody marginTop={'0rem'} heading={'Are you sure?'} subHeading = {""} iconCont={ErrorIconContainer}>
        {children}
      </MuiResuableStatusModalBody>
      </StatusModal>
  </MuiModal>
}

const SureModalButtonContainer=({cancel,submit}:{cancel:()=>void,submit:()=>void})=>{
  return <Box style={{display:'flex',justifyContent:"space-between",columnGap:'1.9rem',width:'23rem'}}>
         <AddLegtTypeButton className="cancel_confirm" onClick={()=>cancel()}  fullWidth>Cancel</AddLegtTypeButton>
         <FormSubmitButton className="delete_confirm" fullWidth onClick={()=>submit()}>Yes</FormSubmitButton>
  </Box>
}

const MuiResuableStatusModalBody = ({ children, heading, subHeading,iconCont = StatusGeneralIconContainer,marginTop='1.5rem' }: any) => {
  return (
     <>
        {iconCont()}
        <ModalHeading marginTop={marginTop} textAlign='center'>{heading}</ModalHeading>
        <Box className='profile_gen_modal_margin' textAlign='center'>
           {subHeading}
        </Box>
        {children}
     </>
  )
}

const PaymentStatusIcon=({isSuccessModal}:{isSuccessModal:boolean})=>{
 const backgroundCol = isSuccessModal ?  "#34D399" : '#F87171'
 const iconStyle = {padding:'0.5rem',fontSize:'2.5rem',color:'black',borderRadius:'50%'}
 return <>
  {!isSuccessModal ? <Close style={{...iconStyle,backgroundColor:backgroundCol}}/> : <Check style={{...iconStyle,backgroundColor:backgroundCol}}/>}
 </>
}

const PaymentStatusParent=({children,isOpen}:{children:React.ReactNode,isOpen:boolean})=>{
  return <MuiModal open={isOpen}>
     <StatusModal>
        <Box className = 'booking_section' style={{width:'35rem',alignItems:'center'}}>
          {children}
        </Box>
        </StatusModal>
      </MuiModal>
}

const CustomCheckbox = styled(Checkbox)({
  color: '#64748B', 
  '&.Mui-checked': {
    color: 'yellow', 
  },
  '&.MuiCheckbox-root': {
    
    borderRadius: '2px', 
    backgroundColor: 'transparent',
    'input:hover ~ &': {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206, 217, 224, 0.5)',
    },
  },
  '&.MuiCheckbox-colorSecondary.Mui-checked': {
    color: '#FFB833',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 0, 0.08)', 
    },
    'input:disabled ~ &': {
      backgroundColor: 'transparent',
    },
  },
});


const modalStyle:any = {
  backgroundColor: '#282B2E',
  maxHeight: '80vh',
  width: '500px',
  padding: '40px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  borderRadius: '10px',
  color:"white"
}

// const StyledModalContent = styled(Box)({...modalStyle});

const StatusModal = styled(Box)({
  ...modalStyle,
  overflow: 'visible',
  // width: '400px',
  width : 'auto !important'
});


const RequirementKindTextField = styled(CustomFormTextField)(({ heightOverride = '4rem',fontSize = '1.2rem' }:any) => ({
  '& .MuiInputBase-root': {
    height: heightOverride, 
    fontSize
  },
}));


  const ContainerSameAsTextField = styled(Box)(({passenger=false}:any)=>({
    border:passenger?"none": "1px solid #CBD5E1",
    borderRadius: "0.5rem",
    height:'3.7rem',
    width:'100%'
  }))


const SearchRequestTexfield = styled(CustomFormTextField)({
  '& .MuiInputBase-root': { 
      border: "1px solid #3E4247",
      height:'auto',
      fontSize:'1rem'
  }
});

const PaymentTexfields = styled(CustomFormTextField)(({isErrorTouch}:any)=>({
  '& .MuiInputBase-root': { 
      height:'3rem',
      fontSize:'1rem',
      border:isErrorTouch ? "none":""
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
        borderColor:isErrorTouch ? '#F87171':'#CBD5E1',
    },
    '&:hover fieldset': {
        borderColor:isErrorTouch ? '#F87171': '#CBD5E1',
    },
    '&.Mui-focused fieldset': {
        borderColor:isErrorTouch ? '#F87171' : '#FFD27E', 
    },
},
}));

const FilterCustomFormTextField = styled(CustomFormTextField)({
    '& .MuiInputBase-root': {
        color: "white",
        border: "none",
        padding: "10px",
        borderRadius: "0rem 0.5rem 0.5rem 0rem",
        height:'3.2rem',
        fontSize:'1.1rem',
        backgroundColor:'black',
        
    },
    '& .MuiInputBase-input::placeholder':{
        color : '#64748B',
        fontSize: '1.3rem'
    },
    
});

const StyledOnlineBadge = styled(Badge)({
    '& .MuiBadge-badge': {
      backgroundColor: '#20E70E',
      color: '#20E70E',  // This ensures the badge doesn't display a number.
      boxShadow: `0 0 0 2px #272828`,
      borderRadius: '50%',
      width: '15px',  // Adjust as needed
      height: '15px',  // Adjust as needed
    //   right: '-8px',  // Half the width to position to the right
    //   bottom: '-8px',  // Half the height to position at the bottom
    },
  });


  const CustomLabel = styled(Box)(({ isFocused = false,isError=false }:any) => ({
    color: isFocused ?  '#FFD27E' :  isError ? '#F87171'  : '#6C7278',
    fontSize: '1.2rem',
    margin: '10px 0px',
    fontWeight: 'bold'
}));

const UserDetailsLabel = styled(Box)(({ isFocused = false }:any) => ({
  color: isFocused ? '#FFD27E' : '#6C7278',
  fontSize: '1rem',
  margin: '10px 0px',
  fontWeight: 'bold'
}));

const PaymentLabel = styled(CustomLabel)({
  fontSize: '1rem',
  margin:'0',
  marginBottom: "5px",
});

const ClientLabel = styled(Box)(({isFocused=false,isError=false}:any)=>({
  fontSize:"1rem",
  fontWeight:600,
  marginBottom :"5px",
  color: isFocused ?  '#FFD27E' :  isError ? '#F87171'  : '#94a3b8',
}))

const ClientValue = styled(Box)({
  fontSize:"1.3rem",
  fontWeight:400,
  color:"white",

})

const TopupButton = styled(Button)(({selected= false}:any)=>({
  background:selected?"var(--Button, linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%))":"inherit",
  borderRadius:"1.5rem",
  fontWeight:400,
  fontSize:"1rem",
  color:selected?"black":"white",
  padding:"0.5rem 1.5rem",
  textTransform:"none",
  cursor:"pointer",
  '&:hover': {
    backgroundColor: '#1a1c1e', 
  },
}))

const TopupAmountBox = styled(Box)({
  display:"flex",
  justifyContent:"center",
  flexWrap:"wrap",
  gap:"1rem 4rem",
  padding:"1rem 4rem"
})

const PassengerLabel = styled(Box)(({ isFocused = false,isError=false,card=false }:any) => ({
  color: isFocused ?  '#FFD27E' :  isError ? '#F87171'  : '#94A3B8',
  fontSize:card?"1.1rem": '1rem',
  margin: '0 0 5px 0',
  fontWeight: 'bold'
}));



const UserProfileLabel = styled(Box)({
    color: 'var(--neutrals-cool-gray-400, #94A3B8)',
    fontFamily: 'Manrope',
    fontSize: '1.2rem',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '1.375rem',
  });

const HorizantalRow = styled(Box)({
    height: '0.0625rem',
    background: 'var(--card-2, #636E72)',
  });


const CustomFormCheckbox = styled(Checkbox)({
    // Default state
    color: '#64748B', // Default border color when not checked

    // Checked state
    '&.Mui-checked': {
        color: '#FFB833', // Border color when checked
        '&:hover': {
            backgroundColor: 'transparent', // Remove hover effect
        },
    },

    // Remove hover effect when checked
    '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
        backgroundColor: 'transparent',
    },

    // Tick color
    '& .MuiSvgIcon-root': {
        // fill: '#FFB833',
    },
});

const PhoneNumberFieldBox = styled(Box)(({actionType="show",showError=false,isFocusedTypeInput="false"}:any)=>({
  "& .react-tel-input":{
    backgroundColor:actionType!=="show"?"#2d3034":"#3E4247",
    border:actionType==="show"?"1px solid #3e4247 !important":showError?"1px solid #F87171":isFocusedTypeInput?"1px solid #FFD17A":"1px solid #CBD5E1"
  },
  "& .phone_number_inputField":{
    backgroundColor:actionType!=="show"?"transparent !important":"#3E4247",
    boxShadow: "none !important",
    border:"none !important",
    color:"#fff !important",
    padding:"19px 14px 19px 70px !important" ,
    width:"100%"  
  }

}))

const ForgotPasswordTypeButton = styled(Button)({
    background: 'var(--primary-purple-brand-gradient, linear-gradient(99deg, #FFE3AE 2.64%, #FFD07A 100%))',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: "1.34rem", // Adjusted font size
    transform: "scale(1.02)", // Added scale transform
    textTransform: "capitalize",
    fontWeight: 900,
    color: 'transparent',
    padding: "0px"
});

const FormSubmitButton = styled(Button)({
    display: 'flex',
    height: '3.5rem',
    padding: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    borderRadius: '0.5rem',
    background: 'var(--Button, linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%))',
    textTransform:"capitalize",
    fontWeight:"bolder",
    fontSize:"1.15rem"
  });

  const AddLegtTypeButton = styled(FormSubmitButton)({
    border: '1px solid #FFD27E',
    color: '#FFD27E',
    background: 'inherit',
     '&.Mui-disabled': {
        color: '#9f917b', // This sets the text color to orange when the button is disabled
        border: '1px solid rgba(255, 210, 126, 0.5)', // Optional: if you want to change the border color when disabled
        background: 'rgba(0, 0, 0, 0.12)', // Optional: if you want to change the background when disabled
    },
});



const CustomTypography = styled(Typography)({
  fontFamily: 'Manrope',
  fontWeight:600,
  fontSize:"1.7rem",
  color:"#FFD27E"
})

const CustomSubHeadingText = styled(Typography)({
  fontFamily: 'Manrope',
  fontWeight:700,
  fontSize:"24px",
  color:"#FFFFFF",
  marginBottom:"20px"
})

const CustomBox = styled(Box)({
backgroundColor:"#2D3034",
borderRadius:"16px",
padding:"16px 24px 16px 24px",
marginTop:"20px",
marginBottom:'7rem'
})

const CustomErrorMessage = styled(Box)({
    padding:"15px 12px",
    color:"#DC2626",
    backgroundColor:"#FEE2E2",
    borderLeft:"5px solid #DC2626",
    borderRadius:"5px",
  });


const CustomDepartueAiportAutoCompplete = styled(Autocomplete)(({ bgColor = 'inherit',isFocusedTypeInput=false, height="4rem",showError=false,fontSize="1.1rem",actionType="show",section="admin",paddingRight }:any) => (
  {
    '& .MuiInputBase-root': {
        color: "white",
        border:actionType==="show" && section === "client"?'none':showError?"1px solid #F87171":isFocusedTypeInput?"1px solid #FFD17A":"1px solid #CBD5E1",
        padding: "10px",
        borderRadius: "0.5rem",
        zIndex: 1,
        fontSize:fontSize,
        backgroundColor:actionType == "show" && section === "client" ? "#3E4247":bgColor,  // Use the bgColor prop here
        position: "relative",
        minHeight: height,
    },
    '& .MuiAutocomplete-inputRoot' :{
        paddingRight:paddingRight?"0px !important": '10px !important'
    },
    '& .MuiAutocomplete-root': {
        zIndex: 1,
        backgroundColor: bgColor  // And here

    },
    '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#636E72',
                },
                '&:hover fieldset': {
                    borderColor: '#636E72',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#636E72',
                },
            },
            '& .MuiInput-underline:after': {
                borderBottom: "none"
            },
            '& .MuiSvgIcon-root':{
                fontSize:'1.5rem'
            },
            '& .MuiAutocomplete-clearIndicator': {
                color: '#6C7278',
                marginRight:"0.4rem"
            },
}));

const CustomFormSelect = styled(Select)(({height="4rem",fontSize="1.1rem",isFocusedTypeInput=false,filterSelect=false}:any)=>(
  {
    color:filterSelect?"#FFD17A": 'white',
    height:height,
    fontSize:fontSize,
    borderRadius: "0.5rem",
    border:isFocusedTypeInput? "1px solid #FFD17A": "",
    '&:focus': {
        outline: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        // borderColor: '#FFD17A',
        borderColor:filterSelect?"#FFD17A": "#CBD5E1"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor:filterSelect?"#FFD17A": '#CBD5E1',
        outline:'none !important'
    },
    '& .MuiSelect-outlined':{
        outline:'none'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: filterSelect?"#FFD17A": '#CBD5E1',
    },
    '&.Mui-focused.MuiOutlinedInput-notchedOutline': {
        borderColor: filterSelect?"#FFD17A": '#CBD5E1',
    },
    '& .MuiSelect-icon': {
        color:filterSelect?"#FFD17A":"white" ,
    },
    '& .MuiInput-underline:after': {
        borderBottom: "none"  // Removes the bottom border when the input is focused
    },
}));

const CustomFormSelectNew = styled(CustomFormSelect)(({ isError=false }:{isError?:boolean}) => ({
  borderColor: '#636E72',
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#636E72', 
    borderWidth:'1px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: isError ? '#FF7373' : '#FFD27E',
    outline: 'none !important',
    borderWidth:'1px',
  },
  '& .MuiSelect-icon': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottom: "none" 
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: isError ? '#FF7373' : '#636E72',
    borderWidth:'1px',
  },
}));

const CustomInputLabelForSelect = styled(InputLabel)({
    color: 'white',
    marginTop:'-5px',
    marginLeft:'15px',
    '&.Mui-focused': {
    },
    '&.MuiInputLabel-shrink': {
        display: 'none',
        transform: "scale(0.75) translateY(-24px)"
    }
});

const MenuItemForSelect = styled(MenuItem)(({ bgColor = 'inherit' }:any) => ({
      backgroundColor:bgColor,
      "&.MuiListItem-button:hover":{
        backgroundColor:"black"
      },
      "&.MuiMenuItem-root.Mui-selected":{
        backgroundColor:"black",
        color:"#fff"
      }
}));


//  Modal Components Start

const CustomStyledPopper = styled(Popper)(({ zIndex=0,highIndex, marginTop = '-10px', bgColor = '#3E4247',border }:any) => ({
    border:border?"none": '1px solid #CBD5E1',
    borderTop: 'none',
    top: `${marginTop} !important`,
    borderRadius: '5px',
    marginLeft:"1px",
    overflow: 'hidden',
    zIndex: highIndex|zIndex,
    borderTopRightRadius:"2px",
    borderTopLeftRadius:"2px",
    '& > div': {
        margin: "0",
        backgroundColor: bgColor,
        color: 'white',
    },
    '& > div > div': {
        margin: "0",
        padding: "1rem",
        backgroundColor: bgColor,
        color: 'white',
    }
}));

const ModalHeading = styled(Box)({
    fontSize: '2rem',
    color: 'white',
    fontWeight: 'bold',
  });

const SearchStyledPopper = styled(CustomStyledPopper)(({dashboard=false,zIndex=1345,paddingTop='0',marginTop='10px'}:any)=>({
  marginTop :marginTop,
  border:'none',
  borderRadius :dashboard?"0px": '5px',
  zIndex:zIndex,
  "& .MuiPaper-rounded":{
    borderRadius:"0px"
  },
  '& > div':{
    paddingTop:paddingTop
  },
  '&[x-placement*="top"]': {
    top: '0 !important',
  },
  '& ul': {
    padding :"5px",
    overflowY: 'auto',
    "& li":{
      "&:hover": {
        background:dashboard?"":"#1A1C1E",
      },
      
      "& .MuiAutocomplete-groupLabel":{
        background:"#3E4247",        
        boxSizing:"border-box",
        color:"#94A3B8",
        paddingTop:"0.4rem",
        paddingBottom:0,
        fontSize:"1rem",
      
        '&::after': {
          content: '""',
          position: 'absolute', // Set position to absolute
          left: "1rem",
          right:"1rem",
          bottom: 0,
          width: "90%",
          height: '1px',
          background:"#94A3B8", // Border color
        },
      },
      "& .MuiAutocomplete-groupUl .MuiAutocomplete-option":{
        paddingLeft:"1rem",        
      },
      "& .MuiAutocomplete-option[data-focus=true]":{
        background:"#1A1C1E",
        borderRadius:"4px",
      }            
    },
    '&::-webkit-scrollbar': {
        width: '10px',
    },
    '&::-webkit-scrollbar-track': {
        background: '#2e2e2e',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '5px',
        '&:hover': {
            background: '#555',
        },
    }
}
}))


const StyledModalContent = styled(Box)(({section="admin"}:any)=>({
  backgroundColor:section==="client"?"#2D3034": '#282B2E',
  maxHeight: '80vh',
  width: '500px',
  padding: '40px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  borderRadius: '10px',
  color:"white"
}));



const MuiModal = ({ children, open, onClose }:any) =>{
    return <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    {children}
  </Modal>
}

//  Modal Components Ended






// ===================================================Advance Form Components=====================================

const ButtonTogglerFormContainer = styled('div')({
    display: 'flex'
  });
  
  const CustomMuiButtonPassenger = styled(Button)({
    padding: 0,
    minWidth: 0
  });
  
  
  
  const CustomFormTabButton = styled('button')(({ selected }:{selected:boolean}):any => ({
    padding: '10px 20px',
    width : "32%",
    height : "4.5rem",
    margin: '0 10px',
    borderRadius: '10px',
    fontWeight : '600',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    transition: '0.3s',
    fontSize : '1.3rem',
    background: selected 
      ? 'linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%)' 
      : 'var(--Grey-Button, #3E4247)',
    color: selected ? 'black' : 'white'
  }));
  
  const CustomFormTabPanel = styled('div')({
    borderRadius: '10px',
  });
  
  
  
  const SearchStyledAutocomplete = styled(Autocomplete)(({ isErrorBorder = false,navbar=false, borderColor = '#3E4247' }:any) =>({
    width: '100%',
    borderRadius: '0.5625rem',
    border: !isErrorBorder ? `1px solid ${borderColor}` :navbar?"none": '1px solid #F87171',
    // paddingRight:"0 15px 0 25px !important",
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '0 0 0 25px',
      fontSize: '16px',
      paddingLeft:navbar?"1rem":"25px",
      height: navbar?"3.3rem": '5rem', 
      paddingRight : "15px !important",
      color:'inherit',
    },
  
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 10,
    },
  
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none !important',
        top: 0, 
      },
      '&:hover fieldset': {
        borderColor: !isErrorBorder ? '1px solid #3E4247' : '1px solid red',
      },
      
      
    },
  
    '& .MuiInputLabel-root': {
      color: colors.GRAY_COLOR_FORM,
      left: 40,
      fontSize : '1rem',
      top:5,
      zIndex:0
    },
  
    '& .MuiInputLabel-root.Mui-focused': {
      color: colors.GRAY_COLOR_FORM,
    },
  
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)', // Adjust the vertical position of the label
    },
  }));
  
  
  const SearchStyledTextField = styled(TextField)(({ isValueSelected = false }:any) =>({
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
    '& .MuiOutlinedInput-input': {
      color: '#fff',
      marginTop: isValueSelected?.code ? '-25px' : '',
      fontSize:'1rem'
    },
    '& .MuiInputLabel-root': {
      color: colors.GRAY_COLOR_FORM,
      marginTop:'1.5rem',
      fontSize:'1rem',
      width:'75%',
      whiteSpace: 'nowrap', 
      overflow: 'hidden', 
      textOverflow: 'ellipsis', 
    },
  }));
  
  const CustomDateFormTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      marginTop:'0px',
        color: "white",
        border: '1px solid #3E4247',
        padding: "10px",
        borderRadius: "0.5rem",
        height:'5rem',
        fontSize:'1rem',
    },
    '& .MuiInputLabel-root': {
      color: colors.GRAY_COLOR_FORM,
      left: 45,
      fontSize : '1.3rem',
      top:20
  },
  '& .MuiInputBase-input':{
    marginLeft:"5px"
  },
  '& .MuiInputLabel-root.Mui-focused': {
      color: colors.GRAY_COLOR_FORM,
  },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#636E72',
            borderBottom: 'none',  
        },
    },
    '& .MuiInput-underline:after': {
        borderBottom: "none"  
    },
  });
  
  const PassengerBox = styled(Box)({
    border: '1px solid #3E4247',
    height: '5rem',
    borderRadius: '0.5625rem',
    width: '100%',
    display:'flex',
    alignItems:'center',
    fontSize : '1rem',
    columnGap:'10px'
  });
  
  const CustomFormAircraftSelect = styled(Select)({
    border: '1px solid #3E4247',
    background: '#1A1C1E',
    color: 'white',
    height: '5rem',
    borderRadius: '0.5rem',
    '&:focus': {
      outline: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'none',
      border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3E4247',
      outline:'none !important'
  },
  '& .MuiSelect-outlined':{
      outline:'none'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3E4247',
  },
  '&.Mui-focused.MuiOutlinedInput-notchedOutline': {
      borderColor: '#3E4247',
  },
  '& .MuiSelect-icon': {
      color: 'white',
  },
  '& .MuiInput-underline:after': {
      borderBottom: "none"  // Removes the bottom border when the input is focused
  },
  '&.MuiInputBase-root.Mui-disabled': {
    color: 'inherit !important',
    border: '1px solid #3E4247',
  },
  });


const Footer = ({children} : any)  =>{
  return <Box className="footer">
      {children}
  </Box>
}
// ===================================================Advance Form Components=====================================

// Avinode Screen Components ////////////////////////////////////////////////////////
const GoldishSimpleButton = styled(Button)({
  color: colors.GOLD_COLOR,
  fontSize: '1.4rem',
  fontWeight: 600,
  padding : 0
});

const LoadingImage = () => <svg xmlns="http://www.w3.org/2000/svg" width="152" height="152" viewBox="0 0 160 160" fill="none">
<path d="M154.699 23.4713C155.065 23.9838 155.219 24.2834 155.447 24.5082C156.855 25.8956 157.134 27.4643 156.192 29.2094C154.949 31.5154 150.118 48.638 149.465 50.9779C149.352 51.383 149.705 51.9995 149.991 52.4175C151.5 54.6254 153.123 56.7588 154.562 59.0094C155.127 59.8938 155.471 60.9943 155.634 62.0397C155.833 63.3332 154.917 64.0092 153.814 63.3252C152.593 62.5655 151.459 61.5617 150.539 60.4548C149.147 58.7799 147.903 56.9679 146.733 55.1269C146.062 54.0696 145.27 53.7137 144.047 53.7373C142.096 53.7759 125.243 52.5114 122.542 52.9567C119.937 53.3866 118.899 51.6606 117.858 49.6176C124.927 48.271 131.886 46.7442 138.546 43.5761C136.721 40.6916 134.787 38.0949 133.355 35.2448C131.955 32.4604 130.014 31.3814 127.014 31.9157C126.59 31.9914 126.125 31.8468 125.705 31.9356C123.512 32.4015 122.369 31.2197 121.519 29.2386C122.991 28.7095 124.443 28.1272 125.934 27.6733C127.13 27.3083 128.233 26.9533 128.275 25.4378C129.699 25.8702 130.42 25.0083 131.15 23.9885C132.031 22.759 133.03 21.6151 133.978 20.4337C136.018 22.1677 136.112 22.4918 135.341 24.8133C135.289 24.9685 135.244 25.1261 135.196 25.2832C134.663 26.9973 133.486 28.8333 133.779 30.389C134.075 31.9534 135.836 33.2377 136.953 34.6453C138.609 36.7348 140.264 38.8263 142.058 41.0905C147.192 35.872 150.913 29.8061 154.699 23.4713Z" fill="#FFD485"/>
<path d="M158.5 80C158.5 121.974 124.026 156 81.5 156C38.9741 156 4.5 121.974 4.5 80C4.5 38.0264 38.9741 4 81.5 4C95.5251 4 108.674 7.70098 120 14.1674" stroke="url(#paint0_angular_9235_42803)" stroke-width="8" stroke-linecap="round" stroke-linejoin="bevel"/>
<defs>
<radialGradient id="paint0_angular_9235_42803" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(82.5 80) rotate(-21.5713) scale(92.477 93.5112)">
<stop stop-color="#FFB833"/>
<stop offset="0.250766" stop-color="#FFC14C"/>
<stop offset="0.479167" stop-color="#FFCE74" stop-opacity="0.7"/>
<stop offset="0.978342" stop-color="#FFD382" stop-opacity="0"/>
<stop offset="1" stop-color="#FFDB99" stop-opacity="0"/>
</radialGradient>
</defs>
</svg>


const Loader = ({ children, height }: { children: React.ReactNode, height?: string }) => {
  return (
      <Box className='cf_avinod_loader_screen' style={{ height: height }}>
          <Box className="cf_avinod_no_result_container">
              <Box className='rotate-clockwise'><LoadingImage/></Box>
              {children}
          </Box>
      </Box>
  );
}











// Avinode Screen Components End ///////////////////////////////////////////////////////

const StyledEditDeleteMenu = styled(Menu)({
  '& .MuiPaper-root': {
    background: 'var(--gray-BG, #1A1C1E)',
    borderRadius: '10px',
    color:'white',
  },
});

const SidebarAccordion = styled(Accordion)({
  backgroundColor: 'inherit !important',
  color: 'white !important',
  boxShadow: 'none !important',
  margin : '0 !important',
});


const GenralUsedAccordion = styled(SidebarAccordion)({
  borderRadius:'10px !important',
  backgroundColor: '#3E4247 !important',
  overflow : 'hidden'
});


const MyAccordionSummary = styled((props: any) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDown style={{ color: "white" }} />}
    {...props}
  />
))(({ borderRadius = '0.5rem' }:any) => ({
  height: '30px',
  borderRadius:borderRadius, // use the borderRadius from props or default value
  backgroundColor: '#1A1C1E',
  '& .MuiAccordionSummary-content': {
    alignItems: "center"
  },
  '&.Mui-expanded': {
    minHeight: '48px',
    margin: '0px',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0px',
  },
  paddingRight: "10px",
}));

const OutlineButton = styled(Button)({
  color : '#FFE3AE',
  border: '1px solid var(--Linear-gradient-tab, #FFE3AE)',
  height: '3.5rem',
  fontWeight:"bolder",
  fontSize:"1rem",
  textTransform:'none',
  borderRadius:'0.5rem'
})

const FilterTypeButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(45, 48, 52, 0.40)',
  color: '#FFE3AE',
  border: `1px solid #FFE3AE`,
  borderRadius: '10px',
  padding:'0.9rem 0.75rem',
  minWidth:'0px'
}));

const SearchTextFieldActiveBooking = styled(TextField)({
  backgroundColor: 'rgba(45, 48, 52, 0.40)',
  borderRadius: '10px',
  padding:'1rem 1.5rem',
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none', // Remove the default underline
  },
  '& .MuiInput-underline:hover:before': {
    borderBottom: 'none', // Remove the underline on hover
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none', // Remove the underline after interaction
  },
});


const CustomRadio = styled(Radio)({
  '&.MuiRadio-root': {
    color: 'white', 
  },
  '&.MuiRadio-root.Mui-checked': {
    color: '#FFD07A', 
  },
});

const CustomDashboardButton = styled(Button)({
  backgroundImage: `linear-gradient(#FFD078, #FFE3AF)`,
  color: "black",
  backgroundColor: "#FFD078",
  '&:hover': {
    backgroundColor: '#FFD078',
  },
  textTransform: "none",
  height: "56px",
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  borderRadius:"0.5rem",
  width:'18rem',
});

const CustomSwitch = styled(Switch)({
  width: 56,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 0,
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#FFD993', 
        borderWidth: '1px',
        borderStyle: 'solid',
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#332100',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#ACB5BB', 
    marginTop:"0.06rem",
    width: 16,
    height: 16,
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: '#2D3034', // Custom color when the switch is unchecked
    borderColor: '#ACB5BB', // Green border color when unchecked
    borderWidth: '1px',
    borderStyle: 'solid',
    opacity: 1,
    transition: 'background-color 500ms',
  },
});

const handleMinutesAsHoursAndMinutes=(minutes:number)=>{
  const duration = moment.duration(minutes, 'minutes');
  const hours = duration.hours();
  const minutesLeft = duration.minutes();
  const hoursString = 'h';
  const minutesString = 'm';

  let result = '';

  if (hours > 0) {
    result += `${hours}${hoursString}`;
  }

  if (minutesLeft > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += `${minutesLeft}${minutesString}`;
  }

  return result;
}

const renderOptionFrom = (option: any, isSelected: boolean) => {
  const formattedText = `${option?.code} - ${option?.name}, ${option?.city}, ${option?.countryCode}`;

 return (
   <>
     <StyledOptionsBox className={`advance_search_option ${isSelected && 'advance_search_selected_option'}`}>
       <Box>
         <Flight style={{ color: `${!isSelected ? '#34D399' : 'black'}`, fontSize: "1.2rem" }} />
       </Box>
       <Box>{formattedText}</Box>
     </StyledOptionsBox>
   </>
 );
};

const renderGlobalSearchOptions = (option:any,isSelected:boolean,navbar=false,filterType:string|null="booking_no")=>{
  const formattedText = navbar?`${option.id}`:`${  option.booking_no ? filterType==="booking_no"? option.booking_no:option.invoice_number : option.id} - ${option?.client_name}` 

  return(
    
     <StyledOptionsBox 
     className={`advance_search_option ${isSelected && 'advance_search_selected_option'}`}
     >      
       <Box>{formattedText}</Box>       
     </StyledOptionsBox>
  )
  
}

const clientSearchOptions = (options:any)=>{
  console.log("option",options.full_name, options.account_id)
  return(
    <StyledOptionsBox 
     className={`advance_search_option`}
     >      
       <Box>{`${options.account_id} - ${options.full_name}`}</Box>       
     </StyledOptionsBox>
  )

}

const getOptionsTitle = (option:any)=>{
  if(option.full_name){
    return `${option.booking_no} - ${option.client_id} - ${option.full_name}`
  }
  else{
    return option.booking_no
  }
}

const renderDashboardSearchOptions = (option:any,isSelected:boolean)=>{
  const formattedText =  getOptionsTitle(option) 

  return(
    
     <StyledOptionsBox 
     className={`advance_search_option ${isSelected && 'advance_search_selected_option'}`}
     >      
       <Box>{formattedText}</Box>       
     </StyledOptionsBox>
  )
  
}

const StyledGrid = styled(Grid)(({ popperWidth }:any) => ({
  '& .react-datepicker__tab-loop': {
    '& .react-datepicker-popper': {
      width: popperWidth
    },
  },
  "& .react-datepicker__year-dropdown":{
    backgroundColor:"#3e4247",
  },
  "& .react-datepicker__year-read-view":{
    visibility:"hidden",
    color:"#3e4247"
  }
  
}));

const StyledOptionsBox = styled(Box)({
  "& .advance_search_option":{
    display: "flex",
  alignItems: "center",
  columnGap: "5px",
  },
  

})

const CustomStyledTimePicker = styled(KeyboardTimePicker)({
  // Customize styles for the input 
  '& .MuiInputBase-root': {
    color: 'white', 
    border: 'none', 
    background:'#4B4E55',
    padding:'10px',
    margin:'-10px',
    marginRight:'5px',
    '& .MuiInputBase-input':{
      // border:'1px solid white',
      width:'5rem !important',
      padding:'0px'
    },
    '& .MuiButtonBase-root':{
      padding:'0px'
    }
  },

  '&. Mui-focused':{
    border:'none',
  },
  // Customize styles for the label if needed
  '& .MuiFormLabel-root': {
    display: 'none', // Hide label
  },
  '& .MuiInput-underline:after':{
    border:'none'
  },
  '& .MuiInput-underline:before':{
    borderBottom:'none'
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before':{
    border:'none'
  },


  '& .MuiPickersToolbar-toolbar': {
    backgroundColor: 'red',
  },
  // To style the pin of the clock
  '& .MuiPickersClock-pin': {
    backgroundColor: 'red',
    '&:after': {
      backgroundColor: 'red',
    },
  },
  // To style the selected time text
  '& .MuiPickersClockNumber-clockNumberSelected': {
    backgroundColor: 'red',
    color: 'white',
  },

});


const TimePickerInputCustom = ({ setFunction,isPickerOpen, openTimePicker,secondValue}:any) => {
  const currentTime = secondValue() ?  new Date(secondValue()) : null;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CustomStyledTimePicker
        open={isPickerOpen}
        margin="normal"
        id="time-picker"
        inputProps={{
          style: { pointerEvents: 'none' }
        }}
       DialogProps={{
        className : 'custom_time_picker_modal'
       }}
        onClose={()=>openTimePicker(false)}
        value={currentTime}
        keyboardIcon={<AccessTime style={{color:'white',padding:'0px'}} onClick={()=>openTimePicker(true)}/>}
        onChange={(date:any) => { setFunction(date.toDate()); openTimePicker(false)}}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

const formatTime = (date:Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

const parseTime = (timeStr:string) => {
  const [time, period] = timeStr.split(' ');
  const [hours, minutes] = time.split(':').map(Number);
  return { hours, minutes, period };
};

const TimePickerNew=({setFunction,dateValue}:any)=>{
  const isTimePickerDisable = dateValue === null
  const date = dateValue ? dateValue : new Date();
  const period = date.getHours() >= 12 ? 'PM' : 'AM';
  const [time, setTime] = React.useState(formatTime(date));
  const handleTimeChange = (event:any) => {
    let value = event.target.value.replace(/[^0-9:]/g, '');
    if (value.length > 5) value = value.slice(0, 5);
    if (!value.includes(':') && value.length > 2 && value[2] !== ':') value = value.slice(0, 2) + ':' + value.slice(2);
    setTime(value);
    if (value.length === 5) {
      const { hours, minutes } = parseTime(value);
      if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
        const newDate = new Date(dateValue || new Date());
        newDate.setHours(hours);
        newDate.setMinutes(minutes);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        setFunction(newDate);
      }
    }
  };

  const handleBlur = () => {
    if (time.length === 0) {
      setTime('00:00');
      setFunction(new Date(dateValue.getTime()));
    } else {
      const { hours, minutes } = parseTime(time);
      if (hours < 0 || hours >= 24 || minutes < 0 || minutes >= 60) {
        setTime('00:00'); 
        setFunction(new Date(dateValue.getTime()));
      } else {
        const newDate = new Date(dateValue.getTime()); 
        newDate.setHours(hours);
        newDate.setMinutes(minutes);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        setFunction(newDate);
        setTime(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
      }
    }
  };

  const handlePeriodChange = (event:any, newPeriod:any) => {
    if (newPeriod !== null) {
      const currentHours = date.getHours();
      const isPM = currentHours >= 12;
      let newHours = currentHours;
  
      if (newPeriod === 'AM' && isPM) {
        newHours -= 12;
      } else if (newPeriod === 'PM' && !isPM) {
        newHours += 12;
      }
  
      const newDate = new Date(dateValue || new Date());
      newDate.setHours(newHours);
      newDate.setMinutes(date.getMinutes());
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      setFunction(newDate);
    }
  };


  return <Box style={{display:'flex', columnGap:'0.5rem'}}>
    <StyledTimeTextField variant="outlined" onChange={handleTimeChange}
     className="time_picker_input"
     value={time}
     onBlur={handleBlur}
     disabled={isTimePickerDisable}
     />
    <AmPmPicker period={period} handlePeriodChange={handlePeriodChange} isTimePickerDisable={isTimePickerDisable}/>  
  </Box>
}


const AmPmPicker = ({ period, handlePeriodChange, isTimePickerDisable }:any) => (
  <Paper elevation={0} style={{ backgroundColor: '#4B4E55' }}>
    <StyledToggleButtonGroup
      size="small"
      value={period}
      exclusive
      onChange={handlePeriodChange}
      aria-label="AM/PM picker"
    >
      <ToggleButton disabled={isTimePickerDisable} value="AM" aria-label="AM">AM</ToggleButton>
      <ToggleButton disabled={isTimePickerDisable} value="PM" aria-label="PM">PM</ToggleButton>
    </StyledToggleButtonGroup>
  </Paper>
);


const StyledTimeTextField = styled(TextField)({
  backgroundColor: '#4B4E55',
  width: '4rem',
  color: 'white',
  height:'2rem',
  borderRadius:"10px",
  '& .MuiInputBase-input': {
    color: 'white',
    padding: '0.5rem',
    textAlign:'center'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  padding:"0.15rem",
  height:"1.7rem",
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    color: 'white',
    borderRadius: 5,
    '&.Mui-disabled': {
      border: 0,
    },
  },
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    marginLeft: -1,
    borderLeft: '1px solid transparent',
  },
  '& .Mui-selected': {
    backgroundColor: '#FEDEA1',
    color: '#664200',
    // height:"2rem",
    '&:hover': {
      backgroundColor: '#F0E0A0', 
    },
  },
});


const GeneralParentBox = styled(Box)({
  backgroundColor: 'black',
  padding: '1rem',
  color: 'white',
  borderRadius: '1rem',
});


const renderSystemSettingsCancelAndSubmit=({handleCancel,handleSubmit}:{handleCancel:()=>void,handleSubmit:()=>void})=>{
  return <Box className="profile_submission">
  <FormSubmitButton style={{color:"white",background:"#3E4247"}} fullWidth onClick={handleCancel} className="cancel_changes">Cancel</FormSubmitButton>
  <FormSubmitButton fullWidth className="profile_test_submit save_changes" onClick={handleSubmit}>Save Changes</FormSubmitButton>
  </Box>
}

const formatTIme=(time:number)=>{
  return time < 10 ? `0${time}` : time 
}

const RenderTimerAndResend=({seconds,submit}:{seconds:number,submit:()=>void})=>{
  const isEnabled = seconds === 0;
  return <Box className="forp_password_timer_resend">
    <CustomLabel style={{color:'white'}}>
      00:{formatTIme(seconds)}
    </CustomLabel>
    <Button disabled={!isEnabled} variant="text" onClick={()=>submit()} style={{textTransform:'none',fontSize:'1.2rem',color:isEnabled ? colors.GOLD_COLOR : '#94A3B8'}} className="resend_button">Resend</Button>
  </Box>
}

const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    color: 'white', // Default color for all numbers
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    color: '#FFD27E', 
    border:'1px solid #6C7278'
  },
  '& .MuiPagination-ul':{
    justifyContent:'end'
  }
})

const renderTableLoader = (waitText='Please Wait',status="We're searching for results....")=>{
  return <Loader height="50vh">
  <Box className="cf_avinod_no_result_head loader_container" marginTop={'1rem'}>{waitText}</Box>
  <Box fontSize={'1.2rem'}>{status}</Box>
</Loader> 
}

const NoRecordFoundRow=({children}:{children:React.ReactNode})=>{
  return <>
  <TableRow className="dash_table_row no_result_found">
<TableCell className="dash_rounded-row-first-cell dash_rounded-row-last-cell" colSpan={9} style={{textAlign:'center'}}>No Data Found!</TableCell>
</TableRow>
<TableRow>
<TableCell style={{padding:"0", backgroundColor:'black',border:'none',paddingTop:'1rem'}} colSpan={8}>
  {children}
  </TableCell>
</TableRow>
</>
}


// Styles

const otpcontainerStyle = {
  display:'flex',
  justifyContent:'space-between',
  margin:'auto',
  width:'100%',
  columnGap:'1rem'
}

const otpinputStyle = {
  width:'100%',
  height:'4rem',
  fontSize:'2rem',
  borderRadius:10,
  backgroundColor:'inherit',
  color:'inherit'
}

// Styles
const CustomInputLabel = styled(InputLabel)({
  color:"#FFD17A !important" ,
  paddingTop:"1.1rem",
  paddingLeft:"2rem",
  fontSize:"2rem",
})

const CustomSelectOrderDetails = styled(CustomFormAircraftSelect)({
  border: '1px solid #CBD5E1', 
  height:"4rem",
  backgroundColor:'inherit'
})

const UserDetailsBox = styled(Box)(({status=""}:{status?:string})=>(
  ({
    background: "#1A1C1E",
    borderRadius: "8px",
    paddingTop:"1rem",
    "& .w-100": {
      width: "200px",
      height:"200px",
      border:"3px solid #FFD27E",
      borderRadius:"50%",
    },
    "& .innerbox": {
      padding: '10px'
    },
    "& .text-center": {
      textAlign: "center",
      "& .client-name": {
        fontSize: "16px",
        color: "#fff",
        margin: "0 0 0 0"
      },
      "& .account-id": {
        fontSize: "14px",
        color: "#94A3B8",
      },
      marginBottom: "10px"
    },
    "& .d-flexBetween": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    "& .status-box":{
      padding : "0.4rem 1.4rem",
      background:"#2D3034",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      borderRadius:"2rem",
      marginTop:"0.5rem",
      gap:"0.2rem"
    },
    "& .status":{
      fontWeight:400,
      fontSize:"1.2rem",
      color:status==="deactivated"?"#F87171":"#34D399",
      background:"inherit",
      border:"none",
    },
    "& .status-button":{
      background:status==="deactivated"?"#F87171":"#34D399",
      height:"0.8rem",
      width:"0.8rem",
      borderRadius:"50%",
      border:"none",
      marginTop:"0.2rem"
    },
    "& .d-flex": {
      display: "flex",
      alignItems: "center"
    },
    "& .flex-center":{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .flex-column":{
      flexDirection:"column"        
    },
    "& .update-email-box": {
      background: "#2D3034",
      padding: "8px",
      borderRadius: "8px",
      cursor: "pointer",
      "& .ml8": {
        marginLeft: "8px"
      },
      "& .update-email": {
        fontSize: "14px",
        color: "#CBD5E1",
      },
      "& .email-id": {
        fontSize: "16px",
        color: "#fff",
        margin: 0
      },
      marginBottom: "15px"
    },
    "& .personal-info-box": {
      "& .heading": {
        fontSize: "16px",
        color: "#FFFFFF",
        margin: "0 0 15px 0",
        fontWeight: 600
      }
    },
    "& .user-details-box": {
      borderBottom: "1px solid #3E4247",
      paddingBottom: "10px",
      marginBottom: "10px",
      overflow:"hidden",
    },
    "& .left-text": {
      color: "#94A3B8",
      fontSize: "15px",
      margin: 0,
      fontWeight: 600,
      minWidth:"50%"
    },
    "& .right-text": {
      color: "#fff",
      fontSize: "16px",
      margin: 0,
      wordBreak:"break-all",
      hyphens:"auto",
      overflowWrap:"break-word"
    },
    "& .mb15": {
      marginBottom: "15px"
    },
    "& .btn-div": {
      background: "transparent",
      width: "100%",
      textAlign: "center",
      color: "#FFD27E",
      border: "1px solid #FFD27E",
      borderRadius: "5px",
      padding: "12px 0",
      fontWeight: 600,
      fontSize: "16px",
      cursor: "pointer"
    },
    "& .red-button":{
      border:"1px solid #F87171",
      color:"#F87171"
    },
    "& .border-div":{
      borderTop: "1px solid #3E4247",
      padding: "0.8rem 0",
    }
  })
))

const CustomModalContent = styled(Box)(({account=false}:{account?:boolean})=>(
  ({
    maxHeight: '80vh',
    width: account?"450px":"500px",
    padding: '40px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
    borderRadius: '10px',
    color:"white",
    "& .icon-box":{
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      marginBottom:account?"-13%":"-10%",
  
    },
    "& .modal-title": {
      fontWeight: 700,
      fontSize: account?"1.7rem":"36px",
      fontFamily: "Manrope",
      margin: "0",
    },
    "& .modal-subtitle": {
      fontWeight: account?500:400,
      fontSize: account?"1.1rem":"20px",
      fontFamily: "Manrope",
      color:account?"#94A3B8": "white"
    },
    "& .modal-content": {
      backgroundColor: '#282B2E',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      borderRadius:"10px",
    },
    "& .modal-button": {
      fontWeight: 700,
      fontSize: "20px",
      background: "var(--primary-purple-brand-gradient, linear-gradient(99deg, #FFE3AE 2.64%, #FFD07A 100%))",
      borderRadius: "8px",
      padding: "1rem 10.5rem",
      margin: "1.5rem 0 0.5rem 0",
      cursor: "pointer",
      border:"none"
    },
    "& .account-button":{
      fontWeight: 800,
      fontSize: "1rem",
      background: "var(--primary-purple-brand-gradient, linear-gradient(99deg, #FFE3AE 2.64%, #FFD07A 100%))",
      borderRadius: "8px",
      padding: "0.5rem 3rem",
      // marginTop: "2.5rem",
      cursor: "pointer",
      border:"none"      
    },
    "& .cancel-account":{
      fontWeight: 800,
      fontSize: "1rem",
      background: "inherit",
      borderRadius: "8px",
      padding: "1rem 3rem",
      // marginTop: "2.5rem",
      cursor: "pointer",
      border:"1px solid #FFD27E",
      color:"#FFD27e"

    }
  })
));

const StyledOtpContainer = styled(Box)(()=>({
  backgroundColor:"rgba(0, 0, 0, 0.90)",
  maxHeight: '80vh',
  width: '400px',
  padding: '40px',
  overflow: 'auto',
}));

const formatCardNumber = (value:string | null | number) => {
  const cardVal = value === null ?  '' : value
  const cleaned = ('' + cardVal).replace(/\D/g, '');
  const match = cleaned.match(/.{1,4}/g);
  if (match) {
    return match.join(' ');
  }
  return cardVal;
};

const cleanCardNumber=(cardNum:string)=>{
  return cardNum.replace(/\s+/g, '');
}

const formatNumberWithCommas = (value: string) => {
  const parts = value.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const handleVendorChargedChange = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,keyName:string) => {
  const formattedValue = formatNumberWithCommas(event.target.value.replace(/,/g, ''));
  setFieldValue(keyName, formattedValue);
};

const dollarTextFieldInputProps = {
  inputProps:{
    style:{marginLeft : '-0.4rem'}
  },
  startAdornment: (
    <InputAdornment position='start'>
      <Box color={'white'}>$</Box>
    </InputAdornment>
  ),
}
const validContractsExtension = /^(image\/(jpeg|png)|application\/pdf)$/;
const CustomLinearProgress = styled(LinearProgress)({
  borderRadius: 5,
  backgroundColor: '#fff',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#FFD27E', 
  },
});

function LinearProgressWithLabel() {
  return (
    <Box display="flex" alignItems="center" marginTop={'1.2rem'}>
      <Box width="100%" mr={1}>
        <CustomLinearProgress/>
      </Box>
    </Box>
  );
}

const StatusDot=({innerColor,outerColor} : {innerColor:string,outerColor:string})=>{
    return <Box style={{borderRadius:'50%',width:"1.5rem",height:'1.5rem',backgroundColor:outerColor, display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Box style={{borderRadius:"50%",width : '0.75rem', height : '0.75rem', backgroundColor:innerColor}}></Box>
    </Box>
}


const StyledChip=styled(Chip)({
  backgroundColor:'#FFD27E',
  fontWeight:'bold',
  padding :"0.2rem 0rem",
  height:'auto'
})

const BoxContainer = styled(Box)({
  display:"flex",
  flexWrap:"wrap",
  gap:"1rem 2rem"
})

const BoxItems = styled(Box)({
  flex: "1 1 calc(50% - 1rem)",
  boxSizing: "border-box"

})


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      padding: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      border: 'none',
    },
    [theme.breakpoints.up('md')]: {
      border: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      border: 'none',
    },
  },
  secondInRowLg: {
    borderLeft: '1px solid grey',
    borderRight: '1px solid grey',
  },
  borderLeftSt:{
    borderLeft: '1px solid grey',
  }
}));


function isOnePatternNumber(n:number) {
  if (n % 3 === 1) {
      return true;
  } else {
      return false;
  }
}

const GridItem = ({ children, index }: { children: React.ReactNode; index: number }) => {
  const classes = useStyles();
  const theme = useTheme();
  const breakpoint = theme.breakpoints.values;

  const isLg = window.innerWidth >= breakpoint.lg;
  const isMd = window.innerWidth >= breakpoint.md && window.innerWidth < breakpoint.lg;

  let additionalClass = '';
  if (isLg) {
    if (isOnePatternNumber(index)) {
      additionalClass = classes.secondInRowLg;
    }
  } else if (isMd) {
    const positionInRowMd = (index % 2) + 1; 
    if (positionInRowMd === 2) {
      additionalClass = classes.borderLeftSt;
    }
  }

  return (
    <Grid
      item
      lg={4}
      md={6}
      xs={12}
      className={`${classes.root} ${additionalClass} booking_section`}
    >
      {children}
    </Grid>
  );
};

const adminSelectProps = {
  getContentAnchorEl: null,
  className: "profile_ditry_menu",
  anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
  },
  transformOrigin: {
      vertical: "top",
      horizontal: "left",
  },
}

const ErrorBoxes = ({touched,errors,message}:{touched: TouchedMessage,errors : string | undefined,message:string | undefined})=>{
  return <>
    {touched && errors && (
      <Box className="login_valid_error" id = 'profile_error_properties'>{message}*</Box>
    )}
  </>
}

const FooterCancelSaveChanges=({cancel,submit=()=>{}}:{cancel:()=>void,submit?:()=>void})=>{
  return <Box className="profile_submission">
    <FormSubmitButton style={{color:"white",background:"#3E4247"}} className="admin_cancel_changes"  fullWidth onClick={()=>cancel()}>Cancel</FormSubmitButton>
    <FormSubmitButton fullWidth className="profile_test_submit" onClick={()=>submit()}>Save</FormSubmitButton>
  </Box>
}

const renderSelectCatOption = (option: string,isSelected:boolean) => {
  return (
    <>
      <Box className={`advance_search_option ${isSelected && 'advance_search_selected_option'}`}>
        <Box>
          <Flight style={{ color: `${!isSelected ? '#34D399' : 'black'}`, fontSize: "1.2rem" }} />
        </Box>
        <Box>{option}</Box>
      </Box>
    </>
  )
}


const quill_modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "bullet" }, { list: "ordered" }]
  ]
};
const quill_formats = ["bold", "italic", "underline", "list", "bullet"];
const threadOptions = [
  {option:"All",outerColor:"",innerColor:""},
  {option:"Admin",outerColor:"#2A542D",innerColor:"#21C915"},
  {option:"AI Concierge",outerColor:"#575043",innerColor:"#DDB872"},
  {option:"Closed",outerColor:"#383C41",innerColor:"#5A6468"}
]

const renderDeleteIconContainer=()=>{
  return <Box className="modal_check_icon_cont" component={'span'} style={{padding:'0.7rem',top:'-18%'}}>
    <DeleteSvg/>
  </Box>
}

export {renderDeleteIconContainer, BoxContainer,BoxItems,threadOptions,CvvIconContainer, quill_modules,quill_formats,renderSelectCatOption,FooterCancelSaveChanges, ErrorBoxes, adminSelectProps, GridItem, CSSPropertiesMap, StatusDot,StyledChip, LinearProgressWithLabel,validContractsExtension, dollarTextFieldInputProps, handleVendorChargedChange, SureModalButtonContainer,cleanCardNumber, formatCardNumber, PaymentStatusParent, PaymentStatusIcon,GreySkeleton,StatusGeneralParentContainer,SurityModal,CustomSelectOrderDetails, CustomInputLabel,renderGlobalSearchOptions,CustomCheckbox,StyledPagination, renderTableLoader, NoRecordFoundRow, RenderTimerAndResend,otpcontainerStyle,
  otpinputStyle, StatusModal, MuiResuableStatusModalBody, CustomFormSelectNew, renderSystemSettingsCancelAndSubmit, 
  GeneralParentBox, RequirementKindTextField, TimePickerInputCustom,TimePickerNew, CustomStyledTimePicker, StyledGrid, 
  renderOptionFrom, handleMinutesAsHoursAndMinutes, CustomSwitch, CustomDashboardButton, CustomRadio, PaymentLabel, 
  PaymentTexfields, SearchRequestTexfield, SearchTextFieldActiveBooking, FilterTypeButton, StyledEditDeleteMenu, Loader,
   GoldishSimpleButton, Footer ,CustomFormAircraftSelect,PassengerBox,ButtonTogglerFormContainer,CustomDateFormTextField,
   SearchStyledTextField,CustomMuiButtonPassenger,SearchStyledAutocomplete,CustomFormTabButton,CustomFormTabPanel, 
   SearchStyledPopper, StyledOnlineBadge, FilterCustomFormTextField, ModalHeading, StyledModalContent, MuiModal ,
   CustomFormTextField, CustomLabel,UserDetailsLabel, HorizantalRow, CustomFormCheckbox, ForgotPasswordTypeButton, 
   FormSubmitButton, CustomErrorMessage,CustomSubHeadingText,CustomTypography,CustomBox, 
   CustomDepartueAiportAutoCompplete, CustomFormSelect, CustomInputLabelForSelect, CustomStyledPopper, MenuItemForSelect, 
   AddLegtTypeButton, GenralUsedAccordion, MyAccordionSummary, SidebarAccordion, OutlineButton,ContainerSameAsTextField,
   UserProfileLabel,CustomTextField,PassengerLabel,PhoneNumberFieldBox,renderDashboardSearchOptions,UserDetailsBox,CustomModalContent,StyledOtpContainer,clientSearchOptions,ClientLabel,ClientValue,TopupButton,TopupAmountBox}

// Customizable Area End