import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme} from "@material-ui/core/styles";
import {GoldishSimpleButton, GeneralParentBox, HorizantalRow, Footer, CustomDashboardButton, renderSystemSettingsCancelAndSubmit, CustomLabel,CustomFormTextField} from "../../../components/src/MuiCustomComponents";
import {ArrowBack} from '@material-ui/icons'
import {theme as colors} from '../../../components/src/utils';
import {Formik, FormikProps} from "formik";
import {systemSettingsSchema} from "../../../components/src/Validations";



interface LabelAndTextField {
  placeholder:string,
  label:string | null,
  formikProps:FormikProps<any>,
  keyName:string
}


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

   renderSystemSettiingHeading=()=>{
    const {isEditMode} = this.state;
    const editText = isEditMode ? 'Edit' : '';
    return  <GoldishSimpleButton
    style={{ color: colors.GOLD_COLOR, textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}
    className='cf-avinode-search'
    data-testId = 'redirect_back'
    onClick={this.handleGoBack}
  >
  <ArrowBack className = 'cf_avinod_arrow_search'/>
    {editText} System Settings
  </GoldishSimpleButton>
  }
  

  renderSystemMainContent=()=>{
    const {isEditMode} = this.state;
    return <GeneralParentBox style={{...webStyle.mainBox,padding:"1.5rem"}}>
       {!isEditMode ? this.renderShowSystemSettingInfo() : this.renderEditSystemSettingForm()}
    </GeneralParentBox>
  }



  renderShowSystemSettingInfo=()=>{
    const {systemSettingsValues:{domestic_segment_fee,international_segment_fee,landing_fee,markup_percentage,tax,payment_processing_fee}} = this.state
    return <Box style={webStyle.childGreyContainer} className="info_system_parent">
      {this.renderPriceInfoRow('Markup Percentage',markup_percentage,'')}
      <HorizantalRow/>
      {this.renderPriceInfoRow('Domestic Segment Fee',domestic_segment_fee,undefined,'')}
      <HorizantalRow/>
      {this.renderPriceInfoRow('International Segment Fee ',international_segment_fee,undefined,'')}
      <HorizantalRow/>
      {this.renderPriceInfoRow('Landing Fee',landing_fee,undefined,'')}
      <HorizantalRow/>
      {this.renderPriceInfoRow('Tax',tax,'')}
      <HorizantalRow/>
      {this.renderPriceInfoRow('Payment Processing Fee',payment_processing_fee,'')}
    </Box>
  }

  renderPriceInfoRow = (keyName:string, value:number | null, currency = '$ ', percent = '%') => {
    const valueUpdated = value !== null ? `${currency}${value}${percent}` : value;
    return (
        <Box style={webStyle.priceRow}>
            <Box style={webStyle.keyStyle}>{keyName}</Box>
            <Box style={webStyle.valueStyle}>{valueUpdated}</Box>
        </Box>
    );
}

  renderSystemSettingsFooter=()=>{
    const {isEditMode} = this.state;
    return <Footer>
      {!isEditMode? this.renderSettingEditButtonContainer() : renderSystemSettingsCancelAndSubmit(
         {
           handleCancel: ()=>this.handleEditMode(false),
           handleSubmit:()=>this.formikForm?.handleSubmit()
          }
        )}
    </Footer>
  } 


  renderEditSystemSettingForm = () => {
    const {systemSettingsValues:{domestic_segment_fee,international_segment_fee,landing_fee,markup_percentage,tax,payment_processing_fee}} = this.state;

    const initial_values = {
      domestic_segment_fee:domestic_segment_fee,
      international_segment_fee:international_segment_fee,
      landing_fee:landing_fee,
      markup_percentage:markup_percentage,
      tax,
      payment_processing_fee
    }

    return (
      <Formik
        initialValues={initial_values}
        onSubmit={(values) => {
         this.handleEditSystemSettings(values);
        }}
        validationSchema={systemSettingsSchema}
        validateOnChange
        validateOnBlur
        enableReinitialize
        innerRef={(formikProps) => {
          this.formikForm = formikProps;
        }}
      >
        {(formikProps) => (
            <Box
              className='booking_section edit_system_setting_parent'
              style={{ rowGap: "1.8rem", padding: "1rem" }}
            >
              {this.renderLabelAndTextField({
                label: "Enter Markup Percentage (%)",
                placeholder : "Enter Markup Percentage",
                formikProps,
                keyName:'markup_percentage'
              })}
              {this.renderLabelAndTextField({

                label : "Domestic segment fee ($)",
                placeholder: "Enter Domestic segment fee",
                formikProps,
                keyName:'domestic_segment_fee'
              })}
              {this.renderLabelAndTextField({
                label  : "International segment fee ($)",
                placeholder: "Enter International segment fee",
                formikProps,
                keyName:'international_segment_fee'
              })}
              {this.renderLabelAndTextField({
                label: "Landing Fee ($)",
                placeholder: "Enter Landing Fee",
                formikProps,
                keyName:'landing_fee'
              })}
              {this.renderLabelAndTextField({
                placeholder: "Enter Tax",
                formikProps,
                keyName:'tax',
                label: "Tax (%)",
              })}
              {this.renderLabelAndTextField({
                label: "Payment Processing Fee (in %)",
                placeholder: "Enter Payment Processing Fee",
                keyName:'payment_processing_fee',
                formikProps,
              })}
            </Box>
        )}
      </Formik>
    )
  }


  renderLabelAndTextField=({label,placeholder,formikProps,keyName}:LabelAndTextField)=>{
    const {handleChange,values,touched,errors}  = formikProps;
    const {touchedLabel} = this.state;
    return <Box>
    <CustomLabel 
     style={{ margin: "0", marginBottom: "5px" }}
     isFocused = {touchedLabel ===  label} 
     isError={touched[keyName] && errors[keyName]}

     >
      {label}
    </CustomLabel>
    <CustomFormTextField
      isFocusedTypeInput={true}
      isErrorTouch = {touched[keyName] && errors[keyName]}
      id={keyName}
      variant="outlined"
      onChange={handleChange}
      fullWidth
      className="label_text_field"
      value={values[keyName]}
      placeholder={placeholder}
      onFocus={()=>{this.handleActiveFocusedLabel(label)}}
      onBlur={()=>{this.handleActiveFocusedLabel(null)}}
      autoComplete="off"
    />
     {touched[keyName] && errors[keyName] && <Box className="login_valid_error" id="profile_error_properties">
              {errors[keyName]}
      </Box>}
  </Box>
  }


  renderSettingEditButtonContainer=()=>{
    return <Box className = 'footer_global'>
    <CustomDashboardButton size="large" variant="outlined" className="edit_terms_policy edit_contact_details_button edit_system_setting" onClick={()=>this.handleEditMode(true)}>
        Edit Settings
    </CustomDashboardButton>
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="booking_section system_setting_parent">
        {this.renderSystemSettiingHeading()}
        {this.renderSystemMainContent()}
        {this.renderSystemSettingsFooter()}
      </Box>  
      // Customizable Area End
    );
  }
}

// Customizable Area Start

type CSSPropertiesMap = {
  [key: string]: React.CSSProperties;
};
const webStyle:CSSPropertiesMap = {
    childGreyContainer:{
      backgroundColor:'#2D3034',
      padding:'1.5rem',
      borderRadius:"1rem",
      fontWeight:'bold',
      display:'flex',
      rowGap:"1.2rem",
      flexDirection:"column"
    },
    mainBox:{
      marginBottom:'7rem'
    },
    priceRow:{
      display:'flex',
      justifyContent:"space-between",
      alignItems:'center'
    },
    keyStyle:{
      color:'#94A3B8',
      fontSize:'1.4rem',
    },
    valueStyle:{
      color:colors.GOLD_COLOR,
      fontSize:'1.6rem',
    }
};
// Customizable Area End
