import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleNavigation } from "../../../components/src/Navigator";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  termsCondsId: string;
  termsConds: string;
  title: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  updateTermsCondsCallId: string = "";
  getFaqToEditCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      termsCondsId: "",
      termsConds: "",
      title:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    this.handleMountApiCall();
  }

  
  handleEditorChange = (content:any, delta:any, source:any, editor:any) => {
    this.setState({ termsConds: content });
  };


  handleBack = () => {
    const { location: { pathname } } = window;
  
    switch (pathname) {
      case '/TermsConditionsEdit':
        handleNavigation("TermsConditions",this.props,this.send);
        break;
  
      case '/PrivacyPolicyEdit':
        handleNavigation("PrivacyPolicy",this.props,this.send);
        break;
  
      default:
        handleNavigation("FAQ",this.props,this.send);
        break;
    }
  }

  isTermConditionEdit=()=>{
    const endPoint = window.location.pathname;
    if(endPoint === '/TermsConditionsEdit'){
      return true;
    }
    return false;
  }

  handleGetEndPoint=()=>{
    if(this.isTermConditionEdit()){
      return configJSON.getTermsCondsApiEndPoint;
    }
    return configJSON.privacyPolicyEndpoint;
  }


  handleUpdate =(responseJson:any) =>{
    if(responseJson){
      this.handleBack();
    }
  }

  handleTitleChange = (value:string) => {
    this.setState({ title : value });
  };


  getTermsConds = async (token:string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessaged.messageId;

    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.handleGetEndPoint()
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessaged.id, requestMessaged);
  };

  getFAQToEdit = async (faqId:any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const endPoint = `${configJSON.addFaqEndPoint}/${faqId}`;
    const requestMessaged = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFaqToEditCallId = requestMessaged.messageId;

    requestMessaged.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessaged.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessaged.id, requestMessaged);
  };

  handleTextChange = (value: string | undefined) =>
    value && this.setState({ termsConds: value });

  sendTermsConds = async(termsCond:string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('token'),
    };
    const requestedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateTermsCondsCallId = requestedMessage.messageId;
    requestedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.handleEndPoint()
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.handleBodyFormat())
    );

    runEngine.sendMessage(requestedMessage.id, requestedMessage);
  };

  sendAddAndEditFAQ = async(endPointParams:any, methodType:any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('token'),
    };
    const requestedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateTermsCondsCallId = requestedMessage.messageId;
    requestedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addFaqEndPoint + endPointParams
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    requestedMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.handleFaqBody())
    );

    runEngine.sendMessage(requestedMessage.id, requestedMessage);
  };

  handleEndPoint=()=>{
    const id = 1;
    if(this.isTermConditionEdit()){
      const end_point = `${configJSON.createTermsCondsApiEndPoint}/${id}`;
      return end_point;
    }
    return `${configJSON.getPrivacyPolicyUpdateEndPoint}/${id}`;
  }

  handleBodyFormat=()=>{
    const body = {
      // title:this.state.title,
      description: this.state.termsConds,
    };
    if(this.isTermConditionEdit()){
      return {terms_and_condition : body};
    }
    return {privacy_and_policy : body};
  }


  handleFaqBody=()=>{
    const {title, termsConds} = this.state;
    const body = {
      data : {
        question : title,
        answer : termsConds
      }
    }
    return body;
  }

  handleMountApiCall=()=>{
    const heading = this.handleHeading()
    if(heading === 'Edit Terms & Conditions' || heading === 'Edit Privacy Policy'){
      this.getTermsConds('');    
    }else if(heading === 'Edit FAQ'){
      const queryParams = new URLSearchParams(window.location.search);
      const FAQId = queryParams.get('q') as string;   
      this.setState({termsCondsId:FAQId});
      this.getFAQToEdit(FAQId);
    }
  }

  handleSaveChanges=()=>{
    const heading = this.handleHeading()
    if(heading === 'Edit Terms & Conditions' || heading === 'Edit Privacy Policy'){
      this.sendTermsConds("");
    }else {
      const {termsCondsId} = this.state;
      const isAddFaq = heading === 'Add FAQ';
      const endPointParams = isAddFaq ?  '' : `/${termsCondsId}`;
      const methodType = isAddFaq ?  configJSON.postApiMethod : configJSON.patchApiMethod;
      this.sendAddAndEditFAQ(endPointParams, methodType);
    }
  }

  handleHeading = () => {
    const { location: { pathname } } = window;
  
    switch (pathname) {
      case '/AddFAQ':
        return 'Add FAQ';
  
      case '/TermsConditionsEdit':
        return 'Edit Terms & Conditions';
  
      case '/PrivacyPolicyEdit':
        return 'Edit Privacy Policy';
  
      default:
        return 'Edit FAQ';
    }
  }

  handleFaqResponse=(responseJson:any)=>{
    if(responseJson.data){
      const {data:{attributes:{question, answer}}} = responseJson;
      this.setState({title: question, termsConds : answer});
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (
      apiRequestCallId === this.getTermsCondsCallId &&
      responseJson && !errorResponse 
    ) {
      this.setState({
        title: responseJson?.data?.attributes?.title,
        termsConds: responseJson?.data?.attributes?.description,
        termsCondsId:responseJson?.data?.attributes?.id       
      });

    }else if(apiRequestCallId === this.updateTermsCondsCallId && responseJson && !errorResponse){
      this.handleUpdate(responseJson)
    }else if(apiRequestCallId === this.getFaqToEditCallId){
      this.handleFaqResponse(responseJson);
    }
    // Customizable Area End
  }
}
