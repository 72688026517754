import React from "react";

// Customizable Area Start
import {
  Box,
  InputAdornment,
  styled,
  Button,
  Grid,
  Avatar,
  Snackbar,
  FormControl,
  MenuItem,
  TextFieldProps
} from "@material-ui/core";
import { logo, LogoutIcon,SelectedBookingIcon,SelectedInvoiceIcon,SelectedAccountIcon,SelectedClientIcon } from "./assets";
import {KeyboardArrowDown, CheckCircle, Cancel} from "@material-ui/icons"
import {FilterCustomFormTextField,CustomFormSelect,SearchStyledAutocomplete,SearchStyledPopper,renderGlobalSearchOptions,CustomInputLabel} from "../../../components/src/MuiCustomComponents";

import Sidebar from "./Sidebar.web";
import "./Navigation.css";
import { NavLink} from "react-router-dom";

const adminSelectProps = {
  disableScrollLock: true,
  getContentAnchorEl: null,
  className: "filter",
  anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
      position:"absolute",
  },
  transformOrigin: {
      vertical: "top",
      horizontal: "left",
  },
  style:{marginTop:"-4px",borderTopLeftRadius:"0px",borderTopRightRadius:"0px"}
}

interface AutoCompleteMenuType {
  selected:boolean
}

interface ClientAccount{
  id:string,
  client_name:string
}
interface BookingInvoice{
  id:string,
  client_name:string,
  booking_id:string,
  invoice_number:string,
  }

  const DefaultMenuItem = ()=><MenuItem style={{ display: "none" }}>Please Specify</MenuItem>

const FilterButton = styled(Button)({
  borderRadius: "0.5rem 0rem 0rem 0.5rem",
  background: "var(--primary-purple-brand-gradient, linear-gradient(99deg, #FFE3AE 2.64%, #FFD07A 100%))",
  color: "black",
  fontWeight: "bold",
  fontSize: '1.4rem',
  height: '3.2rem', 
  textTransform: "none",
});

const StyledSnackbar = styled(Snackbar)({
  '& > div':{
    color: "white",
    width : "auto",
    borderRadius: '8px',
    background: 'rgba(45, 48, 52, 0.90)',
    boxShadow: '0px 6px 15px -3px rgba(0, 0, 0, 0.15)'
  },
  '& .MuiSnackbarContent-root': {
    minWidth : 'auto',
    position:'relative',
    bottom : '6rem',
    left:'9rem'
  }
});

const BoxContainer = styled(Box)({
  "&.selected svg path": {
    fill: '#FFD17A',
  }   
})

const NavLogoutButton = styled(Button)({
  backgroundColor: 'black',
  color: '#FFD078',
  textTransform: 'capitalize',
  '&:hover': {
      backgroundColor: 'black',
  },
  display:'flex',
  columnGap:'1rem',
  padding : '0.5rem 1rem',
  borderRadius : '0.5rem'
});


// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderNavbar = ()=>{
    return <Box className="navbar">
    <Box className="logo">
      <img src={logo}/>
    </Box>
      {this.renderFilterAndTextfield()}
      {this.renderProfileAndLogoutButton()}
  </Box>
  }

  renderFilterAndTextfield=()=>{
    const {filterType} = this.state
    const filters=[{name:"Booking No",icon:SelectedBookingIcon,filterType:"booking_no"},
    {name:"Invoice No",icon:SelectedInvoiceIcon,filterType:"invoice_number"},
    {name:"Account ID",icon:SelectedAccountIcon,filterType:"account_id"},
    {name:"Client Name",icon:SelectedClientIcon,filterType:"client_name"}]    
    return  <Box className="nav_filter_button_textfield">
       <Grid container>
        <Grid item lg={3} md = {3} sm={4}>                                   
          <FormControl fullWidth>
            {!filterType && <CustomInputLabel id="demo-simple-select-label" >Filter</CustomInputLabel>}
             <CustomFormSelect
              height={"3.4rem"}
              fontSize={"1.2rem"}
              labelId="demo-simple-select-label"
              variant="outlined"
              filterSelect={true}
              isFocusedTypeInput={true}
              className="profile_customSelect"
              style={{borderBottomLeftRadius:"0px",borderBottomRightRadius:"0px",borderTopRightRadius:"0px"}}
              name="dietary_requirements"             
              id="filter"
              value={this.state.filterType||"filter"}
              onChange={(event:React.ChangeEvent<HTMLInputElement>)=>{this.handleFilterChange(event)}}
              inputProps={{
                IconComponent: KeyboardArrowDown,
              }}
              MenuProps={adminSelectProps}
              renderValue={()=>{
                const selectedOption = filters.find(option => option.filterType === filterType);
                return(
                  <Box display="flex" alignItems="center" gridColumnGap="0.6rem">
                    <BoxContainer pt={0.2} className="selected">
                      {selectedOption && <selectedOption.icon />}
                    </BoxContainer>
                    <Box>{selectedOption && selectedOption.name}</Box>
                  </Box>
                )
              }}
            >              
              {filters.map((element: {name:string,icon:React.FunctionComponent,filterType:string},filterIndex:number) => (
                <MenuItem key={filterIndex} value={element.filterType}>
                  <Box display={"flex"} alignItems={"center"} gridColumnGap={"0.6rem"}>
                   <BoxContainer  pt={0.2}>
                    {<element.icon></element.icon>}
                    </BoxContainer>
                    <Box>{element.name}</Box>                  
                  </Box>
                </MenuItem>
              ))}
            </CustomFormSelect> 
          </FormControl>
          </Grid>
        <Grid item lg={9} md = {9} sm={8}>
        <SearchStyledAutocomplete 
        options={this.state.options}
        PopperComponent={SearchStyledPopper}
        open={this.state.focus && this.state.searchValue}
        style={{borderBottomLeftRadius:"0px",borderTopLeftRadius:"0px"}}
        id="globalSearch"
        loading={this.state.loading}
        onChange={(event: React.ChangeEvent<{}>, newValue:ClientAccount|BookingInvoice ) => {
          this.handleGlobalSearchNavigation(newValue) 
        }}
        filterOptions= {(option:ClientAccount|BookingInvoice)=>option}
        ListboxProps={{ id: "flight_list_container" }}
        disabled={false}
        renderOption={(option: ClientAccount|BookingInvoice,{selected}:AutoCompleteMenuType) => renderGlobalSearchOptions(option,selected,false,filterType)}
        navbar={true}
        renderInput={(params:TextFieldProps) => (
          <FilterCustomFormTextField
            {...params}
            className={"login_test_password"}
            isValueSelected = {false}
            onFocus = {()=>{this.handleFocusSearchbar()}}
            onBlur = {()=>{this.handleBlur()}}
            variant="outlined"
            id="airporst-search"
            placeholder="Search..."
            onChange={this.handleGlobalSearchValue}
            InputProps={{
              ...params.InputProps,              
            }}
          />
        )}
      />  
      
       </Grid>
       </Grid>
          
    </Box>
  }

  renderProfileAndLogoutButton=()=>{
    const {photo,name} = this.state
    return <Box className = 'nav_profile_name'>
      <Box className = 'nav_name_cont' style={{width : '12rem'}}>
          <Box>
            <Avatar src={photo as string} alt="Remy Sharp" style={{width : "50px", height  : "50px"}}/>
          </Box>
          <Box className="nav_user_name  truncate-container" >{name}</Box>
      </Box>
      <NavLogoutButton onClick={this.handleLogout} className='sidebar_unactive_element'>
          <Box marginRight = '1rem'>Log Out</Box>
          <LogoutIcon/>
      </NavLogoutButton>

    </Box>
  }

  renderMessage = () => {
    const {isMessageVisible} = this.state
    const position:Record<string,string> = {
      vertical: "bottom",
      horizontal: "center",
    }
    const MessageVisibleTime = 3000;
    const { vertical, horizontal } = position as unknown as {
      vertical: "bottom",
      horizontal: "center",
    }
    return (
      <>
        <StyledSnackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isMessageVisible}
          onClose={()=>this.handleMessageOpener(false)}
          autoHideDuration={MessageVisibleTime}
          message={this.renderMessageContainer()}
          key={vertical + horizontal}
        />
      </>
    )
  }
  
  renderMessageContainer = () => {
    const {alertMessage, isAlertType} = this.state
    return (
      <Box className='message_container'>
        {isAlertType === 'success' ? 
        <CheckCircle style={{ fill: "#34D399", fontSize: "1.3rem" }} />
        :
        <Cancel style={{ fill: "red", fontSize: "1.3rem" }}/>}
        <Box>{alertMessage}</Box>
      </Box>
    )
  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box className="navigation_parent_container">
     {this.renderMessage()}
     {this.renderNavbar()}
    <Box className="container">
        <Box className="sidebar">
            <Sidebar navigation={this.props.navigation} id={"Sidebar"} screen={<></>} />
        </Box>
        <Box className="content">
          <Box className="content_screen">
            {this.props.screen}
          </Box>
        </Box>
    </Box>
    </Box>


    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
