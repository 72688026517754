// Customizable Area Start
import React from "react";
import { TextField, styled, Box, Checkbox, Button, Popper, Select, InputLabel, MenuItem, Modal, Badge, InputAdornment, FormControl, Grid } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {theme as colors} from "./utils";
import {CustomDateFormTextField, FormSubmitButton, PassengerBox, CustomMuiButtonPassenger, CustomFormAircraftSelect, SearchStyledAutocomplete, SearchStyledTextField, SearchStyledPopper} from "./MuiCustomComponents"

import moment from "moment"
import get from 'lodash/get';
import DatePicker from "react-datepicker";
import {DateRangeOutlined, FlightLand, FlightTakeoff, KeyboardArrowDown, AirplanemodeActive, AccountCircleOutlined, Flight, AddOutlined, RemoveOutlined, SyncAlt} from "@material-ui/icons";
import { FormikProps } from "formik";
type OneWayFormikPropType = FormikProps<any>;
interface autoCompleteMenuType {
  selected:boolean
}


  const formatDate = (date:string) => {
    return date ? moment(date).format('MMM D, YYYY  |  ddd - h:mm A') : '';
  };
  
  const renderDateTextField = (formikProps: OneWayFormikPropType,departureDateKey:string) => {
    const { values, setFieldValue, touched, errors } = formikProps
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (
      <>
        <DatePicker
          disabled={true}
          selected={values[departureDateKey]}
          onChange={(date) => setFieldValue(departureDateKey, date)}
          minDate={tomorrow}
          className="advance_search_date_pick"
          timeInputLabel="Time"
          dateFormat={"MM/dd/yyyy | h:mm aa"}
          value={formatDate(get(values,departureDateKey))}
          showTimeInput
          placeholderText="Add date & time"
          customInput={
            <CustomDateFormTextField
              placeholder="Add date & time"
              className={`login_test_password ${
                get(touched,departureDateKey) && get(errors,departureDateKey)
                  ? "login_border_error"
                  : ""
              }`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeOutlined />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          }
        />
         {get(touched,departureDateKey) && get(errors,departureDateKey) && (
          <Box className="login_valid_error" id="profile_error_properties">
            {get(errors,departureDateKey)}
          </Box>
        )}
      </>
    )
  }
  
  const renderSubmit = (formikProps: OneWayFormikPropType) => {
    const { handleSubmit } = formikProps
    return (
      <FormSubmitButton
        onClick={() => handleSubmit()}
        fullWidth
        style={{ fontWeight: "bolder" }}
        className="advance_search_test_submit"
      >
        Search
      </FormSubmitButton>
    )
  }
  
  const renderOption = (option: string,isSelected:boolean) => {
    return (
      <>
        <Box className={`advance_search_option ${isSelected && 'advance_search_selected_option'}`}>
          <Box>
            <Flight style={{ color: `${!isSelected ? '#34D399' : 'black'}`, fontSize: "1.2rem" }} />
          </Box>
          <Box>{option}</Box>
        </Box>
      </>
    )
  }
  
  const renderPassenger = (formikProps: OneWayFormikPropType,passengerKey:string) => {
    const { values, setFieldValue } = formikProps
  
    return (
      <PassengerBox>
        <Box marginLeft="10px">
          <AccountCircleOutlined />
        </Box>
        <Box>
          <Box className="advance_search_passenger_placeholder" color={colors.GRAY_COLOR_FORM}>Passengers</Box>
          <Box className="advance_search_passenger">
            <CustomMuiButtonPassenger
              disabled={true}
              className="advance_search_pass_button_test"
            >
              <RemoveOutlined style={{ color: "white", fontSize: "1.3rem" }} />
            </CustomMuiButtonPassenger>
            <Box>{get(values,passengerKey)}</Box>
            <CustomMuiButtonPassenger
              disabled={true}
              className="advance_search_pass_button_test"
            >
              <AddOutlined style={{ color: "white", fontSize: "1.3rem" }} />
            </CustomMuiButtonPassenger>
          </Box>
        </Box>
      </PassengerBox>
    )
  }
  
  const renderSelect = (value:string | null) => {
    return (
      <FormControl fullWidth>
        <CustomFormAircraftSelect
          disabled={true}
          fullWidth
          inputProps={{
            IconComponent: KeyboardArrowDown, 
          }}
          id = 'aircraftCategory'
          // onChange={(e)=>{setFieldValue(aircraftKey,e.target.value)}}
          value={value}
          renderValue={(selectedValue:any) => {
            return (
              <Box className='advance_search_select_box'>
                <AirplanemodeActive />
               
                  <Box marginLeft='1rem' style={{textTransform:'capitalize'}}>{selectedValue}</Box>
             
              </Box>
            )
          }}
          MenuProps={{
            style: { marginTop: "6.5px" },
            getContentAnchorEl: null,
            className: "advance_search_picker",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          variant="outlined"
        >
          <MenuItem style={{ display: "none" }} value={"Aircraft Category"}>
            {renderOption("Aircaft1",true)}
          </MenuItem>
        </CustomFormAircraftSelect>
      </FormControl>
    )
  }
  
  const renderOneWayForm = (
    formikProps: OneWayFormikPropType,
    fromLocationKey: string,
    toLocationKey: string,
    departureDateKey: string,
    passengerKey: string,
    aircraftKey: string,
 ) => {
    return (
       <>
          <Grid container style={{ rowGap: "1.5rem",width:'97%' }}>
             <Grid
                container
                item
                md={12}
                sm={12}
                lg={10}
                spacing={2}
                style={{ margin: "0", rowGap: "1.5rem" }}
             >
                <Grid item md={6} sm={6} lg={4}>
                   {renderFromFlight(formikProps, fromLocationKey)}
                </Grid>
                <Grid
                   item
                   md={undefined}
                   style={{
                      position: "relative",
                      padding: "0px",
                      height: "6rem",
                   }}
                >
                   {renderExhangeIcon(
                      formikProps,
                      fromLocationKey,
                      toLocationKey,
                   )}
                </Grid>
                <Grid item md={6} sm={6} lg={4}>
                   {renderToFlight(formikProps, toLocationKey)}
                </Grid>
                <Grid item md={12} sm={12} lg={4}>
                   {renderDateTextField(formikProps, departureDateKey)}
                </Grid>
             </Grid>
             <Grid
                container
                item
                md={12}
                sm={12}
                lg={2}
                spacing={2}
                style={{ margin: "0", rowGap: "1.5rem" }}
             >
                <Grid item md={12} sm={12} lg={12}>
                   {renderPassenger(formikProps, passengerKey)}
                </Grid>
                {/* <Grid item md={12} sm={12} lg={6}>
                   {renderSelect(formikProps, aircraftKey)}
                </Grid> */}
             </Grid>
          </Grid>
       </>
    )
 }
 
  
  const renderFromFlight = (formikProps: any,fromLocationKey:string) => {
    const { values, setFieldValue, touched, errors } = formikProps;
    return (
      <>
        <SearchStyledAutocomplete
          options={[
            "TETERBORO, (New Tokyo), dss United States -  KTEB",
            "TETERBORO, (Germany), dss United States -  KTEB",
          ]}

          disabled={true}
          PopperComponent={SearchStyledPopper as any}
          id="fromLocation"
          onChange={(_: React.ChangeEvent<{}>, newValue: unknown) => {
            setFieldValue(fromLocationKey, newValue)
          }}
          value={get(values,fromLocationKey)?.name}
          renderOption={(option: any,{selected}:autoCompleteMenuType) => renderOption(option,selected)}
          renderInput={(params:any) => (
            <SearchStyledTextField
              {...params}
              isValueSelected={{code:'IN'}}
              className={`login_test_password ${
                get(touched,fromLocationKey) && get(errors,fromLocationKey)
                  ? "login_border_error"
                  : ""
              }`}
              variant="outlined"
              label={`${get(values,fromLocationKey)?.icao} - ${get(values,fromLocationKey)?.city}, ${get(values,fromLocationKey)?.country.code}`}
              placeholder="Country, city or airport"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <FlightTakeoff
                      color="action"
                      style={{ marginRight: 5, color: "white", marginLeft: -5 }}
                    />
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        {get(touched,fromLocationKey) && get(errors,fromLocationKey) && (
          <Box className="login_valid_error" id="profile_error_properties">
            {get(errors,fromLocationKey)}
          </Box>
        )}
      </>
    )
  }
  
  const renderToFlight = (formikProps: OneWayFormikPropType,toLocationKey:string) => {
    const { values, touched, errors } = formikProps
    return (
      <>
        <SearchStyledAutocomplete
          options={[
            "TETERBORO, (New Jersey), dss United States -  KTEB",
            "TETERBORO, (Germany), dss United States -  KTEB",
          ]}
          disabled={true}
          isErrorBorder = {
            get(touched,toLocationKey) && get(errors,toLocationKey)
              ? true
              : false
          }
          PopperComponent={SearchStyledPopper as any}
          renderOption={(option: any,{selected}:autoCompleteMenuType) => renderOption(option,selected)}
          id="toLocation"
          onChange={(_: React.ChangeEvent<{}>, newValue: unknown) => {
            formikProps.setFieldValue(toLocationKey, newValue)
          }}
          value={get(values,toLocationKey)?.name}
          renderInput={(params:any) => (
            <SearchStyledTextField
              {...params}
              className={`login_test_password`}
              isValueSelected={{code:'IN'}}
              variant="outlined"
              label={`${get(values,toLocationKey)?.icao} - ${get(values,toLocationKey)?.city}, ${get(values,toLocationKey)?.country.code}`}
              placeholder="Country, city or airport"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <FlightLand
                      color="action"
                      style={{ marginRight: 5, color: "white", marginLeft: -5 }}
                    />
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
       {get(touched,toLocationKey) && get(errors,toLocationKey) && (
          <Box className="login_valid_error" id="profile_error_properties">
            {get(errors,toLocationKey)}
          </Box>
        )}
      </>
    )
  }
  
  const renderExhangeIcon = (formikProps: OneWayFormikPropType,fromLocationKey:string,toLocationKey:string) => {
    const { setFieldValue, values, validateForm } = formikProps
    return (
      <Box
        className="advance_search_exhange advance_search_exchange_container"
      >
        <SyncAlt />
      </Box>
    )
  }

  export {renderExhangeIcon,renderToFlight, renderFromFlight, renderOneWayForm, renderSelect, renderPassenger,renderOption, renderSubmit, renderDateTextField } 

  // Customizable Area End