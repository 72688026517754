import React from "react";

// Customizable Area Start
import {Box} from '@material-ui/core';
import {FormSubmitButton,GoldishSimpleButton, GeneralParentBox,AddLegtTypeButton,MuiModal,ModalHeading,StyledModalContent,CustomLabel,CustomFormTextField,HorizantalRow} from "../../../components/src/MuiCustomComponents";
import {theme as colors} from '../../../components/src/utils';
import {Formik} from 'formik';
import {Edit,DeleteOutline,ArrowBack} from "@material-ui/icons";
import * as Yup from 'yup';


const jobTItleValidationSchema = Yup.object().shape({
  jobTitle: Yup.string().trim().required('Job title is required*').notOneOf([''], 'Job title cannot be empty or just spaces*'),
});

interface JobDataType{
  attributes:{
    job_title:string
  }
  id:string
  jobData:string
}

// Customizable Area End

import JobtitlesController, {
  Props,
    configJSON,
} from "./JobtitlesController";

export default class Block extends JobtitlesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderJobTItleHeading=()=>{
    return  <Box justifyContent={'space-between'} alignItems={'center'} display={'flex'} paddingRight={'0.4rem'}>
    <GoldishSimpleButton
    style={{ color: colors.GOLD_COLOR, textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}
    className='cf-avinode-search'
    data-testId = 'redirect_back'
    onClick={this.handleJobTitleNavigation}
  >
  <ArrowBack className = 'cf_avinod_arrow_search'/>
    {configJSON.head}
  </GoldishSimpleButton>
  {this.state.jobTItleData.length !== 0 && this.renderAddJobTItleButton()}
    </Box>
  }

  renderMainJobTitleConten=()=>{
    return <GeneralParentBox style={{...webStyle.mainCont,padding:"1.5rem"}}>
      {this.renderViewJobTItleCOntainer()}
    </GeneralParentBox>
  }

  renderAddJobTItleButton=()=>{
    const {jobTItleData} = this.state;
    const padding = jobTItleData.length === 0 ? '4rem' :'2rem';
    const background = jobTItleData.length === 0 ? 'black' : '#1A1C1E'
    return <AddLegtTypeButton style={{padding:`1rem ${padding}`,backgroundColor:background}} onClick={()=>this.handleJobTItleOpener(true)}>+ Add New Job</AddLegtTypeButton>
  }


  renderJobTItleModal = ()=>{
    const {isJobTItleModalOpen,isJobTItleModalOpenForEdit,editJobTItleData} = this.state;
    const head = isJobTItleModalOpenForEdit ? 'Edit Job Title' : 'Add New Job';
    const initial_values = !isJobTItleModalOpenForEdit ? '' : editJobTItleData.attributes.job_title

    return <MuiModal
        open = {isJobTItleModalOpen}
        onClose={() => {this.handleJobTItleOpener(false)}}
    >
          <StyledModalContent>
               <ModalHeading marginBottom={'1rem'}>{head}</ModalHeading>
               <Box>        
                    <Formik
                        initialValues={{jobTitle:initial_values}}
                        onSubmit={(values) => {
                          this.handleAddJobTItleAddorEdit(values);
                        }}
                        validationSchema={jobTItleValidationSchema}
                        validateOnChange
                        validateOnBlur
                        enableReinitialize 
                    >
                        {({
                            handleSubmit,
                            errors,
                            // setFieldValue,
                            values,
                            handleChange,
                            touched
                        }) => (<>
                            <CustomFormTextField
                            autoComplete="off"
                            placeholder="Enter Title"
                            size="medium"
                            fullWidth
                            name="jobTitle"
                            value={values.jobTitle}
                            onChange={handleChange}
                            id="profile_test_full_name"
                            className={touched.jobTitle && errors.jobTitle ? "register_border_error" : ""}
                            />
                            {touched.jobTitle && errors.jobTitle && <Box className="register_valid_error" position="absolute">
                                {errors.jobTitle}
                            </Box>}
                            <Box className="booking_section" marginTop={'1.75rem'}>
                            <FormSubmitButton fullWidth className="profile_test_submit" onClick={()=>handleSubmit()}>Save</FormSubmitButton>
                            <AddLegtTypeButton style={{color:colors.GOLD_COLOR}}  fullWidth onClick={()=>this.handleJobTItleOpener(false)}>Cancel</AddLegtTypeButton>
                            </Box>
                        </>)}
                    </Formik>       
                    
               </Box>
          </StyledModalContent>  
    </MuiModal>
}

  renderViewJobTItleCOntainer=()=>{
    const {jobTItleData} = this.state;
    return  <>
      {jobTItleData.length === 0 ? this.renderAddJobTItleButton() : <Box style={webStyle.viewJobCont} className="info_system_parent">
        {jobTItleData.map((jobData:JobDataType,index:number)=>  this.renderEachJobTItle(jobData,index))}
        </Box>}
    
    </>
    
  }

  renderEachJobTItle=(jobData:JobDataType, titleIndex:number)=>{
    const {attributes:{job_title}} = jobData;
    return <>
    {titleIndex !== 0 && <HorizantalRow/>}
    <Box className="eachJobTitleContainer" display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{columnGap:'0.8rem'}}>
        <Box style={{wordBreak:'break-all',fontSize:'1.5rem'}}>{job_title}</Box>
        <Box style={{...webStyle.mainCont,columnGap:'1rem'}}>
            <DeleteOutline style={{color: "#DC2626"}} className="delete_job" onClick={()=>this.deleteJobTItle(jobData.id)}/>
            <Edit className="edit_job" style={{color: colors.GOLD_COLOR}} onClick={()=>this.handleJobTItleOpener(true,true,jobData as unknown as null)}/>
        </Box>
    </Box>
    </>
  }


  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
       <Box className="booking_section system_setting_parent">
        {this.renderJobTItleHeading()}
        {this.renderMainJobTitleConten()}
        {this.renderJobTItleModal()}
       </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start

type CSSPropertiesMap = {

  [key: string]: React.CSSProperties;
};
const webStyle:CSSPropertiesMap = {
  mainCont:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  viewJobCont:{
    flexDirection:"column",
    padding:'1.5rem',
    fontWeight:'bold',
    borderRadius:"1rem",
    backgroundColor:'#2D3034',
    rowGap:"1.2rem",
    display:'flex',
    width:'100%'
  },
};
// Customizable Area End
