import React from "react";
// Customizable Area Start
import {
  Box,
  Grid
} from "@material-ui/core";
import { createTheme} from "@material-ui/core/styles";
import {CustomTypography,Footer, CustomDashboardButton, FormSubmitButton, CustomLabel, CustomFormTextField, HorizantalRow} from "../../../components/src/MuiCustomComponents"
import {webStyles}  from "./FAQ.web";
import "./Contactus.css";
import {Formik, FormikProps} from "formik";
import PhoneInput from "react-phone-input-2";

interface  InitType{
  call_country_code: string,
  text_country_code: string,
  phone_number: string,
  whatsapp: string,
  call_label: string,
  text_label: string,
  text_us_number:string,
  call_us_number:string
}

// Customizable Area End

import ContactusController, { Props } from "./ContactusController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  renderContactUsSection=()=>{
    const {isEditState} = this.state;
    return <Box className="booking_section">
      <CustomTypography style={{color:"white"}}>Contact Us</CustomTypography>
      {!isEditState ? <Box>{this.renderTextAndCallUsContainer()}</Box> : this.renderCallTextUsFormsContainer()}
    </Box>
  }


  renderCallTextUsFormsContainer=()=>{
    const {intitialValues} = this.state
    return  <Formik
        initialValues={intitialValues}
        onSubmit={(values) => {
          this.handleFormSubmit(values);
        }}
        innerRef={(formikProps) => {
          this.formikMethods = formikProps
        }}
        validateOnChange
        validateOnBlur
        enableReinitialize
      >
        {(formikProps:FormikProps<InitType>) => (<Box className="contact_us_form_parent">
      <Grid container spacing={4} style={{padding:'0.8rem'}}>
        <Grid item lg={6} md={12}>
          {this.renderCallUsAndTextForm(true,formikProps)}
        </Grid>
        <Grid item lg={6} md={12}>
        {this.renderCallUsAndTextForm(false,formikProps)}
        </Grid>
      </Grid>
    </Box>)}</Formik>
    
  
  }


  renderCallUsAndTextForm = (isCallUs: boolean,formikProps: FormikProps<InitType>) => {
    const { values, handleChange, setFieldValue, errors, touched } = formikProps
    const heading = isCallUs ? "Call" : "Text"
    const labelKey = isCallUs ? "call_label" : "text_label"
    const phoneNumberMainKey = isCallUs ? "call_us_number" : "text_us_number"
    const phoneNumberKey = isCallUs ? "phone_number" : "whatsapp"
    const countryCodeKey = isCallUs ? "call_country_code" : "text_country_code"
    const {focused} = this.state;
  
    return (
      <Box className='booking_section' style={{rowGap:'1.8rem'}}>
        <Box>
          <CustomLabel style={{ margin: "0", marginBottom: "5px" }} isFocused = {focused ===  phoneNumberMainKey}>
            Enter {heading} Number
          </CustomLabel>
          <PhoneInput
            enableSearch={true}
            searchClass="search_country_code"
            inputClass={`regsiter_phone_input`}
            dropdownClass='register_drop_down_class'
            searchNotFound="No Options"
            containerClass='regsiter_contaitner_class'
            onFocus={()=>{this.handleFocusedLabel(phoneNumberMainKey)}}
            onBlur={()=>{this.handleFocusedLabel('')}}
            searchPlaceholder="Search"
            dropdownStyle={{ zIndex: 2, marginTop: "-10px" }}
            containerStyle={{ zIndex: 4, position: "relative" }}
            country='us'
            value={values[phoneNumberMainKey]}
            inputStyle={
              touched[phoneNumberKey] && errors[phoneNumberKey]
                ? { border: "1px solid #F87171" }
                : {}
            }
            onChange={(
              value,
              data: { dialCode: string },
            ) => {
              const countryCode = data.dialCode
              const phoneNumber =
                countryCode && value.substring(countryCode.length)
              setFieldValue(phoneNumberMainKey, value)
              setFieldValue(phoneNumberKey, phoneNumber)
              setFieldValue(countryCodeKey, countryCode)
            }}
            placeholder='Your Phone Number'
          />
          {touched[phoneNumberKey] && errors[phoneNumberKey] && (
            <Box className='register_valid_error' position={'absolute'}>
              {errors[phoneNumberKey]}
            </Box>
          )}
        </Box>
  
        <Box>
          <CustomLabel style={{ margin: "0", marginBottom: "5px" }} isFocused = {focused ===  labelKey} >
            Enter Button Label
          </CustomLabel>
          <CustomFormTextField
            id="label_text_field"
            variant="outlined"
            fullWidth
            isFocusedTypeInput={true}
            initialBorderColor={'#CACACA'}
            borderType={'0.5px solid'}
            placeholder='Type Label'
            value={values[labelKey]}
            onFocus={()=>{this.handleFocusedLabel(labelKey)}}
            onBlur={()=>{this.handleFocusedLabel('')}}
            name={labelKey}
            onChange={handleChange}
            autoComplete="off"
            className={
              touched[labelKey] && errors[labelKey] ? "register_border_error" : ""
            }
          />
          {touched[labelKey] && errors[labelKey] && (
            <Box className='register_valid_error' position={'absolute'}>
              {errors[labelKey]}
            </Box>
          )}
        </Box>
      </Box>
    )
  }
  

  renderTextAndCallUsContainer=()=>{
    return <Grid container  spacing={2}>
    <Grid item md = {6} sm={12}>
      {this.renderCallUsORTextUs('phone_number')}
    </Grid>
    <Grid item md = {6} sm={12}>
      {this.renderCallUsORTextUs('whatsapp')}
    </Grid>
  </Grid>
  }


  renderCallUsORTextUs=(callOrContact:'phone_number' | 'whatsapp')=>{
    const isCallUs = callOrContact === 'phone_number';
    const text = isCallUs  ? 'Call Number' : 'Text Number';
    const {intitialValues} = this.state;
    const {phone_number, whatsapp, call_country_code, text_country_code,call_label,text_label} = intitialValues
    const buttonLabel = isCallUs ? call_label : text_label

    return <Box style={{...webStyles.generalBoxStyles,display:'flex',flexDirection:'column',rowGap:"1rem"}}>
    {this.renderDataDisplayRow(text,isCallUs ? `${call_country_code} ${phone_number.slice(0, 3)}-${phone_number.slice(3, 6)}-${phone_number.slice(6, 10)}` : `${text_country_code} ${whatsapp.slice(0, 3)}-${whatsapp.slice(3, 6)}-${whatsapp.slice(6, 10)}`)}
      <HorizantalRow/>
    {this.renderDataDisplayRow('Button Label',buttonLabel)}
    </Box>
  }

  renderDataDisplayRow=(text:string,numberToDisplay:string)=>{
   return  <Box style={{...webStyles.goldTextAndSize,color:'#CBD5E1'}}>
      <Box>
        <Box>{text}</Box>
      </Box>
      <Box textAlign={'right'} width={'70%'} style={{wordBreak:'break-all'}}>
        <Box className="contact_us_display_number" style={{fontWeight:'bold',color:'white'}}>{numberToDisplay}</Box>
      </Box>
   </Box>
  }

  renderContactUsFooter=()=>{
    const {isEditState} = this.state;
    return <Footer>
      {!isEditState ? this.renderEditButtonContainer() : this.renderCancelAndSubmit()}
    </Footer>
  } 

  renderCancelAndSubmit=()=>{
    return <Box className="profile_submission">
    <FormSubmitButton style={{color:"white",background:"#3E4247"}} onClick={()=>{this.handleEditor(false)}} fullWidth>Cancel</FormSubmitButton>
    <FormSubmitButton fullWidth className="profile_test_submit save_changes" onClick={()=>{ this.formikMethods?.handleSubmit()}}>Save Changes</FormSubmitButton>
    </Box>
}

  renderEditButtonContainer=()=>{
    return <Box className = 'footer_global'>
    <CustomDashboardButton size="large" variant="outlined" className="edit_terms_policy edit_contact_details_button" onClick={()=>this.handleEditor(true)}>
        Edit Contact Details
    </CustomDashboardButton>
    </Box>
  }


  // Customizable Area End

  render() {
    return (
// Customizable Area Start
<Box className="contact_us_parent_section" style={{paddingBottom:'15rem'}}>
{this.renderContactUsSection()}
{this.renderContactUsFooter()}
</Box>
// Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
