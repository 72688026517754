import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { handleNavigation, handleTriggerAlertMessage } from "../../../components/src/Navigator";
import { FormikProps } from "formik";
import { getStorageData } from "../../../framework/src/Utilities";


interface DesciptionResType{
  data: {
    id: string,
    type: string,
    attributes: {
      sky_card_name: string,
      about: string
    }
  }
}

export interface ValueType{
  title : null | string,
  description : null | string
}

interface MetaMessageType{
  meta: {
    message: string
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  focusLabel:null | string
  isViewState : boolean
  init_value:ValueType
  desc_type : null | string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfdebitskycard3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  descFormik: null | FormikProps<unknown> = null;
  getCardDescriptionApiId:string='';
  updateDescriptionApiId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      focusLabel:null,
      isViewState:true,
      init_value:{
        title : null,
        description : null
      },
      desc_type :null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson && !responseJson.errors){
        if(apiRequestCallId === this.getCardDescriptionApiId){
          this.handleGetDescription(responseJson);
        }
        else if(apiRequestCallId === this.updateDescriptionApiId){
          this.handleUpdateDesciption(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.handleDesciptionType();
  }

  handleDesciptionType=()=>{
    const {location : {pathname}} = window;
    let desc_type = null;

    if(pathname === '/DebitskycardDescription') desc_type = 'debit';
    else if (pathname === '/Cf25skycardDescription') desc_type = '25hour';
    else desc_type = 'custom';
    this.setState({desc_type});
    this.getCardDescription(desc_type);
  }


  handleLableFocus=(focusLabel:string | null)=>{
    this.setState({focusLabel})
  }

  handleViewEditState=(isViewState:boolean)=>{
    this.setState({isViewState})
  }

  handleNavigationDescription=()=>{
    const {desc_type} = this.state;
    const redirectTo = desc_type === '25hour' ?'/Manage25hourskycard' : '/Managedebitskycard3'
    handleNavigation(redirectTo,this.props,this.send);
  }


  getCardDescription=async(desc_type = this.state.desc_type)=>{
    const api_endPoint = this.handleEndPoint(desc_type as string,configJSON.get25HrDescriptionEndPoint,configJSON.getDebitCardDescriptionEndPoint,configJSON.getCustomCardDescriptionEndPoint);
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
     this.getCardDescriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      api_endPoint
    );

     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEndPoint=(desc_type:string,hour25:string,debit:string,custom:string)=>{
    switch (desc_type) {
      case '25hour':
      return hour25;

      case 'debit':
      return debit;

      default:
      return custom;
    }
  }

  updateDescription=async(formData:ValueType)=>{
    const {desc_type} = this.state;
    const method = desc_type === '25hour' ? configJSON.updateMethod : configJSON.patchUpdateMethod
    const handleData = this.handleDescriptionData(formData);
    const headers = {
      'token' : await getStorageData('token'),
      'Content-type' : configJSON.exampleApiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDescriptionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.handleEndPoint(desc_type as string,configJSON.update25DesciptionEndPoint,configJSON.updateDebitDescriptionEndPoint,configJSON.updateCustomDescriptionEndPoint)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(handleData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  handleDescriptionData=(formData:ValueType)=>{
    const {description,title} = formData;
    return {
        data : {
          sky_card_name: title,
          about: description
        }
    }
  }

  handleGetDescription=(responseJson : DesciptionResType)=>{
    if(responseJson.data){
      const {attributes:{about, sky_card_name}} = responseJson.data;
      this.setState({init_value : {description:about,title:sky_card_name}});
    }
  }

  handleUpdateDesciption=(responseJson:MetaMessageType & DesciptionResType)=>{
      if(responseJson.meta.message){
        const {attributes:{about,sky_card_name}} = responseJson.data;
        handleTriggerAlertMessage(this.send,responseJson.meta.message);
        this.setState({isViewState:true,init_value:{description:about ,title:sky_card_name}});
      }
  }

  handleDesError=(touched:boolean,error:boolean)=>{
    return touched && error
  }

  handleCondition=(condition:boolean,output1:string,output2:string)=>{
    return condition ? output1 : output2;
  }
  // Customizable Area End
}
