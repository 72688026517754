import React from "react";
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
const NoResultsFound = () => <svg width="123" height="122" viewBox="0 0 123 122" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M77.6061 73.157H81.5997L103.034 94.6923C105.107 96.7649 105.107 100.152 103.034 102.225C100.961 104.297 97.5742 104.297 95.5016 102.225L74.0169 80.7399V76.7462L72.652 75.3308C65.5747 81.3971 55.9192 84.5313 45.6571 82.8125C31.6036 80.4366 20.381 68.7084 18.6622 54.5538C16.0335 33.1702 34.0301 15.1736 55.4137 17.8023C69.5683 19.5211 81.2964 30.7437 83.6724 44.7972C85.3912 55.0593 82.2569 64.7148 76.1906 71.7921L77.6061 73.157ZM28.5216 50.4084C28.5216 62.996 38.6826 73.157 51.2702 73.157C63.8577 73.157 74.0187 62.996 74.0187 50.4084C74.0187 37.8209 63.8577 27.6599 51.2702 27.6599C38.6826 27.6599 28.5216 37.8209 28.5216 50.4084Z" fill="url(#paint0_linear_6946_20707)" />
    <path d="M39.1709 54.6487C39.1644 54.3086 39.1869 54.1278 39.1538 53.9578C38.9495 52.9094 39.3037 52.1243 40.2565 51.6333C41.5149 50.984 48.8968 44.8204 49.9021 43.9757C50.0763 43.8295 50.1071 43.4469 50.107 43.173C50.1079 41.7275 50.0348 40.2802 50.08 38.8371C50.098 38.2701 50.2803 37.6741 50.5269 37.1581C50.8329 36.5203 51.4479 36.4984 51.731 37.14C52.0437 37.8517 52.2435 38.6458 52.3161 39.4204C52.4257 40.5925 52.4273 41.7803 52.3873 42.9588C52.3642 43.6354 52.6089 44.036 53.1615 44.3986C54.0438 44.9771 61.176 50.6855 62.5164 51.3111C63.8099 51.9146 63.746 53.0014 63.5868 54.2305C60.0224 52.6735 56.4517 51.2303 52.5139 50.6108C52.4477 52.4547 52.5178 54.2034 52.2863 55.9118C52.061 57.5813 52.5976 58.6551 54.0989 59.3325C54.3112 59.4281 54.4747 59.6347 54.6889 59.7232C55.8094 60.1847 55.9586 61.0608 55.7333 62.2042C54.9149 61.9908 54.0893 61.8072 53.2857 61.5547C52.6408 61.3525 52.0405 61.1743 51.559 61.8373C51.0558 61.2098 50.471 61.3742 49.8341 61.6064C49.0661 61.886 48.2712 62.0914 47.4876 62.329C47.1067 60.9326 47.1635 60.7593 48.2165 59.9593C48.2871 59.9059 48.3551 59.8492 48.4245 59.7937C49.1855 59.1919 50.271 58.7321 50.6152 57.9487C50.9607 57.1605 50.5672 56.05 50.4983 55.0809C50.3975 53.6434 50.2977 52.2053 50.1886 50.6476C46.3054 51.4085 42.7936 52.9785 39.1709 54.6487Z" fill="#FFD485" />
    <defs>
        <linearGradient id="paint0_linear_6946_20707" x1="19.6374" y1="26.3656" x2="114.537" y2="41.5337" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFE3AE" />
            <stop offset="1" stop-color="#FFD07A" />
        </linearGradient>
    </defs>
</svg>;


export { NoResultsFound }
