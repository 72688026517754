import React, { ChangeEvent } from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid, styled, TableContainer, Table, TableCell, TableHead, TableRow, FormControl, MenuItem,Avatar, TextFieldProps, PopperProps
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { backarrow, edit_icon, email_icon, Slider, vector,checkboxIcon,deleteIcon,masterCard,UploadIcon2, BookingsData, PassengerData,confirmIcon,formikCobmineTypeType, PassengerFormValues, CardDetails,setFieldValueType, setFieldErrorType } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import moment from 'moment';
import { Formik, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import { ICountry, IState, ICity } from 'country-state-city';
import { CustomFormSelect, CustomDepartueAiportAutoCompplete, CustomStyledPopper, CustomFormTextField, FormSubmitButton, UserDetailsLabel, 
  CustomLabel, Loader, MuiModal,renderOptionFrom, CustomTextField, UserDetailsBox, CustomModalContent, StyledModalContent, ModalHeading,
  SurityModal,
  SureModalButtonContainer,MenuItemForSelect,} from "../../../components/src/MuiCustomComponents";
import { SearchOutlined, KeyboardArrowDown, PhotoCameraOutlined, RoomOutlined, BorderColorOutlined } from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
import { v4 } from "uuid";
import {AddPassengerModal,AddPaymentMethodModal} from "../../../components/src/ReUsableModal"



type CombineLocationType = ICountry | IState | ICity

type PreferredAirport = {
  id:string,
  name:string,
  city:string,
  code:string,
  countryCode:string,
}
interface CustomPopperProps {
  highIndex: number;
  left: number;
  marginTop: string;
}


const profileValidations = Yup.object().shape({
  phone_number: Yup.string().required('Phone number is required*'),
  full_name: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .matches(/^[a-zA-Z\s'-]+$/, "Invalid Full Name*")
    .min(1, 'Name cannot be empty*')
    .required('Full name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    preferredAirport: Yup.object().shape({
      name: Yup.string()
        .required("Preffered Airport is required*")
        .min(1, "Preffered Airport can't be empty")
    })
    .nullable()
    .required('Preffered Airport is required*')
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const ClientDetailsHeader = styled(Box)({
  "& .dFlex": {
    display: "flex",
    alignItems: "center"
  },
  "& .client-details": {
    color: "#FFFFFF",
    margin: "0 0 0 10px",
    fontSize: "20px",
    fontWeight:600
  },
  marginBottom: "20px"
});

const ClientDetailsContainer = styled(Box)({
  background: "#0D1015",
  minHeight: "100vh",
  padding: "10px",
  borderRadius: "8px"
});

;

const DFlexBetween = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

const BackgroundWrapper = styled(Box)({
  background: "#1A1C1E",
  borderRadius: "8px",
  padding: "10px 20px",
  marginBottom: "15px"
});

const CommonBox = styled(Box)({
  "& .heading": {
    fontSize: "16px",
    color: "#FFFFFF",
    margin: 0,
    fontWeight: 600
  },
  "& .view-btn": {
    background: "linear-gradient(180deg, #FFD078 0%, #FFE3AF 100%)",
    textAlign: "center",
    color: "#000",
    borderRadius: "5px",
    padding: "8px 20px",
    fontWeight: 600,
    fontSize: "16px",
    cursor: "pointer",
    border: "none"
  }
});

const DFlexAlignCenter = styled(Box)({
  display: "flex",
  alignItems: "center",
  "& .mr10": {
    marginRight: "10px"
  },
  "& .pointer": {
    cursor: "pointer"
  }
});

const ButtonBox = styled(Box)({
  background: "transparent",
  width: "100%",
  textAlign: "center",
  color: "#FFD27E",
  border: "1px solid #FFD27E",
  borderRadius: "5px",
  padding: "12px 0",
  fontWeight: 600,
  fontSize: "16px",
  cursor: "pointer"
});

const DFlexAround = styled(Box)({
  display:"flex",
  justifyContent:"center",
  alignItems:"space-around",
})

const PaymentCard = styled(Box)({
  background: "#3E4247",
  padding: "15px 15px",
  cursor:"pointer",
  "& .div-img": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "44px",
    width: "44px",    
    marginBottom: "10px"
  },
  "& .card-btns":{
    display:"flex",
    alignItems:"center",
    gap:"0.5rem",
  },
  "& .card-btn":{
    fontSize:"0.6rem",
    color:"rgba(52, 211, 153, 1)",
    border:"1px solid rgba(52, 211, 153, 1)",
    fontWeight:700,
    padding:"0.2rem 1rem",
    background:"inherit",
    borderRadius:"0.2rem",
    height:"1.4rem",
  },
  "& .card-name": {
    fontSize: "20px",
    margin: "6px 0 5px 0",
    color: "#fff",
    fontWeight:400,
  },
  "& .card-info": {
    color: '#94A3B8',
    fontSize: "16px",
    fontWeight: 800,
    margin: 0,
    "& span": {
      color: "#FFB833"
    }
  },
  borderRadius: "8px"
});

const StyledProfileBox =styled(Box)({
  display:"flex",
    alignItems:"center",
    justifyContent:"center",
    flexDirection:"column",
  "& .center_column":{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    flexDirection:"column",    
  },

  "& .profile_pic":{
    width:"9rem",
    height:"9rem",
    borderRadius:"50%",
    border:"2px solid #FFD17A",
    cursor:"pointer",
    position:"relative",
    background:"#3E4247"
  }

})

const GridContainer = styled(Grid)({
  marginTop: '-3rem',  // Counteract the vertical spacing
  // marginBottom: '-3rem',
});

const GridItem = styled(Grid)({
  paddingTop: '0px',  // Remove vertical padding
  paddingBottom: '0px',
});

// Customizable Area End

import Adminconsole2Controller, {
  Props,
  configJSON,
} from "./Adminconsole2Controller";
import { emailValidationSchema } from "../../../components/src/Validations";

export default class Adminconsole2 extends Adminconsole2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  
  renderUserDetails = () => {
    const { profileData, accountId, totalSpend,totalBookings,status,profilePicture } = this.state
  
    return (
      <UserDetailsBox status={status}>
        <div className="flex-center">
          {profileData.photo ? <img src={profileData.photo instanceof Blob ? URL.createObjectURL(profileData.photo) : profileData.photo} alt="" className="w-100" /> :
          <Avatar className="w-100"></Avatar>}         
        </div>
        <div className="innerbox">
          <div className="text-center flex-center flex-column">
            <h6 className="client-name">{profileData.full_name}</h6>
            <span className="account-id">Account ID: {accountId}</span>
            <div className="status-box">
              <button className="status-button"></button>
              <button className="status">{this.getAccountStatus(status)}</button>
            </div>
          </div>
          <div className="update-email-box" onClick={()=>this.handleUpdateEmailModal(true)}>
            <div className="d-flexBetween">
              <div className="d-flex">
                <div><img src={email_icon} alt="" /></div>
                <div className="ml8" data-test-id="updateEmailId">
                  <span className="update-email">Update Email</span>
                  <p className="email-id">{profileData.email}</p>
                </div>
              </div>
              <div><img src={Slider} alt="" /></div>
            </div>
          </div>
          <div className="personal-info-box">
            <p className="heading">Personal Info.</p>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">Phone Number</span>
                <p className="right-text">+{profileData.phone_number}</p>
              </div>
            </div>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">Preferred Departure Airport</span>
                <p className="right-text">{profileData.preferred_departure_airport}</p>
              </div>
            </div>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">Company Name</span>
                <p className="right-text">{this.getFieldvalue(profileData.companyName)}</p>
              </div>
            </div>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">Street Address</span>
                <p className="right-text">{this.getFieldvalue(profileData.street_address)}</p>
              </div>
            </div>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">City</span>
                <p className="right-text">{this.getFieldvalue(profileData.city)}</p>
              </div>
            </div>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">State</span>
                <p className="right-text">{this.getFieldvalue(profileData.state)}</p>
              </div>
            </div>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">Zip Code</span>
                <p className="right-text">{this.getFieldvalue(profileData.zip_code)}</p>
              </div>
            </div>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">Main Country of Residence </span>
                <p className="right-text">{this.getFieldvalue(profileData.main_country_of_residence)}</p>
              </div>
            </div>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">Dietary Requirements  </span>
                <p className="right-text">{this.getFieldvalue(profileData.dietary_requirements)}</p>
              </div>
            </div>
            <div className="user-details-box">
              <div className="d-flexBetween">
                <span className="left-text">Total Bookings</span>
                <p className="right-text">{this.getFieldvalue(String(totalBookings))}</p>
              </div>
            </div>
            <div className="mb15">
              <div className="d-flexBetween">
                <span className="left-text">Total Spend</span>
                <p className="right-text">${this.getFieldvalue(String(totalSpend))}</p>
              </div>
            </div>
            {status === "activated" &&(
              <>
              <div className="mb15">
              <button className="btn-div" data-test-id="edit" onClick={this.openEditPersonalInfoModal}>Edit Personal Information</button>
            </div>
            <div className="mb15">
              <button className="btn-div" onClick={this.handleResetPassword} data-test-id="resetPassword">Reset Password</button>
            </div>
            <div className="border-div">
              <button className="btn-div red-button"  onClick={()=>this.handleDeactivateAccountModal("open")} data-test-id="deactivateAccount">Deactivate Account</button>
            </div>
              </>
            )}
          </div>
        </div>
      </UserDetailsBox>
    )
  }

  CommonHeaderRow = (TableHeadData: string[]) => {
    return <TableHead>
      <TableRow className={"dash_table_head_client"} style={{ height: 'auto' }}>
        {TableHeadData.map((elem: string, index: number) => {
          return <TableCell style={{ borderRadius: '0',color:"#94A3B8",background:"#3e4247" }} key={index}>{elem}</TableCell>
        })}
      </TableRow>
    </TableHead>
  }

  UpcomingsBookingsTable = () => {
    let TableHeadData = ["Booking No.", "Departure Date","Trip Type", "Amount", "Vendor", "Payment Status", "Vendor Status"];
    const { upComingBookingData } = this.state
    return (
      <>
        <BackgroundWrapper>
          <CommonBox>
            <DFlexBetween><h6 className="heading">Upcoming Bookings</h6>
              <button className="view-btn" onClick={()=>this.handleNavigations("upcomingBookings")} data-test-id="viewAllUpcomingBookings">View All</button>
            </DFlexBetween>
          </CommonBox>
          <TableContainer className="dash_table_flights" style={{ marginTop: '1rem' }}>
            <Table style={{ borderCollapse: "separate" }}>

              {this.CommonHeaderRow(TableHeadData)}

              <Box height={"15px"} />

              {
              upComingBookingData.length >0?upComingBookingData.map((booking: BookingsData) => {
               return  this.renderBookings(booking)

              }):this.NoRecordFoundRow("upcoming booking")}
            </Table>
          </TableContainer>
        </BackgroundWrapper>
      </>
    )
  }

  PreviousBookingsTable = () => {
    let TableHeadData = ["Booking No.", "Departure Date","Trip Type", "Amount", "Vendor", "Payment Status", "Vendor Status"]
    const { previousBookingsData } = this.state
    return (
      <>
        <BackgroundWrapper>
          <CommonBox>
            <DFlexBetween>

              <h6 className="heading">Previous Bookings</h6>

              <button className="view-btn" onClick={()=>this.handleNavigations("previousBookings")}>View All</button>
            </DFlexBetween>
          </CommonBox>

          <TableContainer className="dash_table_flights" style={{ marginTop: '1rem' }}>

            <Table style={{ borderCollapse: "separate" }}>

              {this.CommonHeaderRow(TableHeadData)}

              <Box height={"15px"} />

              {
              previousBookingsData.length > 0 ? previousBookingsData.map((booking: BookingsData) => {
               return this.renderBookings(booking)
                  
                
              }):this.NoRecordFoundRow("previous booking")}
            </Table>
          </TableContainer>
        </BackgroundWrapper>
      </>
    )
  }

  PassengerInfoTable = () => {
    let TableHeadData = ["Name", "Passport No.", "Country of Issue", "Picture of Passport", ""];
    const { passengerDetails,action,passengerDataIndex,status} = this.state
    return (
      <>
        <BackgroundWrapper>
          <CommonBox>
            <DFlexBetween>
              <h6 className="heading">Saved Passengers</h6>
            </DFlexBetween>
          </CommonBox>
          <TableContainer className="dash_table_flights" style={{ marginTop: '1rem' }}>
            <Table style={{ borderCollapse: "separate" }}>
              {this.CommonHeaderRow(TableHeadData)}
              <Box height={"15px"} />
              {
              passengerDetails?.length>0?passengerDetails.map((passenger: PassengerData,passengerNumber:number) => {
                return <><TableRow className="dash_table_row_client order_details_row dash_table_row" data-testid="redirect_booking" onClick={()=>this.openPassengerModal("show",passengerNumber,passenger.attributes.id)}>
                  <TableCell className="dash_rounded-row-first-cell">{passenger.attributes.full_name}</TableCell>
                  <TableCell>{passenger.attributes.passport_number}</TableCell>
                  <TableCell>{passenger.attributes.country_of_issue}</TableCell>
                  <TableCell><DFlexAlignCenter><img src={vector} alt="" className="mr10" /> {this.getFileName(passenger.attributes.passport_image?.name)}</DFlexAlignCenter></TableCell>
                  <TableCell style={{ borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }}>
                    <DFlexAlignCenter gridGap={"1rem"}>
                    <img src={deleteIcon} alt="" className="pointer" onClick={(event:React.MouseEvent<HTMLImageElement,MouseEvent>)=>{event.stopPropagation()
                      this.handleSureModalOpen(true,true,passenger.attributes.id as unknown as null)
                      }} data-test-id={`deletePassenger${passenger.id}`} id="deleteButton" />
                    <img src={edit_icon} onClick={(event:React.MouseEvent<HTMLImageElement,MouseEvent>)=>{event.stopPropagation()
                      this.openPassengerModal("edit",passengerNumber,passenger.attributes.id)}} id="editButton" alt="" className="pointer"/>           
                    </DFlexAlignCenter>
                     </TableCell>
                </TableRow>
                  <Box height={"10px"} />
                </>
              }):this.NoRecordFoundRow("passengers")}
            </Table>
          </TableContainer>
          <AddPassengerModal 
          isOpen={this.state.showPassengerModal} 
          cancel={this.closePassengerModal}
          submit={(values:PassengerFormValues)=>this.handlePassengerInfo(values)}          
          focusField = {this.handleFocusedField}
          focusedField={this.state.focusedField}
          section={"client"} 
          actionType={this.state.action}
          {...(action !== "add" && passengerDataIndex >=0 ?{initialValues:this.getPassengerData()}:{})}
          uploadIcon={<UploadIcon2/>}
          passportRef ={this.passportFieldRef}
          editPassengerInfo = {this.openPassengerModal}
          deletePassenger={(passengerId:string)=>this.handleSureModalOpen(true,true,passengerId as unknown as null)}
          >
          {status === "activated" &&(
            <ButtonBox onClick={()=>this.openPassengerModal("add",0)} data-test-id="addPassenger">+ Add New Passenger</ButtonBox>
          )}
          </AddPassengerModal>
        </BackgroundWrapper>
      </>
    )
  }

  PaymentInfo = () => {
    const {action,showPaymentModal,focusedField,cardNumber,cards} = this.state    
    return (
      <>
        <BackgroundWrapper>
          <CommonBox>
            <DFlexBetween>
              <h6 className="heading" style={{ marginBottom: '10px' }}>Payment Methods</h6>
            </DFlexBetween>
          </CommonBox>
          <Grid container spacing={2}>
            {cards.length>0?cards.map((paymentCards: CardDetails, index: number) => {
              const {cards:{card_number,card_holder_name,card_type},primary_method,} = paymentCards.attributes
              return (
                <Grid item lg={4} key={index}>
                  <PaymentCard onClick={()=>this.openPaymentModal("show",index)} data-test-id={`paymentCard${index}`}>
                    <DFlexBetween>
                    <div className="card-btns">
                    <div className="div-img"><img  src={this.handleCardProvider(card_type)} alt="" height={"28px"} width={"44px"} /></div>
                    {primary_method && <button className="card-btn">Primary</button>}
                    </div>
                    <DFlexAlignCenter gridGap={"1rem"}>
                    <img src={deleteIcon} alt="" className="pointer delCardModal" onClick={(event:React.MouseEvent<HTMLImageElement>)=>{
                      event.stopPropagation()
                      this.handleSureModalOpen(true,false,paymentCards.id as unknown as null)
                    }}/>
                    <img src={edit_icon} alt="" className="pointer" onClick={(event:React.MouseEvent<HTMLImageElement>)=>{
                      event.stopPropagation()
                      this.openPaymentModal("edit",index)}}/>                                
                    </DFlexAlignCenter>
                    </DFlexBetween>                    
                                        
                    <p className="card-name">{card_holder_name}</p>
                    <p className="card-info"> **** **** **** {card_number}</p>
                  </PaymentCard>
                </Grid>
              )
            }):<Grid item sm={12} md={12} lg={12}>
            <Box width={"100%"} color={"white"} textAlign={"center"} mt={1} pt={1.5} mb={1} pb={1.5} style={{backgroundColor:"#1D1F21",border:"1px solid #36393D",fontSize:"0.9rem",fontWeight:"bolder",borderRadius:"0.7rem"}} data-test-id="card">No payment method found!</Box>
            </Grid>}
          </Grid>
          <ButtonBox mt={2} onClick={()=>this.openPaymentModal("add",-1)} data-test-id="addPaymentMethod">+ Add Payment Method</ButtonBox>
          <AddPaymentMethodModal
          createCard={this.handleCreateNewCard}
          {...(action !== "add" && cardNumber >= 0 ?{initialValues:this.getCardDetails()}:{})}
          actionType={action}
          showModal = {showPaymentModal}
          cancel={this.closePaymentModal}
          openPaymentModal = {this.openPaymentModal}
          makePrimaryPaymentMethod= {this.handleMakePrimaryMethod}
          focusedField={focusedField}
          focusField={this.handleFocusedField}
          deleteCard={()=>this.handleSureModalOpen(true,false,cards[this.state.cardNumber].id as unknown as null)}
          cardFormik={(formikProps: FormikProps<FormikValues>) => this.cardFormik = formikProps}>
          </AddPaymentMethodModal>
        </BackgroundWrapper>
      </>
    )
  }


  renderDeleteConfirmationModal=()=>{
    const {isSureModalOpen} = this.state;
    return  <SurityModal isOpen={isSureModalOpen}><SureModalButtonContainer submit={()=>{this.handleDeletePassengerAndCard()}} cancel={()=>this.handleSureModalOpen(false,false)}/></SurityModal>
  }
  
  ShowLoader = () => {
    return <Loader height="100vh">
      <Box className="cf_avinod_no_result_head loader_container" marginTop={'1rem'}>Please Wait</Box>

    </Loader>
  }

  NoRecordFoundRow = (recordType:string) => {
    const notificationMessage = recordType === "payment method" ? `No ${recordType} found!`:`No ${recordType} data found!`
    return <>
      <TableRow className="dash_table_row no_result_found">
        <TableCell className="dash_rounded-row-first-cell dash_rounded-row-last-cell" colSpan={8} style={{ textAlign: 'center' }}>
          {notificationMessage}
          </TableCell>
      </TableRow>
      <Box height={"14px"} />
    </>
  }
  
  renderProfileContainer = (props:FormikProps<formikCobmineTypeType>) => {
    const {values,setFieldValue,setFieldError} = props
    return (
      <StyledProfileBox mb={"1rem"}>
        <Box className="profile_pic center_column" onClick={()=>this.handleEditProfilePictureModal(true)}>
            {values.photo && this.renderAvatar(values.photo)}
            {this.renderProfileModal(values.photo,setFieldValue,setFieldError)}
            <Box position={"absolute"} top={"32%"} display={"flex"} alignItems={"center"} flexDirection={"column"} onClick={()=>this.inputFileRef.current?.click()}>
            <PhotoCameraOutlined style={{color:"#fff"}}/>
            <Box borderBottom={"1px solid #fff"} color={"#fff"}>New Picture</Box>
            </Box>
            <Box className="profile_pic_pen_icon">
                      <Box className="profile_pen_icon_cont">
                         <BorderColorOutlined style={{ fontSize: "15px" }}className="profile_pen_test"/>
                      </Box>
                </Box>
        </Box>
        <CustomLabel>Account ID: {this.state.profileData.account_id}</CustomLabel>
      </StyledProfileBox>
    )

  }
  renderSubmitAndCancel = (formikProps: FormikProps<formikCobmineTypeType>) => {
    const {handleSubmit} = formikProps
    return <UserDetailsBox mt={"1rem"} pt={1}>
      <FormSubmitButton 
      fullWidth 
      className="profile_test_submit" 
      onClick={()=>handleSubmit()}
      data-test-id="saveChanges">
        Save Changes
        </FormSubmitButton>
      <FormSubmitButton className="btn-div"
        fullWidth
        style={{ marginTop: "1.5rem" }}
        onClick={this.closeEditPersonalInfoModal}
        data-test-id="cancelEdit">
        Cancel
      </FormSubmitButton>
    </UserDetailsBox>
  }

  renderUserProfile = () => {
    const {city,state,main_country_of_residence,preferred_departure_airport} = this.state.profileData
    const initialValues = {
      optedState: {name:state},
      optedCity: {name:city},
      optedCountry: {name:main_country_of_residence},      
      ...this.state.profileData,
      preferredAirport:{name:preferred_departure_airport,city:"",code:""}
    }
    

    return (
      <Box style={{ borderRadius: "1rem", padding: "2rem 1rem", background: "#1A1C1E" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            this.editPersonalInfo(values)
          }}
          validationSchema={profileValidations}
          validateOnChange
          validateOnBlur
          enableReinitialize
          innerRef={(props)=>{
            this.formikForm = props as  FormikProps<unknown>
          }}          
          
        >
          {(props:FormikProps<formikCobmineTypeType> ) => (
            <form onSubmit={props.handleSubmit}>

              {this.renderProfileContainer(props)}
              <Typography style={{ fontSize: "1.3rem", fontWeight: 600, color: "#fff", marginBottom: "2.5rem" }}>Edit Personal Info.</Typography>
              {this.renderForm(props)}
              {this.renderSubmitAndCancel(props)}
            </form>
          )}
        </Formik>
      </Box>

    )
  }

  renderForm = (formikProps: FormikProps<formikCobmineTypeType>) => {
    return (
      <Box>
        <GridContainer container spacing={2}>
          {this.renderRow1(formikProps)}
          {this.renderRow2(formikProps)}
          {this.renderRow3(formikProps)}
          {this.renderRow4(formikProps)}
          {this.renderRow5(formikProps)}
        </GridContainer>
      </Box>
    )
  }

  renderRow1 = (formikProps: FormikProps<formikCobmineTypeType>) => {
    const {values,handleChange,touched,errors} = formikProps
    return (
      <>
        <GridItem item sm={12}>
          <UserDetailsLabel>Full Name</UserDetailsLabel>
          <CustomTextField
            isFocusedTypeInput={this.state.focusedField === "fullName"}
            autoComplete="off"
            value={values.full_name}
            placeholder="Your Name"
            size="medium"
            fullWidth
            name="full_name"
            onFocus={()=>this.handleFocusedField("fullName")}
            onBlur={()=>this.handleFocusedField(null)}
            height="3rem"
            fontSize="1rem"
            onChange = {handleChange}
            data-test-id = "fullName"
            showError={touched.full_name && errors.full_name}
            editInfo={true}
            actionType={"edit"}
          />
          {touched.full_name &&(
            <Box mt={1} color={"red"}>{errors.full_name}</Box>
          )}
        </GridItem>
      </>
    )
  }

  renderRow2 = (formikProps: FormikProps<formikCobmineTypeType>) => {
    const { values, touched, errors,setFieldValue } = formikProps;
    return (
      <>
        <GridItem item sm={12}>
          <UserDetailsLabel>Phone Number</UserDetailsLabel>
          <CommonBox
          onFocus={()=>this.handleFocusedField("phoneNumber")}
          onBlur={()=>this.handleFocusedField(null)}>                
          <PhoneInput           
            enableSearch={true}
            searchClass="search_country_code"
            searchPlaceholder="Search"
            onChange={(value, data: { dialCode: string }, event, formattedValue) => {
              const countryCode = data.dialCode; // This gives you '1' for 'United States'
              const phoneNumber = countryCode && value.substring(countryCode.length);
              formikProps.setFieldValue("phone_number", value);
              formikProps.setFieldValue("phoneNumber", phoneNumber);
              formikProps.setFieldValue("countryCode", countryCode);
            }}
            inputStyle={touched.phone_number && errors.phone_number ? 
            { border: "1px solid #F87171",height:"3.8rem" } :this.getInputStyle()}
            dropdownStyle={{width:document.querySelector(".regsiter_contaitner_class")?.clientWidth,backgroundColor:"#3E4247",borderTopRightRadius:"0",borderTopLeftRadius:"0px",marginTop:"0px"}}
            searchNotFound="No Options"
            dropdownClass={"phone_number_dropdown"}
            containerClass="regsiter_contaitner_class"
            inputClass={`regsiter_phone_input`}
            disableSearchIcon={false}
            country="us"
            value={values.phone_number}
            placeholder="Your Phone Number"
            searchStyle={{border:"none"}}
          />
          </CommonBox>
          {touched.phone_number &&(
            <Box marginTop={1} color={"red"}>{errors.phone_number}</Box>
          )}
        </GridItem>
        <GridItem item sm={12}>
          <UserDetailsLabel >Preffered Departure Airport</UserDetailsLabel>
          <CustomDepartueAiportAutoCompplete
            height={"3rem"}
            fontSize={"1rem"}
            showError = {errors.preferredAirport && touched.preferredAirport}
            isFocusedTypeInput= {this.state.focusedField === "preferredAirport"}
            onFocus={()=>this.handleFocusedField("preferredAirport")}
            onBlur={()=>this.handleFocusedField(null)}  
            options={this.state.searchedAirports}
            filterOptions = {(option:PreferredAirport)=>option}
            onChange={(event: Event, newValue: PreferredAirport) => {
            setFieldValue("preferredAirport", newValue)
          }
            }            
            ListboxProps={{ id: "regsiter_option_style" }}
            id="regsiter_option_style"
            PopperComponent={(props: PopperProps) => (
              <CustomStyledPopper highIndex={9999}{...props} left={0} marginTop={"-3px"}/>
            )}
            getOptionLabel={(option: PreferredAirport) => this.getOptionLabel({name:option.name,city:option.countryCode,code:option?.code})}
            renderOption={(option: PreferredAirport, { selected }: {selected:boolean}) => renderOptionFrom(option, selected)}
            value={values.preferredAirport}
            renderInput={(params: TextFieldProps) => (
              <CustomFormTextField
              onChange = {(event:ChangeEvent<HTMLInputElement>)=>this.handleAirportSearch(event.target.value)}
                {...params}
                placeholder="Search Departure Location"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Box className="profile_hover_icons">
                      <KeyboardArrowDown />
                    </Box>
                  ),
                  startAdornment: (
                    <Box>
                      <SearchOutlined />
                    </Box>
                  ),
                }}
              />
            )}
          />
          {touched.preferredAirport &&(
            <Box mt={1} color={"red"}>{errors.preferredAirport}</Box>
          )}
        </GridItem>
      </>
    )
  }

  renderRow3 = (formikProps: FormikProps<formikCobmineTypeType>) => {
    const { values,handleChange} = formikProps;
    return (
      <>
      <GridItem item sm={12}>
          <UserDetailsLabel>Company Name</UserDetailsLabel>
          <CustomTextField
            isFocusedTypeInput={this.state.focusedField === "companyName"}
            autoComplete="off"
            value={values.companyName}
            id="companyName"
            placeholder="Enter Company Name"
            size="medium"
            fullWidth
            name="companyName"
            onFocus={()=>this.handleFocusedField("companyName")}
            onBlur={()=>this.handleFocusedField(null)}
            height="3rem"
            fontSize="1rem"
            onChange = {handleChange}
            data-test-id = "companyName"
            editInfo={true}
            actionType={"edit"}
          />
        </GridItem>
        <GridItem item sm={12}>
          <UserDetailsLabel>Street Address</UserDetailsLabel>
          <CustomTextField
            height={"3rem"}
            fontSize="1rem"
            autoComplete="off"
            onFocus={()=>this.handleFocusedField("streetAddress")}
            onBlur={()=>this.handleFocusedField(null)}
            isFocusedTypeInput={this.state.focusedField === "streetAddress"}
            placeholder="Enter street address"
            size="medium"
            fullWidth
            name="street_address"
            value={values.street_address}
            onChange={handleChange}
            data-test-id="streetAddress"
            editInfo={true}
            actionType={"edit"}
          />
        </GridItem>
        <GridItem item sm={12}>
          <UserDetailsLabel>City</UserDetailsLabel>
          <CustomTextField
            isFocusedTypeInput={this.state.focusedField === "city"}
            autoComplete="off"
            value={values.city}
            placeholder="Enter City"
            size="medium"
            fullWidth
            name="city"
            onFocus={()=>this.handleFocusedField("city")}
            onBlur={()=>this.handleFocusedField(null)}
            height="3rem"
            fontSize="1rem"
            onChange = {handleChange}
            data-test-id = "city"
            editInfo={true}
            actionType={"edit"}
          />
        </GridItem>
      </>
    )
  }

  renderRow4 = (formikProps: FormikProps<formikCobmineTypeType>) => {
    const { states } = this.state;
    const { values, errors, touched,handleChange } = formikProps
    return (
      <>
        <GridItem style={{ zIndex: 1 }} item sm={12}>
          <UserDetailsLabel>State</UserDetailsLabel>
          <CustomTextField
            isFocusedTypeInput={this.state.focusedField === "state"}
            autoComplete="off"
            value={values.state}
            placeholder="Enter State"
            size="medium"
            fullWidth
            name="state"
            onFocus={()=>this.handleFocusedField("state")}
            onBlur={()=>this.handleFocusedField(null)}
            height="3rem"
            fontSize="1rem"
            onChange = {handleChange}
            editInfo={true}
            data-test-id = "state"
            actionType={"edit"}
                        
          />          
        </GridItem>
        <GridItem item sm={12}>
          <UserDetailsLabel>Zip Code</UserDetailsLabel>
          <CustomTextField
            height={"3rem"}
            fontSize="1rem"
            autoComplete="off"
            placeholder="Enter zip code"
            onFocus={()=>this.handleFocusedField("zipCode")}
            onBlur={()=>this.handleFocusedField(null)}
            isFocusedTypeInput={this.state.focusedField === "zipCode"}
            size="medium"
            fullWidth
            name="zip_code"
            className={touched.zip_code && errors.zip_code ? "register_border_error" : ""}
            value={values.zip_code}
            onChange={handleChange}
            data-test-id="zipCode"
            actionType={"edit"}
            editInfo={true}
          />
        </GridItem>
      </>
    )
  }

  renderRow5 = (formikProps: FormikProps<formikCobmineTypeType>) => {
    const { dietryData, countries } = this.state
    const { values } = formikProps
    return (
      <>
        <GridItem item sm={12}>
          <UserDetailsLabel>Main Country of Residence</UserDetailsLabel>
          <CustomDepartueAiportAutoCompplete
            height={"3.8rem"}
            fontSize={"1rem"}
            onFocus={()=>this.handleFocusedField("country")}
            onBlur={()=>this.handleFocusedField(null)}
            isFocusedTypeInput={this.state.focusedField === "country"}
            onChange={(country: Event, newValue: ICountry) => {
              formikProps.setFieldValue('optedCountry', newValue);
            }}
            id='profile_country_selector'
            PopperComponent={(props: PopperProps) => (
              <CustomStyledPopper
                {...props}
                zIndex={3}
                marginTop="1px"
              />
            )}
            value={values.optedCountry}
            ListboxProps={{ id: "regsiter_option_style" }}
            getOptionLabel={(option: ICountry) => option.name}
            options={countries}
            renderOption={(
              option: ICity | IState | ICountry,
              { selected }: { selected: boolean },
            ) => <>{this.renderStateCountryCityOption(option)}</>}
            renderInput={(params: TextFieldProps) => (
              <CustomTextField
                {...params}
                placeholder="Search Country"
                editInfo={true}
                actionType={"edit"}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Box className="profile_hover_icons">
                      {this.state.focusedField !== "country" && <KeyboardArrowDown/>}
                    
                    </Box>
                  ),
                  startAdornment: (
                    <Box>
                      <SearchOutlined />
                    </Box>
                  ),
                }}
              />
            )}
          />
        </GridItem>

        <GridItem item sm={12}>
          <UserDetailsLabel>Dietery Requirements</UserDetailsLabel>
          <FormControl placeholder="Please Verify" fullWidth>
            <CustomFormSelect
              height={"3.8rem"}
              fontSize={"1rem"}
              labelId="demo-simple-select-label"
              variant="outlined"
              onFocus={()=>this.handleFocusedField("diet")}
              onBlur={()=>this.handleFocusedField(null)}
              isFocusedTypeInput={this.state.focusedField === "diet"}
              className="profile_customSelect"
              name="dietary_requirements"
              id="dietary_requirements"
              value={values.dietary_requirements || 'Please Specify'}
              onChange={formikProps.handleChange}
              inputProps={{
                IconComponent: KeyboardArrowDown, // Your custom icon here
              }}
              MenuProps={{
                getContentAnchorEl: null,
                className: "advance_search_picker",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
            >
              <MenuItem
                style={{ display: "none" }}
                value={"Please Specify"}
              >
                Please Specify
              </MenuItem>
              {dietryData.map((element: string) => (
                <MenuItemForSelect key={v4()} value={element}>
                  {element}
                </MenuItemForSelect>
              ))}
            </CustomFormSelect>
          </FormControl>
        </GridItem>
      </>
    )
  }

  renderStateCountryCityOption = (option: CombineLocationType) => {
    const country = option as ICountry
    const countryFlag = this.getCountryFlag(country.isoCode)
    return (
      <Box className={`register_airport_option`}>
        <Box fontSize={"1.5rem"}>
          {countryFlag}
        </Box>
        <Box>{option.name}</Box>
      </Box>
    )
  }

  renderAvatar = (image: string | Blob) => {

    return <Avatar style={{width:"100%",height:"100%"}} src={this.getUrl(image)} className="profile_avatar"/>;
}

renderEditEmailModal = ()=>{
  return <MuiModal
      open = {this.state.updateEmailModal}
      onClose={() => {this.handleUpdateEmailModal(false)}}
  >
    <Formik
      initialValues={{email:''}}
      innerRef={(formikProps)=>{
        this.emailFormik = formikProps as FormikProps<unknown>
      }}
      onSubmit={(values)=>{        
        this.handleUpdateEmail(values.email);
      }}
      validationSchema={emailValidationSchema}
      validateOnChange
      validateOnBlur

    >
      {({handleChange,touched,values,setFieldValue,errors,handleSubmit})=>(
        <StyledModalContent>
             <ModalHeading>New Email</ModalHeading>
             <Box className='profile_gen_modal_margin'>Enter your new email address</Box>
             <Box>        

                  <CustomLabel>Email</CustomLabel>
                  <CustomFormTextField
                  autoComplete="off"
                  className={touched.email && errors.email ? "login_border_error" : ""}
                  placeholder="Enter New Email"
                  size="medium"
                  fullWidth
                  value={values.email}
                  name = {'email'}
                  id = {'email'}
                  onChange={handleChange} 
                  />            
                  <Box className="profile_verification_text">
                    {touched.email && errors.email ? <Box className="login_valid_error">
                        {errors.email}
                    </Box>: <Box>
                        A verification code will be sent to the new email address
                      </Box>}
                  </Box>
                  <Box className="profile_gen_modal_submit_cont">
                  <FormSubmitButton fullWidth className="profile_test_submit email_continue" onClick={()=>{handleSubmit()}} data-test-id="updateEmail">Continue</FormSubmitButton>
                  </Box>                     
             </Box>
        </StyledModalContent>  
      )}

    </Formik>
  </MuiModal>
}

renderBookings = (booking:BookingsData)=>{
  
  return(
  <>
  <TableRow className="dash_table_row dash_table_row_client order_details_row" data-test-id={`${booking.order_id}`} onClick={()=>this.handleNavigations("orderManagement",booking.order_id)}>
  <TableCell className="dash_rounded-row-first-cell">{booking.booking_no}</TableCell>
  <TableCell >{moment(booking.departure_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</TableCell>
  <TableCell id={`trip_type_${booking.order_id}`}>{this.handleTripType(booking.trip_type)}</TableCell>
  <TableCell>${booking.amount}</TableCell>
  <TableCell >{this.getFieldvalue(booking.vendor)}</TableCell>
  <TableCell style={{color:this.handleColorByStatus(booking.payment_status)}} >{this.handleStatus(booking.payment_status)}</TableCell>
  <TableCell style={{color:this.handleColorByStatus(booking.vendor_status)}} id={`vendor_status-${booking.order_id}`}>{this.handleStatus(booking.vendor_status)}</TableCell>                  
 </TableRow>
 <Box height={"10px"} />
</>)
}

renderProfileModal = (photo:Blob|string|null,setFieldValue:setFieldValueType,setFieldError :setFieldErrorType) => {
  return (
    <MuiModal
      open={this.state.editPictureModal}
    >
      <StyledModalContent>
          <ModalHeading>Edit Profile Picture</ModalHeading>
          <Box className="profile_pic_edit_buttons_cont">
          <Button fullWidth className="profile_pic_edit_buttons" onClick={()=>{this.inputFileRef.current?.click()}}>Upload 
              <input
                type="file"
                id="file-input"
                ref={this.inputFileRef}
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                onChange={(
                   event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                   if (
                      event.target.files &&
                      event.target.files.length > 0
                   ) {
                       const file = event?.target?.files[0];
                       const maxSize = 5 * 1024 * 1024;
                       const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
                       if (allowedTypes.includes(file.type) && file.size <= maxSize) {
                           setFieldValue("photo", file);
                           this.handleEditProfilePictureModal(false)
                           
                       } else {
                           setFieldError("photo","Please add valid image");
                       }
                   }
                
                }}
                />
          </Button>
          <Button fullWidth className="profile_pic_edit_buttons" onClick={(event:React.MouseEvent<HTMLButtonElement,MouseEvent>)=>{
            event.stopPropagation()
            if(photo){
            setFieldValue("photo",null)
            this.handleEditProfilePictureModal(false)
            }
          }}>Remove photo</Button>
          </Box>
          <FormSubmitButton id="cancel" onClick={(event:React.MouseEvent<HTMLButtonElement,MouseEvent>)=>{
            event.stopPropagation()
            this.handleEditProfilePictureModal(false)}} style={{color:"white",background:"#3E4247"}}  fullWidth>Cancel</FormSubmitButton>
      </StyledModalContent>
    </MuiModal>
  )
}

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ClientDetailsHeader style={{ zIndex: 3 }}>
          <div className="dFlex">
            <div onClick={()=>this.handleNavigations("Clients")} style={{cursor:"pointer"}} data-test-id="backArrow"><img src={backarrow} alt="" /></div>
            <p className="client-details">Client Details</p>
          </div>
        </ClientDetailsHeader>
        <ClientDetailsContainer>
          {this.state.loader && this.ShowLoader()}
          {!this.state.loader && !this.state.error && (
            <Grid container spacing={2}>
              <Grid item sm={12} lg={3} style={{ zIndex: 2 }}>{!this.state.showEditProfile ? this.renderUserDetails() : this.renderUserProfile()}</Grid>
              <Grid item lg={9}>
                {this.UpcomingsBookingsTable()}
                {this.PreviousBookingsTable()}
                {this.PassengerInfoTable()}
                {this.PaymentInfo()}
              </Grid>
              {this.state.showEditProfile && (
                <div style={{ zIndex: 0, position: "fixed", inset: "0px", background: "rgba(0,0,0,0.7)" }}></div>
              )}
            </Grid>
          )}
          {!this.state.loader && this.state.error && (
            <Typography variant="h6" style={{ color: "#fff", textAlign: "center", margin: "2rem" }}>
              Something went wrong
            </Typography>
          )}
          {this.renderEditEmailModal()}
          
          <MuiModal
          open={this.state.showEmailSentModal}
        >
          <CustomModalContent style={{ padding: 0 }}>          
            <Box>
            <Box className="icon-box">
              <img src={checkboxIcon} width={"120px"} height={"120px"}></img>
              </Box>              
              <CommonBox flexDirection={"column"} className="modal-content"  paddingY={4}>
                <p className="modal-title">Email sent successfully</p>
                <span className="modal-subtitle">{this.state.modalData} reset email has been sent</span>
                <button className="modal-button" onClick={this.closeEmailSentModal} data-test-id="modalButton">Okay</button>
              </CommonBox>
            </Box>
          </CustomModalContent>
        </MuiModal>

        <MuiModal
          open={this.state.showDeactiveAccountModal}
        >
          <CustomModalContent account={true}>          
          <Box className="icon-box">
            <img src={confirmIcon} width={"120px"} height={"120px"}></img>
          </Box>
              <CommonBox flexDirection={"column"} className="modal-content" paddingX={1} paddingY={4}>
                {this.state.upComingBookingData.length > 0 ?(
                <div style={{display:"flex",alignItems:"center",margin:"0 1rem",justifyContent:"center",flexDirection:"column"}}>
                 <span className="modal-subtitle" style={{fontSize:"1.5rem"}}>Account cannot be deactivated</span>
                 <span className="modal-subtitle" style={{fontSize:"1.5rem"}}>with upcoming bookings</span>
                </div>
                ):(
                  <>
                <p className="modal-title">Are you sure?</p>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
                <span className="modal-subtitle">You cannot reactivate an account once</span>
                <span className="modal-subtitle">it is deactivated</span>
                </div>
                  </>
                )}                
                <Box mt={3} display={"flex"} gridGap={"2rem"}>
                {this.state.upComingBookingData.length > 0?<Button onClick={()=>this.handleDeactivateAccountModal("close")} style={{padding:"1rem 7.5rem"}} className="cancel-account" fullWidth>Cancel</Button>:(
                  <>
                  <button className="cancel-account" onClick={()=>this.handleDeactivateAccountModal("close")} data-test-id="cancel" >Cancel</button>
                <button className="account-button" style={{padding:"1rem 4rem"}}  onClick={this.handleDeactiveAccount} data-test-id="confirmButton" disabled={this.state.upComingBookingData.length > 0}>Yes</button>
                  </>
                )}
                </Box>            
              </CommonBox>
            
          </CustomModalContent>
        </MuiModal>
          
        </ClientDetailsContainer>
        {this.renderDeleteConfirmationModal()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End