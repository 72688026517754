import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, SavedCardStateType,CardFormType, FromAndToType, CardsFormType,ClientResponse, SelectedClient,DebitSkyCard, CustomSkyCardsResponse, DebitSkyCardResponse, CustomSkyCard,Filters, SkyCards, HoursSkyCard, Aircraft, SkyCardsResponse, hoursSkyCardsResponse, PurchasedSkyCard, PurchasedSkyCardsResponse, Topup,ExistingCards, TopupError, Aircrafts, SkycardTopup, TopupCustomCardDetails} from "./assets";
import React, { createRef } from "react"
import { getStorageData } from "../../../framework/src/Utilities";
import { FormikProps, FormikValues } from "formik";
import moment from "moment";
import { cleanCardNumber } from "../../../components/src/MuiCustomComponents";
const baseURL = require("../../../framework/src/config.js").baseURL;

const cardInitDetails = {
  amount : null,
  card_number : null,
  name_on_card : null,
  expiry : null,
  security_code : null,
  street_address : null,
  city : null,
  state : null,
  zip_code : null,
  country : null,
  
}

const clientDetails = {
  id: "",
  full_name: "",
  account_id: "",
  cards: []
}
const savedCard = {
  id: "475",
  type: "debit card",
  attributes: {
    cards: {
      card_holder_name: "card name",
      card_number: "3744",
      expiry_date: "03/36",
      card_type: "MasterCard",
      zipcode: "3758374",
      country_code: "ald",
      street_address: "street test",
      city: "city test",
      state: "state test",
      country: "country test",
      country_code_string: "alskd"
    },
    primary_method: true
  }

}
const viewSkycardsHeader = [
  {title:'Account ID',value:'account_id'},
  {title:'Client',value:"client"},
  {title:"Trips Remaining",value:'trip_remaining'},
  {title:"Aircraft Category",value:"aircraft_category"},
  {title:"From > To",value:"from_To"},
  {title:"Date Created",value:"date_created"},
  {title:"Expiry Date",value:"expiry_date"},
  {title:"CSC ID",value:"csc_id"},
  {title:"",value:""}
]

const purchaseSkycardHeader = [
  {title:"Order ID",value:"order_id"},
  {title:"Client",value:"client"},
  {title:"Account ID",value:"account_id"},
  {title:"Sky Card Type",value:"sky_card_type"},
  {title:"Date",value:"date"},
  {title:"Transaction Value",value:"transaction_value"},
  {title:"",value:""}
]

const debitSkyCardsHeader = [
  {title:"Account ID",value:"account_id"},
  {title:"Client",value:"name"},
  {title:"Last Top Up",value:"last_top_up"},
  {title:"Balance",value:"balance"},
  {title:"",value:""}
]

const hoursSkyCardsHeader  = [
  {title:'Account ID',value:'account_id'},
  {title:'Client',value:"name"},
  {title:"Last Top Up",value:'last_top_up'},
  {title:"Aircraft Category",value:"aircraft_category"},
  {title:"Balance",value:"balance"},
  {title:"",value:""}
]
const existingCard = {
  id:"25",
  type:"master",
  attributes:{
    cards:{
      card_holder_name: "Anete blodk",
      card_number:"2845" ,
      expiry_date:"04/78" ,
      card_type:"master" ,
    }
  }
}
const topupDetails={
  id:"35",
  account_id:356,
  client_name:"Annette Black",
  current_balance:"$1,299.00",
  current_top_ups:[],
  cards:[existingCard]
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  skyCardData:CustomSkyCard[],
  sortBy:string,
  loading:boolean,
  cardAction:string,
  anchor:null|HTMLElement,
  appliedFilter:string[],
  savedCard:SavedCardStateType,
  activeFieldName:string,
  cardDetails:CardFormType,
  addSkyCardModal:boolean;
  totalPage:number,
  query:string,
  sortType:string,
  currentPage:number,
  debitSkyCards:DebitSkyCard[],
  hoursSkyCards:HoursSkyCard[],
  airCrafts:Aircraft[],
  purchasedSkyCards:PurchasedSkyCard[]
  fromAirportsData:FromAndToType[],
  toAirportsData:FromAndToType[],
  cardData:CardsFormType,
  aircrafts:string[],
  clientsData:SelectedClient[],
  selectedClient:SelectedClient,
  pay:boolean,
  payType:string,
  amount:number|null,
  cardId:number|null,
  newCardId:number|null,
  topupSkyCardModal:boolean,
  selectedAmount:number|null
  topupDetails:SkycardTopup|Topup|null,
  aircraft:string,
  topupHoursCardId:string,
  hoursBalance:string,
  isFilterModalVisible:boolean,
  aircraftsFilterOption:[] | string[];
  dateFilterOption:[]|string[],

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfcustomskycardsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  newCardFormRef=createRef<FormikProps<CardsFormType>>();
  geSkyCardsAPICallId:string = ""
  timer : NodeJS.Timeout | null = null;
  isFromAirportTouched=true;
  airportsTimer:NodeJS.Timeout|null=null;
  getAirportsAPICallId:string="";
  getClientAPICallID:string = ""
  clientsTimer:NodeJS.Timeout|null=null
  chargeCardFormRef=createRef<FormikProps<SavedCardStateType>>();
  createCardAPICallId:string = "";
  paymentAPICallId:string = "";
  page = window.location.pathname
  airCraftCategoriesApiCallId:string = ""
  topupDetailsAPICallId:string = "";
  topupRef=createRef<FormikProps<FormikValues>>();
  topupCardAPICallId:string = "";
  topupPaymentAPICallId:string="";
  topupNewCardRef=createRef<FormikProps<FormikValues>>()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      skyCardData:[],
      sortBy:this.handleInitialSort(),
      query:"",
      loading:false,
      cardAction:"view",
      anchor:null,
      appliedFilter:this.handleAppliedFilter(),
      currentPage:1,
      savedCard:[],
      activeFieldName:"",
      cardDetails:cardInitDetails,
      addSkyCardModal:false,
      totalPage:0,
      sortType:"asc",
      debitSkyCards:[],
      hoursSkyCards:[],
      airCrafts:[],
      purchasedSkyCards:[],
      fromAirportsData:[],
      toAirportsData:[],
      aircrafts:["Semi jet","Piston","Turbo Prop","Large jet","Midsize jet"],
      cardData:{
       clientName:"",
       accountId:"", 
       fromAirport:{
        id:""
       },
       toAirport:{
        id:""
       },
       noOfTrips:"",
       aircraftCategory:"",
       addInfo:"",
       price:"",
       validFrom:"",
       validTo:"",
         },
      clientsData:[],
      selectedClient:clientDetails,
      pay:false,
      payType:"", 
      amount:null,
      cardId:null,
      newCardId:null,
      topupSkyCardModal:false,
      selectedAmount:null,
      topupDetails:null,
      aircraft:"", 
      topupHoursCardId:"",
      hoursBalance:"",
      isFilterModalVisible:false,
      aircraftsFilterOption: [],
      dateFilterOption:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors && !responseJson.error) {
      if(apiRequestCallId === this.geSkyCardsAPICallId){
        this.handleSkyCardsResponse(responseJson)
      }
      else if(apiRequestCallId === this.getAirportsAPICallId){
        this.handleAirportsResponse(responseJson.airports)

      }

      else if(apiRequestCallId === this.getClientAPICallID){
        this.handleClientResponse(responseJson)
        
      }
      else if(apiRequestCallId === this.createCardAPICallId){
        this.handleCreateCardResponses(responseJson)

      }
      else if(apiRequestCallId === this.paymentAPICallId){
        this.handleCardResponse("pay")
        this.triggerNotification("Card created successfully and Payment done")
      }
      else if(apiRequestCallId === this.airCraftCategoriesApiCallId){
        this.handleAircraftsResponse(responseJson)
      }
      else if(apiRequestCallId === this.topupDetailsAPICallId){
        const topup = {...responseJson.data.attributes,cards:responseJson.data.attributes.payment_methods.cards.data,aircraft:this.state.aircraft,
          topupHoursCardId:this.state.topupHoursCardId,hoursBalance:this.state.hoursBalance}
        this.setState({topupDetails:topup,topupSkyCardModal:true})
      }
      else if(apiRequestCallId === this.topupCardAPICallId){
        this.handletopupCardResponse()
      }
    }
    else if(responseJson.error){
      this.handleTopupErrors(apiRequestCallId,responseJson)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    this.getSkyCards()
    if(this.page === "/Viewcustomskycards" || this.page === "/View25hourskycard"){
      this.getAircraftCategory()
    }

  }

  closeFilters = ()=>{
    if(this.page === "/Viewcustomskycards" || this.page === "/View25hourskycard"){
      this.setState({isFilterModalVisible:false})
    }
    else{
      this.setState({anchor:null})
    }
  }

  openFilters = (element:HTMLElement)=>{
    if(this.page === "/Viewcustomskycards" || this.page === "/View25hourskycard"){
      this.setState({isFilterModalVisible:true})
    }
    else{
      this.setState({anchor:element})
    }
    
  }

  setActiveFieldName = (fieldName:string)=>{
    this.setState({activeFieldName:fieldName})
  }

  handleSkycardModal = (action:boolean)=>{
    this.setState({addSkyCardModal:action})
    
  }

  handleCurrentPage=(currentPage:number)=>{
    this.setState({currentPage});
    this.getSkyCards(undefined,undefined,currentPage);
}

handleQueryDebounce=(event:React.ChangeEvent<HTMLInputElement>)=>{
  const timeToHold = 1500;
  const value = event.target.value;
  if(this.timer){
      clearTimeout(this.timer);
  }
  this.timer = setTimeout(()=>{
      this.handleQuery(value);
  },timeToHold)
}

handleQuery=(query:string)=>{
  
  this.setState({query:query,currentPage:1});
  this.getSkyCards(undefined,query,1);
}


handleSorting = (value: string,sorting=this.state.sortType) => {
  const sortMethod = this.handleSortMethod(value)
  this.setState({sortBy:value,sortType:sortMethod,currentPage:1})
  
  this.getSkyCards(value,undefined,1,undefined,sortMethod)
}

handleSortMethod = (value:string)=>{
  const {sortBy,sortType} = this.state 

  if(value === sortBy){
    if(sortType === "desc"){
      return "asc"
    }
    else{
      return "desc"
    }
  }
  else{
    return "asc"
  }

}



  getSkyCards=async(sorted_column=this.state.sortBy,query=this.state.query,currentPage=this.state.currentPage,number_of_items_per_page=10,sort_type=this.state.sortType,filters=this.state.appliedFilter)=>{
    this.setState({loading:true});
    const filter = this.getFilterString(filters)
    const skyCardsURL = this.handleSkyCardsURL()
    const params = `page_number=${currentPage}&number_of_items_per_page=${number_of_items_per_page}&sort=${sorted_column}&sort_type=${sort_type}&${filter}&query=${query}`;
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      
    this.geSkyCardsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      skyCardsURL + "?" + params
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }    

  handlePageName = ()=>{
    const {location:{pathname}} = window
    if(pathname === "/Viewcustomskycards"){
      return "Custom Sky Cards"
    }
    else if(pathname === "/Viewdebitskycard3"){
      return "Debit Sky Cards"
    }
    else if(pathname === "/View25hourskycard"){
      return "25 Hour Sky Cards"
    }
    else{
      return "Sky Cards Purchases"
    }
  }

  handleCheckboxChange = (event: React.MouseEvent<HTMLElement>, value: string)=>{
    const { appliedFilter } = this.state
    event.stopPropagation();
    if(appliedFilter.includes(value)){
      const updatedFilters = appliedFilter.filter((filter: string) => filter !== value)
      this.setState({ appliedFilter: updatedFilters })
    }
    else{
      this.setState({ appliedFilter: [...appliedFilter, value] })
    }
  }

  handleApplyFilter = ()=>{
    this.getSkyCards(undefined, undefined, 1);
    this.setState({ anchor: null,isFilterModalVisible:false });
  }

  getFilterString = (filters = this.state.appliedFilter)=>{
    let filterQuesry = filters.map(filter => `filter[]=${encodeURIComponent(filter)}`).join('&');
    return filterQuesry;
  }
  handlePlaceholder = ()=>{
    const {location:{pathname}} = window
    if(pathname === "/Viewcustomskycards"){
      return "Enter client name, account ID or csc ID..."
    }
    else if(pathname === "/Viewdebitskycard3" || pathname === "/View25hourskycard"){
      return "Enter client name or account ID..."
    }
    else{
      return "Enter the order ID, account ID or client name..."
    }
  }

  handleAllFilters = ():Filters[]=>{
    const {location:{pathname}} = window
    if(pathname === "/Viewcustomskycards"){
      return [{category:"Aircraft Type",items:this.state.airCrafts},
      {category:"Date",items:[{label:"Expired",value:"expired"},{label:"Not Expired",value:"not_expired"}]}]
    }
    else if(pathname === "/Viewdebitskycard3"){
      return [{label:"With Balance",value:"with_balance"},{label:"No Balance",value:"no_balance"}]
    }
    else if(pathname === "/View25hourskycard"){
      return [{category:"Aircraft Type",items:this.state.airCrafts}]
    }
    else{
      return [{label:"Custom",value:"CustomSkyCard"},{label:"25 Hour",value:"25HourSkyCard"},{label:"Debit",value:"DebitSkyCard"}]
    }
    

  }

  handleAppliedFilter = ()=>{
    const {location:{pathname}} = window
    if(pathname === "/Viewcustomskycards"||pathname === "/View25hourskycard"){
      return []
    }
    else if(pathname === "/Viewdebitskycard3"){
      return ["with_balance","no_balance"]
    }
    else{
      return ["DebitSkyCard","25HourSkyCard","CustomSkyCard"]
    }
  }

  handleTableHeadings = ()=>{
    const {location:{pathname}} = window
    if(pathname === "/Viewcustomskycards"){
      return viewSkycardsHeader
    }
    else if(pathname === "/Customskycardspurchases"){
      return purchaseSkycardHeader
    }

    else if(pathname === "/Viewdebitskycard3"){
      return debitSkyCardsHeader
    }
    else{
      return hoursSkyCardsHeader
    }

  }

  handleSkyCards = ():SkyCards[]=>{
    const {location:{pathname}} = window
    const {skyCardData,debitSkyCards,hoursSkyCards,purchasedSkyCards} = this.state
    if(pathname === "/Viewcustomskycards"){
      return skyCardData
    }
    else if(pathname === "/Customskycardspurchases"){
      return purchasedSkyCards
    }

    else if(pathname === "/Viewdebitskycard3"){
      return debitSkyCards
    }
    else{
      return hoursSkyCards
    }

  }

  handleSkyCardsURL = ()=>{
    const {location:{pathname}} = window
    
    if(pathname === "/Viewcustomskycards"){
      return configJSON.getSkyCardsAPIEndpoint
    }
    else if(pathname === "/View25hourskycard"){
      return configJSON.get25HoursSkyCardsAPIEndpoint

    }
    else if(pathname === "/Viewdebitskycard3"){
      return configJSON.getDebitSkyCardsAPIEndpoint

    }
    else {
      return configJSON.getskyCardsPurchasesAPIEndpoint
    }

  }

  handleInitialSort = ()=>{
    const {location:{pathname}} = window
    
    if(pathname === "/Viewcustomskycards"){
      return "date_created"
    }
    else if(pathname === "/Customskycardspurchases"){
      return "order_id"
    }
    else {
      return "account_id"
    }

  }

  handleSkyCardsResponse = (responseJson:SkyCardsResponse)=>{
    const {location:{pathname}} = window
    
    
    if(pathname === "/Viewcustomskycards"){
      const {total_pages,Custom_forms:{data}} = responseJson as CustomSkyCardsResponse
        this.setState({loading:false,skyCardData:data,totalPage:total_pages})
    }
    else if(pathname === "/Viewdebitskycard3"){
      const {total_pages,data:{data}} = responseJson as DebitSkyCardResponse
      this.setState({loading:false,debitSkyCards:data,totalPage:total_pages})

    }
    else if(pathname === "/View25hourskycard"){
      const {total_pages,data:{data}} = responseJson as hoursSkyCardsResponse
        this.setState({loading:false,hoursSkyCards:data,totalPage:total_pages})
    }
    else {
      const {total_pages,data:{data}} = responseJson as PurchasedSkyCardsResponse
      this.setState({loading:false,purchasedSkyCards:data,totalPage:total_pages})
      
    }

  }

  handleAddCardName = ()=>{
    const {location:{pathname}} = window
    
    if(pathname === "/Viewcustomskycards"){
      return "+ Add New Custom Sky Card"
    }
    else {
      return "+ Add New 25 Hour Sky Card"
    }

  }

  getAircraftCategory = async() => {
    const hoursCards = this.page === "/View25hourskycard"
    
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token:await getStorageData("token")
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.airCraftCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hoursCards?configJSON.hoursCardsFilterAPIEndpoint:configJSON.customSkycardsFilterAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  downloadInvoice = async(invoiceId:string)=>{
    let downloadLink = `${baseURL}/bx_block_cfpaymentgateway33/top_ups/invoice?id=${invoiceId}`
    this.downloadInvoiceUsingLink({pdf_url:downloadLink})
  }

  downloadInvoiceUsingLink=(pdf_object:{pdf_url:string})=>{
    const {pdf_url} = pdf_object;
    const link = document.createElement('a');
    link.href = pdf_url;
    link.download = 'document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

 

  triggerNotification = (toastMessage:string)=>{
    const messageTriger = new Message(getName(MessageEnum.AlertMessage));
    messageTriger.addData(getName(MessageEnum.AlertBodyMessage), toastMessage);
    this.send(messageTriger);
  }

  getAirports = (value:string) => {
   
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAirportsAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchAirportsEndpoint}=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

   handleAirportChange = (valueToSearch:string,isFromAiport:boolean) => {
    this.isFromAirportTouched = isFromAiport
    if (this.airportsTimer) clearTimeout(this.airportsTimer);

    if(valueToSearch.length > 2){
      this.airportsTimer = setTimeout(() => {
        this.getAirports(valueToSearch);
      }, 1000);
    }
  };

  handleAirportsResponse = (airports:FromAndToType[])=>{
    let airportManipulatedData = airports.map((item)=>{
      return this.handlEachAirportData(item);

    })

    if(this.isFromAirportTouched){
      this.setState({fromAirportsData:airportManipulatedData})
      
    }
    else{
      this.setState({toAirportsData:airportManipulatedData})
      
    }
  }

  getOptionLabel = (option:{name:string|null,city:string|null,code:string|null,countryCode:string})=>{

    if(option.city){
      return `${option.code} - ${option.name}, ${option?.countryCode}`
    }
    else{
      return option.name
    }
  
  }

   handlEachAirportData=(eachAirportData:FromAndToType)=>{
    const item = eachAirportData;
    return {name:item.name,city:item.city,countryCode:item.country.code,code:item.icao,id :item.id,iata : item.iata,href:item.href,type:item.type,country:item.country,icao:item.icao}
  }

  formatDate = (date:Date|string) => {
    return date? moment(date).format('MMM D, YYYY'):"" 
  };

  getClient = async (value:string) => {
   
    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getClientAPICallID = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchClientAPIEndpoint}query=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handleClientSearch  = (valueToSearch:string)=>{
    if(this.clientsTimer){
      clearTimeout(this.clientsTimer)
    }
    this.clientsTimer = setTimeout(() => {
      this.getClient(valueToSearch)
      
    }, 1000);
  }

  handleSelectedClient = (client:SelectedClient)=>{
    this.setState({selectedClient:client,clientsData:[]})
    
  }

  handleSelectedAirport = (airportType:string)=>{
    if(airportType === "from"){
      this.setState({fromAirportsData:[]})
    }
    else{
      this.setState({toAirportsData:[]})
    }
  }

  handleClientResponse = (clientResponse:ClientResponse)=>{
    const clients = clientResponse.data.map(client=>(
      {
      id:client.id,
      full_name:client.attributes.full_name,
      account_id:client.attributes.account_id,
      cards:client.attributes.existing_card.cards.data
      }
    ))
    this.setState({clientsData:[...clients]})

  }

  handleCreateCardWithPayment = async (values?:CardFormType,cardType?:string,cardId?:number) => {
    const cardFormik = this.newCardFormRef.current;

    const errors1 = await cardFormik?.validateForm();
    if(Object.keys(errors1 as FormikProps<CardsFormType>).length === 0){
      const cardDetails = values?values:this.state.cardDetails
      this.setState({pay:true,payType:cardType as string,amount:values?.amount as number,cardId:cardId as number,cardDetails:cardDetails},()=>{
        this.createCustomSkyCard(cardFormik?.values as CardsFormType) 
      })
    }
    else{
      const errors = Object.keys(errors1 as FormikProps<CardsFormType>)
      errors.forEach((error)=>{
        this.newCardFormRef.current?.setFieldError(error,errors1?.[error as keyof CardsFormType] as string)
        this.newCardFormRef.current?.setFieldTouched(error,true)
      })
    }
  };

  handleCreateCard = async ()=>{
    const cardFormik = this.newCardFormRef.current;
    cardFormik?.handleSubmit()
  }

  createCustomSkyCard = async (values:CardsFormType)=>{
    const cardData={
        form_attributes: {
          to_airport: values.toAirport.id,
          from_airport: values.fromAirport.id,
          no_of_trips: values.noOfTrips,
          aircraft_type: values.aircraftCategory,
          price: values.price,
          additional_info: values.addInfo,
          valid_from: values.validFrom,
          valid_to: values.validTo,
          account_id: values.accountId
        }
      
    }
    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createCardAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCardAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(cardData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCardData = (customCardId:number)=>{
    const {cardDetails,cardId,payType} = this.state
    if(payType === "new"){
      return{
        id:customCardId,
        data:{
          amount : cardDetails.amount,
          card_number : cardDetails.card_number,
          card_holder_name : cardDetails.name_on_card,
          expiry_date : cardDetails.expiry,
          security_code: cardDetails.security_code,
          street_address : cardDetails.street_address,
          city : cardDetails.city,
          state : cardDetails.state,
          zip_code: cardDetails.zip_code,
          country : cardDetails.country,
        }
      }

    }
    else{
      return{
        id:customCardId,
        card_id:cardId,
        data:{
          amount:1
        }

      }
      
    }

  }

  handlePaymentForCard = async (cardId:number)=>{
    const cardData = this.getCardData(cardId)
    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.paymentAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(cardData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleCardResponse = (type:string)=>{
    const updatedValue = {
      addSkyCardModal:false,activeFieldName:"",selectedClient:clientDetails,fromAirportsData:[],toAirportsData:[],clientsData:[]
    }
    
    if(type === "pay"){
      this.setState(updatedValue)
      const updatedValueWithPayment = {
        ...updatedValue,pay:false,payType:"",amount:null ,cardId:null,cardDetails:cardInitDetails
      }
      this.setState(updatedValueWithPayment)
    }
    else{
      this.setState(updatedValue)
    }

  }

  handleCreateCardResponses = (responseJson:{data:{id:number}})=>{
    if(this.state.pay){
      this.handlePaymentForCard(responseJson.data.id)

    }
    else{
      this.handleCardResponse("withoutPay")
      this.triggerNotification("Card created successfully")
    }

  }

  getTopupData =async (accountId:string,aircraft?:string,balance?:string,skyCardId?:string)=>{
    if(aircraft){
      this.setState({aircraft:aircraft,hoursBalance:balance as string,topupHoursCardId:skyCardId as string})
    }
    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.topupDetailsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.topupDetailsAPIEndpoint}/${accountId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }
  closeTopupModal = ()=>{
    this.setState({topupSkyCardModal:false})
  }

  handleTopup = async()=>{
    this.topupRef.current?.handleSubmit()
  }

  topupWithoutPayment = async(topupType:string,amount:number,values:CardFormType|ExistingCards,trips?:number)=>{
    const topupData=this.page === "/Viewcustomskycards"?this.getToupCustomCardDetails(topupType,amount,values,trips):this.getCardDetail(topupType,amount,values)

    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.topupCardAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.page === "/Viewcustomskycards" ?configJSON.topupCustomCardsAPIEndpoint :configJSON.topupCardAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(topupData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id,requestMessage);

  }

  handletopupCardResponse = ()=>{
    this.triggerNotification("Top Up added successfully")
    this.setState({topupSkyCardModal:false,activeFieldName:"",topupDetails:null})
    this.getSkyCards()
  }

  topupCardWithPayment = async (topupType:string,cardType:string,values:CardFormType) => {
    const cardFormik = this.topupRef.current;
    const errors1 = await cardFormik?.validateForm();
    if(Object.keys(errors1 as {}).length === 0){
      if(topupType === "Debit"){
        this.topupWithoutPayment(cardType,cardFormik?.values.topupAmount,values)
      }
      else if(topupType === "Custom"){
        this.topupWithoutPayment(cardType,cardFormik?.values.price,values,cardFormik?.values.trips)
      }
      else{
        this.handleHourCardTopup(cardType,cardFormik?.values.hours,cardFormik?.values.minutes,values)
      }
    }
    else{
      const errors = Object.keys(errors1 as {})
      errors.forEach((error)=>{
        this.topupRef.current?.setFieldError(error,errors1?.[error as keyof CardsFormType] as string)
        this.topupRef.current?.setFieldTouched(error,true)
      })
    }
  };
  
  getCardDetail = (cardType:string,amount:number,values?:CardFormType|ExistingCards)=>{
    const topup = this.state.topupDetails as Topup
    if(cardType === "existing"){
      const cardDetails = values as ExistingCards
      return{
        id: topup?.debit_sky_card_id,
        amount: amount,
        card_id:cardDetails.card_id
      }
    }
    else if(cardType === "new"){
      const cardDetails = values as CardFormType
      return{
        id: topup?.debit_sky_card_id,
        amount: amount,
        data:this.getCardInfo(cardDetails)
      }
    }
    else{
      return{
        id: topup?.debit_sky_card_id,
        amount: amount,
        process_without_payment:true
      }
    }
    
  }

  handleTopupErrors = (apiRequestCallId:string,responseJson:TopupError)=>{
    if(apiRequestCallId === this.topupCardAPICallId){
      if(responseJson.error.error_code === "00013"||responseJson.error.error_code === "00014"){
        this.topupNewCardRef.current?.setFieldError("card_number","Invalid Card Number")
      }
      else if(responseJson.error.error_message === "cvv is incorrect"){
        this.topupNewCardRef.current?.setFieldError("security_code","Incorrect CVV")
      }
      else if(responseJson.error.error_message === "Credit card has expired"){
        this.topupNewCardRef.current?.setFieldError("expiry","Card has expired")
      }
    }


  }

  getCardInfo = (cardDetails:CardFormType)=>{
    return{
      card_number: cleanCardNumber(String(cardDetails.card_number)),
      card_holder_name: cardDetails.name_on_card,
      expiry_date: cardDetails.expiry,
      security_code: cardDetails.security_code,
      street_address: cardDetails.street_address,
      city: cardDetails.city,
      state: cardDetails.state,
      zip_code: cardDetails.zip_code,
      country: cardDetails.country
    }

  }

  handleHourCardTopup = async(topupType:string,hours:number,minutes:number,values:CardFormType|ExistingCards)=>{
    const topupData= this.getHourCardDetails(topupType,hours,minutes,values)

    const header = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.topupCardAPICallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.topupHourCardAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(topupData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id,requestMessage);

  }
  getHourCardDetails = (cardType:string,hours:number|null,minutes:number|null,values:CardFormType|ExistingCards)=>{
    const topup = this.state.topupDetails as Topup
    const topupDetails = {
      id: topup?.topupHoursCardId,
      account_id:this.state.topupDetails?.account_id,
      hours:hours ?? 0,
      minutes:minutes ?? 0,
    }

    if(cardType === "existing"){
      const cardDetails = values as ExistingCards
      return{
        ...topupDetails,
        card_id:cardDetails.card_id,
        data:{
          amount:String(cardDetails.amount_pay)
        }
      }
    }
    else if(cardType === "new"){
      const cardDetails = values as CardFormType
      const cardInfo = {...this.getCardInfo(cardDetails),amount:String(cardDetails.amount)}
      return{
        ...topupDetails,
        data:{...cardInfo}
      }
    }
    else{
      return{
        ...topupDetails,
        process_without_payment:true
      }
    }

  }

  getBalanceValue = (value:string)=>{
    if(this.page === "/View25hourskycard"){
      if(value === "0Hrs 0Mins"){
        return "0 Hrs"
      }
      return value
    }
    else{
      const balance = Number(value)
      if(balance){
        return `$${value}`
      }
      return "$0"
    }

  }

  handleAircraftsResponse = (responseJson:Aircrafts)=>{
    const hoursCards = this.page === "/View25hourskycard"
    const aircraft = responseJson.data
    const allFilters = hoursCards ? aircraft : [...aircraft, "expired", "not_expired"]
    const aircrafts = responseJson.data.map((aircraft: string) => ({ label: aircraft, value: aircraft }))
    this.setState({ airCrafts: aircrafts, appliedFilter: allFilters })
  }

  handleSkyCardModalType = ()=>{
    if(this.page === "/Viewdebitskycard3"){
      return "Debit"
    }
    else if(this.page === "/View25hourskycard"){
      return "25 Hour"
    }
    else {
      return "Custom"
    }
  }

  handleCustomCardsTopup = (skycard:CustomSkyCard)=>{
    const {id,attributes:{client_name,account_id,from_airport,to_airport,aircraft_category,additional_info,valid_from,existing_card:{cards:{data}},date_created,expiry_date}} = skycard
    const topup={
    skycard_id:id,
    account_id:account_id,
    client_name:client_name,
    cards:data,
    aircraft:aircraft_category,
    from_airport:from_airport.icao,
    to_airport:to_airport.icao,
    additionalInfo:additional_info,
    valid_from:valid_from,
    valid_to:expiry_date
    }
    this.setState({topupDetails:topup,topupSkyCardModal:true})
  }

  getToupCustomCardDetails = (cardType:string,amount:number,values?:CardFormType|ExistingCards|TopupCustomCardDetails,trips?:number)=>{
    const topup = this.state.topupDetails as SkycardTopup
    if(cardType === "existing"){
      const cardDetails = values as ExistingCards
      return{
        id: topup?.skycard_id,
        form_attributes: {
          no_of_trips: trips,
          price: amount,
      },
      data:{
        amount:cardDetails.amount_pay
      },
        card_id:cardDetails.card_id
      }
    }
    else if(cardType === "new"){
      const cardDetails = values as CardFormType
      return{
        id: topup?.skycard_id,
        form_attributes: {
          no_of_trips: trips,
          price: amount,
      },
        data:{...this.getCardInfo(cardDetails),amount:cardDetails.amount}
      }
    }
    else{
      return{
        id: topup?.skycard_id,
        amount: amount,
        no_of_trips:trips,
        process_without_payment:true
      }
    }
    
  }

  // Customizable Area End
}
