import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  PopperProps,
  Divider,
  TextFieldProps,
  FormControl,
  MenuItem,
  ListItem,
  AccordionDetails,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { AddLegtTypeButton, CustomDepartueAiportAutoCompplete,PaymentLabel, PaymentTexfields, CustomRadio, CustomStyledPopper, FilterTypeButton, FormSubmitButton, GoldishSimpleButton, MuiModal, NoRecordFoundRow, renderTableLoader, SearchTextFieldActiveBooking, StyledEditDeleteMenu, StyledModalContent, StyledPagination, SearchStyledAutocomplete, renderOptionFrom, CustomFormTextField, SearchStyledPopper, StyledGrid, CustomTextField, CustomFormSelect, MenuItemForSelect, clientSearchOptions, formatCardNumber, cleanCardNumber, CustomTypography, GenralUsedAccordion, MyAccordionSummary, CustomCheckbox, OutlineButton } from "../../../components/src/MuiCustomComponents";
import { customSkyCardSchema,cardFormValidationSchema, existingCardSchema } from "../../../components/src/Validations";
import {  DateRangeOutlined, FilterList, Flight, KeyboardArrowDown, RoomOutlined, Search as SearchIcon, SearchOutlined, SwapVert,} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import {theme as colors} from "../../../components/src/utils";

import { Formik, FormikProps } from "formik";
import { Country, ICountry } from "country-state-city";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { Card,Client,CombinedTextfieldType,master_image,NoOfTripsIcon,PaymentTextFieldType, PaymentWithLabelCompFocusType,SelectedClient, TouchedMessage, Aircraft,SkyCards, SkyCardsFilter, DebitSkyCardFilter, Filters, } from "./assets";
import { TopupSkyCardModal } from "../../../components/src/ReUsableModal";

type FilterInitialValuesType = FormikProps<{
  selected_categories: string[]
  no_of_stops : string[]
}>;
// Customizable Area End

import CfcustomskycardsController, {
  Props,
  configJSON,
} from "./CfcustomskycardsController";

export default class Cfcustomskycards extends CfcustomskycardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainContainer=()=>{
    return <Box className='dash_main booking_main'>
      {this.renderAdminListHeader()}
      {this.renderAdminListTable()}
    </Box>
  }

  renderAdminListHeader=()=>{
    const {anchor,appliedFilter} = this.state
    const allFilters:Filters[] = this.handleAllFilters()
    return <Grid container justifyContent="space-between" style={{alignItems:'center',rowGap:'1rem'}}>
      <Grid item  md={3} sm={6} >
        <GoldishSimpleButton
          style={{
            color: "#fff",
            textTransform: "capitalize",
            justifyContent: "start",
            fontSize: "1.5rem",
          }}
          className='cf-avinode-search'
        >
          {this.handlePageName()}
        </GoldishSimpleButton>
      </Grid>
      <Grid item md={6} justifyContent="center">
        <SearchTextFieldActiveBooking
          fullWidth
          data-testid='search_field'
          onChange={this.handleQueryDebounce}
          placeholder={this.handlePlaceholder()}
          className="search_admins"
          style={{ position: "relative", left: "-10px" ,width:'80%'}}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon style={{ color: "#757677" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      
      <Grid item md={3} sm={12}>
        <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "2rem" }}>
          {(this.page === "/Viewcustomskycards" || this.page === "/View25hourskycard") && (
            <AddLegtTypeButton style={{ fontSize: "1rem" }} onClick={() => this.handleSkycardModal(true)} data-test-id="addSkyCard">{this.handleAddCardName()}</AddLegtTypeButton>
          )}
          <FilterTypeButton data-test-id='menu_opener' onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.openFilters(event.currentTarget)}>
            <FilterList style={{ fontSize: "2rem" }} />
          </FilterTypeButton>
          <StyledEditDeleteMenu
            PaperProps={{
              style: {
                width: "12rem",
                marginTop: "7rem",
                padding: "0px 10px",
                overflow: "auto"
              },
            }}
            id='simple-me'
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={this.closeFilters}
          >
            <Box className='order_detail_view_menu_box' maxHeight={"13rem"} overflow={"auto"}>
              {allFilters.map((elem: DebitSkyCardFilter | SkyCardsFilter | Aircraft) => {
                if (!("items" in elem)) {
                  return (
                    <Box className='order_detail_view_menu_item'>
                      <Checkbox
                        checked={appliedFilter.includes(elem.value)}
                        data-test-id='check_box'
                        style={{ color: "white" }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCheckboxChange(event as unknown as React.MouseEvent<HTMLElement, MouseEvent>, elem.value)}
                      />
                      <Box style={{ textTransform: "capitalize" }}>{elem.label}</Box>
                    </Box>

                  )
                }
                
              })}
              {(this.page === "/Viewdebitskycard3" || this.page === "/Customskycardspurchases") && this.renderApplyFilterButton()}
            </Box>
            {(this.page === "/Viewcustomskycards" || this.page === "/View25hourskycard") && this.renderApplyFilterButton()}
          </StyledEditDeleteMenu>
        </Box>
      </Grid>
    </Grid>
  }

  renderAdminListTable=()=>{
    return <TableContainer className="dash_table_flights" style={{marginTop:"2rem"}}>
      <Table style={{borderCollapse:"separate"}}>
        {this.renderTableHeadRow()}
        <Box height={'15px'}/>
        {this.renderTableCellContainer()}
        
      </Table>
      {this.renderPagination()}
    </TableContainer>
  }

  renderTableHeadRow=()=>{
    const tableHeaders = this.handleTableHeadings()
    return <TableHead>
      <TableRow className="dash_table_head" style={{height:"auto"}}>
        {tableHeaders.map((elem)=>this.renderHeadCell(elem))}
      </TableRow>
    </TableHead>
  }

  renderHeadCell=(head:{title:string,value:string})=>{
    const {title,value} = head;
    const {sortBy} = this.state
    return <TableCell style={{borderRadius:"0"}}>
      <Box className="order_detail_table_head_cell">
        <Box>{title}</Box>
        <Box
          id="order_detail_view_menu_sort_button" data-testid="menu_sort_button"
          className={value === sortBy ? 'order_detail_view_menu_sorted' : ''}
          onClick={() => { this.handleSorting(value) }}
        >
          {value ? <SwapVert style={{ color: colors.GOLD_COLOR }} /> : <Box style={{ width: "5rem" }}></Box>}</Box>
      </Box>
    </TableCell>
  }

  renderTableCellContainer=()=>{
    const {loading} = this.state;
    return <>
      {!loading ? this.renderNotLoadingContent() :this.renderLoader()}
    </>
  }

  renderNotLoadingContent=()=>{
    const skyCards:SkyCards[] = this.handleSkyCards()
    return <>
     {skyCards.length !== 0 ? (skyCards).map((elem:SkyCards) =>(
      this.renderSkyCard(elem)))
      : <NoRecordFoundRow>{null}</NoRecordFoundRow>}
    </>
   }

   renderEachRowInfo=(rowInfo:any)=>{
    const {attributes:{account_id,client_name,trips_remaining,aircraft_category,from_airport,to_airport,date_created,expiry_date,CSC_ID}} = rowInfo;
    return <>
      <TableRow className="dash_table_row order_details_row" onClick={()=>{}}>
        <TableCell className="dash_rounded-row-first-cell">{account_id}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{client_name}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{trips_remaining}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{aircraft_category }</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{from_airport.icao} {">"} {to_airport.icao} </TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{date_created}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{expiry_date}</TableCell>
        <TableCell style={{textOverflow:'ellipsis',maxWidth:"0rem",overflow:"hidden"}}>{CSC_ID}</TableCell>
        <TableCell className={`dash_rounded-row-last-cell`} style={{textTransform:'capitalize'}} onClick={()=>this.handleCustomCardsTopup(rowInfo)}>{this.renderTopUpButton()}</TableCell>
      </TableRow>
      <Box height={'0.5rem'}/>
    </>
  }

  renderLoader=()=>{
    const colspan = 6;
    return <TableCell colSpan={colspan} style={{borderBottom:'none'}}>
      {renderTableLoader()}
    </TableCell>
  }

  renderTopUpButton = ()=>{
    return(
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Box style={{border:"1px solid #FFD27E",borderRadius:"0.6rem",color:"#FFD27E",fontSize:"1rem",padding:"0.4rem 1rem",textAlign:"center"}}>Top Up</Box>
      </Box>
    )
  }
  renderDownloadInvoiceButton = (id:string)=>{
    return(
      <Box style={{border:"1px solid #FFD27E",borderRadius:"0.6rem",color:"#FFD27E",fontSize:"1rem",padding:"0.3rem 0.1rem",textAlign:"center" }} onClick={()=>this.downloadInvoice(id)} data-test-id="downloadInvoice">Download Invoice</Box>
    )
  }

  ChargeNewCard = () => {
    const {cardDetails,activeFieldName} = this.state
    return (
      <Box className='order_detail_view_cards_parent booking_section' style={{backgroundColor:'#1A1C1E',paddingTop:"1rem",paddingBottom:"2rem"}}>
        <Box className='order_detail_view_cards_head' style={{fontSize:"1.4rem",fontWeight:600}}>Charge a New Card</Box>
        <Formik
          initialValues={cardDetails}
          onSubmit={(values) => {
            this.handleCreateCardWithPayment({...values,card_number : cleanCardNumber(values.card_number as unknown as string) as unknown as number},"new")
          }}
          enableReinitialize
          validateOnChange
          validateOnBlur
          validationSchema={cardFormValidationSchema}
            >
              {({handleChange,handleSubmit,errors,touched,setFieldValue,values}) => (<>
        <Grid container>
          <Grid
            lg={6} md={6} sm={6} xs={6}
            item
            style={{ paddingRight: "1rem", borderRight: "1px solid #3E4247",rowGap:"1.55rem"}}
            className="booking_section"
          >
            {this.renderTextfield({value:values.amount,formName:"amount", handleChange:handleChange, placeholderText:'Amount', label:"Amount(in $)",touched:touched.amount,message:errors.amount,errors:errors.amount})}
            <Box>
            {this.PaymentFieldWithLabel({value:formatCardNumber(values.card_number),activeFieldName:activeFieldName,setActiveFieldName:this.setActiveFieldName,formName:"card_number",handleChange:handleChange,placeholderText:'card number',label:"Card Number"})}
            {this.ErrorBoxes({touched:touched.card_number,errors:errors.card_number,message:errors.card_number})}
            </Box>
            {this.renderTextfield({value:values.name_on_card,formName:"name_on_card",handleChange:handleChange,placeholderText:"Name",label:"Name on Card",touched:touched.name_on_card,errors:errors.name_on_card,message:errors.name_on_card})} 
          <Box display={'flex'} justifyContent={'space-between'} gridColumnGap={'1rem'}>
          <Box flex={1}>
            {this.PaymentFieldWithLabel({value:values.expiry,activeFieldName:activeFieldName,setActiveFieldName:this.setActiveFieldName,formName:"expiry",handleChange:handleChange,placeholderText:'MM/YY',label:"Expiry Date"})}
            {this.ErrorBoxes({touched:touched.expiry,errors:errors.expiry,message:errors.expiry})}
          </Box>
          <Box flex={1}>
            {this.PaymentFieldWithLabel({value:values.security_code,activeFieldName:activeFieldName,setActiveFieldName:this.setActiveFieldName,formName:"security_code",handleChange:handleChange,placeholderText:"CVV",label:"Security Code"})}
            {this.ErrorBoxes({touched:touched.security_code,errors:errors.security_code,message:errors.security_code})}
          </Box>
          </Box>
          </Grid>
              <Grid
                lg={6} md={6} sm={6} xs={6}
                item
                style={{ paddingLeft: "1rem", rowGap: "1.55rem" }}
                className="booking_section"
              >
                <Box>
                  {this.PaymentFieldWithLabel({ value: values.street_address, activeFieldName: activeFieldName, setActiveFieldName: this.setActiveFieldName, formName: "street_address", handleChange: handleChange, placeholderText: 'Street Address', label: "Street Address" })}
                  {this.ErrorBoxes({ touched: touched.street_address, errors: errors.street_address, message: errors.street_address })}
                </Box>
                <Box>
                  {this.PaymentFieldWithLabel({ value: values.city, activeFieldName: activeFieldName, setActiveFieldName: this.setActiveFieldName, formName: "city", handleChange: handleChange, placeholderText: 'City', label: "City" })}
                  {this.ErrorBoxes({ touched: touched.city, errors: errors.city, message: errors.city })}
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} gridColumnGap={'1rem'}>
                  <Box flex={1}>
                    {this.PaymentFieldWithLabel({ value: values.state, activeFieldName: activeFieldName, setActiveFieldName: this.setActiveFieldName, formName: "state", handleChange: handleChange, placeholderText: 'State', label: "State" })}
                    {this.ErrorBoxes({ touched: touched.state, errors: errors.state, message: errors.state })}
                  </Box>
                  <Box flex={1}>
                    {this.PaymentFieldWithLabel({ value: values.zip_code, activeFieldName: activeFieldName, setActiveFieldName: this.setActiveFieldName, formName: "zip_code", handleChange: handleChange, placeholderText: 'Zip Code', label: "Zip Code" })}
                    {this.ErrorBoxes({ touched: touched.zip_code, errors: errors.zip_code, message: errors.zip_code })}
                  </Box>
                </Box>
                <Box>
                  <PaymentLabel isFocused={activeFieldName === 'country'}>Country</PaymentLabel>
                  {this.autoComplete({ setFieldValue: setFieldValue, field: "country", placeholder: "Choose Country" })}
                  {this.ErrorBoxes({ touched: touched.country, errors: errors.country, message: errors.country })}
                </Box>
              </Grid>
                 </Grid>
                 <FormSubmitButton   data-test-id = {'process_payment'} onClick={()=>handleSubmit()} fullWidth style={{marginTop:'1rem'}}>Process Payment</FormSubmitButton>
              </>)}
        </Formik>
      
  
      </Box>
    )
  }
   CountryOption=({option}:{option:ICountry})=>{
    return (
      <Box className="register_airport_option">
         <Box color={'#636E72'}>
           <RoomOutlined/>
         </Box>
         <Box>{option.name}</Box>
      </Box>
    )
  }
  PaymentFieldWithLabel=({value,activeFieldName,setActiveFieldName,label,handleChange,placeholderText,formName}:PaymentTextFieldType & {setActiveFieldName : (action:string)=>void,
    activeFieldName : string})=>{
      const cardInputProp = formName === 'card_number' ? {maxLength : 19} : {};
    return <>
    <PaymentLabel isFocused = {activeFieldName === formName} >{label}</PaymentLabel>
    <PaymentTexfields 
       isFocusedTypeInput={true}
       isErrorTouch = {false}
       variant='outlined'
       name={formName}
       onChange={handleChange}
       placeholder={`Enter ${placeholderText}`} 
       fullWidth
       value={value}
       type={(formName==="amount" || formName === "amount_pay") ? "number":"text"}
       autoComplete='off'
       onBlur= {()=>setActiveFieldName('')}
       onFocus= {()=>setActiveFieldName(formName)}
       data-test-id = {formName}
       InputProps={{
        inputProps : {
          ...cardInputProp,
          style:{padding:'0px'}
        }
       }}
       />
  </>
  }

  ErrorBoxes = ({touched,errors,message}:{touched: TouchedMessage,errors : string | undefined,message:string | undefined})=>{
    return <>
      {touched && errors && (
        <Box className="login_valid_error" id = 'profile_error_properties'>
          {message}*
        </Box>
      )}
    </>
  }

  addCard = ()=>{
    const {activeFieldName,fromAirportsData,cardData,clientsData} = this.state
    
    return(
      <Box className='order_detail_view_cards_parent booking_section' style={{backgroundColor:'rgb(40,43,46)'}}>
        <Box className='order_detail_view_cards_head' style={{fontSize:"1.4rem",fontWeight:600}}>Add Custom Sky Card</Box>
        <Formik
              initialValues={cardData}
              onSubmit={(values) => {
                this.createCustomSkyCard(values)
              }}
              enableReinitialize
              validateOnChange
              validateOnBlur
              validationSchema={customSkyCardSchema}
              innerRef={this.newCardFormRef}
            >
              {({handleChange,handleSubmit,errors,touched,setFieldValue,values}) => (<>
        <Grid container>
          <Grid item sm={12} md={12} lg={12} xl={12}
          style={{padding:"1rem 0 1.6rem 0"}}
          >
        <SearchStyledAutocomplete 
        options={[...clientsData]}
        isErrorBorder={true}
        id="globalSearch"
        PopperComponent={SearchStyledPopper}
        onChange={(event: React.ChangeEvent<{}>, newValue:SelectedClient) => {
          setFieldValue("clientName",newValue.full_name)
          setFieldValue("accountId",newValue.account_id)
          this.handleSelectedClient(newValue)
        }}
        filterOptions= {(option:Client)=>option}
        navbar={true}
        ListboxProps={{ id: "flight_list_container" }}
        renderOption={(option: any,{selected}:any) =>clientSearchOptions(option) }
        renderInput={(params:TextFieldProps) => (
          <CustomFormTextField
            {...params}
            client={true}
            variant="outlined"
            placeholder="Search account ID and client name ..."
            onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.handleClientSearch(event.target.value)}
            isFocusedTypeInput={true}
            id="clientSearch"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                <InputAdornment position="start">
                  <SearchIcon style={{color:'#64748B'}} />
                </InputAdornment>
                  
                </>
              ),
            }}
          />
        )}
      />
          </Grid>
          <Grid
            lg={6} md={6} sm={6} xs={6}
            item
            style={{ paddingRight: "1rem",rowGap:"1.55rem"}}
            className="booking_section"
          >
          <Box>
            <PaymentLabel>Client</PaymentLabel>
            <PaymentLabel style={{color:"white",fontSize:"1.2rem",fontWeight:400}}>{values.clientName}</PaymentLabel>
            {touched.clientName && errors.clientName  &&(
              <Box id = 'profile_error_properties' pb={1}>
              {errors.clientName}
            </Box>
            )}
          </Box>
            <Box>
              {this.LabelCompWithFocus({label:"From", activeField:activeFieldName, currentField:"fromAirport"})}
                  <CustomDepartueAiportAutoCompplete
                    onChange={(airport: Event, newValue: any) => {
                      setFieldValue("fromAirport", newValue)
                      this.handleSelectedAirport("from")
                    }}
                    isFocusedTypeInput={activeFieldName === "fromAirport"}
                    showError={touched.fromAirport && errors.fromAirport}
                    actionType={"add"}
                    height={"3rem"}
                    onFocus={() => this.setActiveFieldName("fromAirport")}
                    onBlur={() => this.setActiveFieldName("")}
                    id="fromAirport"
                    PopperComponent={(props: any) => (
                      <CustomStyledPopper highIndex={9999}{...props} top="calc(100% + 8px)" left={0} marginTop={"0"} border={true} />
                    )}
                    paddingRight={"0px"}
                    ListboxProps={{ id: "regsiter_option_style", style: { zIndex: 9999 } }}
                    getOptionLabel={(option: any) => this.getOptionLabel(option)}
                    filterOptions={(option: any) => option}
                    options={[...fromAirportsData]}
                    renderOption={(option: any, { selected }: any) => renderOptionFrom(option, selected)}
                    value={values.fromAirport}
                    renderInput={(params: any) => (
                      <CustomFormTextField
                        id="fromAirports"
                        padding={"0"}
                        {...params}
                        placeholder='Choose from'
                        height={"3.1rem"}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Box className='profile_hover_icons' onClick={() => {
                              values.fromAirport && (setFieldValue("fromAirport", null))
                            }}
                              style={{ cursor: 'pointer', zIndex: 1, position: "relative", top: 0 }}>
                              {!values.fromAirport && <KeyboardArrowDown />}
                            </Box>
                          ),

                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAirportChange(event.target.value, true)}
                      />
                    )}
                  />
                  {this.ErrorBoxes({touched:touched.fromAirport?.id, errors:errors.fromAirport?.id  , message:errors.fromAirport?.id })}
            </Box>
            <Box pt={1}>
            {this.LabelCompWithFocus({label:"Number of Trips", activeField:activeFieldName, currentField:"noOfTrips"})}
            <CustomTextField 
              padding={"0"}
              height={"3.2rem"}
              placeholder='Enter Number of Trips' 
              fullWidth
              actionType={"edit"}
              editInfo={true}
              name="noOfTrips"
              value={values.noOfTrips} 
              id="noOfTrips"                 
              onChange={handleChange}
              onFocus={()=>this.setActiveFieldName("noOfTrips")}
              onBlur={()=>this.setActiveFieldName("")}
              showError={touched.noOfTrips && errors.noOfTrips}
              isFocusedTypeInput = {activeFieldName==="noOfTrips"}
              InputProps={{
                endAdornment: <InputAdornment position="end" style={{paddingRight:"12px"}}><NoOfTripsIcon/></InputAdornment>,
              }}
            />
            {this.ErrorBoxes({touched:touched.noOfTrips, errors:errors.noOfTrips, message:errors.noOfTrips})}
            </Box>
            <Box pt={1}>
            {this.LabelCompWithFocus({label:"Additional Info", activeField:activeFieldName, currentField:"addInfo"})}
            <CustomTextField 
              padding={"0"}
              height={"3.2rem"}
              placeholder='Enter additional info' 
              fullWidth
              actionType={"edit"}
              editInfo={true}
              name="addInfo"
              value={values.addInfo} 
              id="addInfo"                 
              onChange={handleChange}
              onFocus={()=>this.setActiveFieldName("addInfo")}
              onBlur={()=>this.setActiveFieldName("")}
              showError={touched.addInfo&& errors.addInfo}
              isFocusedTypeInput = {activeFieldName==="addInfo"}
            />
            {this.ErrorBoxes({touched:touched.addInfo, errors:errors.addInfo, message:errors.addInfo})}
            </Box>
            <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth} style={{paddingTop:"0.4rem"}}>
            <PaymentLabel isFocused = {activeFieldName === "validFrom"} >Valid From</PaymentLabel>
              <DatePicker
                onChange={(date) => {
                  setFieldValue("validFrom",date)
                  this.setActiveFieldName("")}}
                  onClickOutside={()=>this.setActiveFieldName("")}
                name={"validFrom"}
                className='advance_search_date_pick'
                timeInputLabel='Time'
                dateFormat={"MM/yyyy | h:mm aa"}
                showTimeInput={false}
                placeholderText='Enter Date'
                value={this.formatDate(values.validFrom)}
                disabled={false}
                customInput={
                  <CustomTextField
                    height={"3.1rem"}
                    actionType={"edit"}
                    editInfo={true}
                    placeholder='Enter date'
                    className={`login_test_password`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <DateRangeOutlined />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    isFocusedTypeInput={activeFieldName==="validFrom"}
                    showError={touched.validFrom && errors.validFrom}
                    padding={true}
                  />
                }
                id="advance_search_date_pick"
                onFocus={()=>this.setActiveFieldName("validFrom")}
                onBlur={()=>this.setActiveFieldName("")}
              />
              {this.ErrorBoxes({touched:touched.validFrom, errors:errors.validFrom, message:errors.validFrom})}
            </StyledGrid>
                  
          </Grid>
          <Grid
            lg={6} md={6} sm={6} xs={6}
            item
            style={{ paddingLeft: "1rem",rowGap:"1.55rem" }}
            className="booking_section"
          >
            <Box>
              <PaymentLabel>Account ID</PaymentLabel>
              <PaymentLabel style={{color:"white",fontSize:"1.2rem",fontWeight:400}}>{values.accountId}</PaymentLabel>
              {touched.accountId && errors.accountId &&(
              <Box id = 'profile_error_properties' pb={1}>
              {errors.accountId}
            </Box>
            )}
          
          </Box>
            <Box>
              <PaymentLabel isFocused = {activeFieldName === "toAirport"}>To</PaymentLabel>
              <CustomDepartueAiportAutoCompplete
                onChange={(airport: Event, newValue: any) => { 
                  setFieldValue("toAirport", newValue)
                  this.handleSelectedAirport("to") }}
                isFocusedTypeInput={activeFieldName==="toAirport"}
                showError={touched.toAirport && errors.toAirport}
                actionType = {"add"}
                height={"3rem"}
                onFocus={()=>this.setActiveFieldName("toAirport")}
                onBlur={()=>this.setActiveFieldName("")}
                id="toAirport"
                PopperComponent={(props: any) => (
                  <CustomStyledPopper highIndex={9999}{...props} top="calc(100% + 8px)" left={0} marginTop={"-0px"} border={true} />
                )}
                paddingRight={"0px"}
                ListboxProps={{ id: "regsiter_option_style", style: { zIndex: 9999 } }}
                getOptionLabel={(option: any) => this.getOptionLabel(option)}
                filterOptions={(option:any)=>option}
                options={[...this.state.toAirportsData]}
                renderOption={(option: any, { selected }: any) => renderOptionFrom(option, selected)}
                value={values.toAirport}
                renderInput={(params: any) => (
                  <CustomFormTextField
                    {...params}
                    placeholder='Choose to'
                    height={"3.1rem"}
                    padding={"0"}
                    id="toAirports"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Box className='profile_hover_icons' onClick={()=>{
                          values.toAirport && (setFieldValue("toAirport",null))
                        }}
                        style={{cursor:'pointer', zIndex:1,position:"relative",top:0}}>
                          {!values.toAirport && <KeyboardArrowDown/>}
                        </Box>
                      ),
                      
                    }}
                    onChange={(event:React.ChangeEvent<HTMLInputElement>)=>this.handleAirportChange(event.target.value,false)}
                  />
                  )}
                />
                {this.ErrorBoxes({touched:touched.toAirport?.id, errors:errors.toAirport?.id, message:errors.toAirport?.id})}
                </Box>
                <Box pt={1}>
                  <PaymentLabel isFocused = {activeFieldName === 'aircraftCategory'}>Aircraft Category</PaymentLabel>
                  <FormControl placeholder="Please Verify" fullWidth>
                     <CustomFormSelect
                       height={"3.1rem"}
                       fontSize={"1rem"}
                       labelId="demo-simple-select-label"
                       variant="outlined"
                       onFocus={()=>this.setActiveFieldName("aircraftCategory")}
                       onBlur={()=>this.setActiveFieldName("")}
                       isFocusedTypeInput={activeFieldName === "aircraftCategory"}
                       className="profile_customSelect"
                       name="aircraftCategory"
                       id="aircraftCategory"
                       value={values.aircraftCategory || 'Please Specify'}
                       onChange={handleChange}
                       inputProps={{
                         IconComponent: KeyboardArrowDown, 
                       }}
                       MenuProps={{
                         getContentAnchorEl: null,
                         className: "profile_ditry_menu",
                         style:{marginTop:"-5px"},
                         anchorOrigin: {
                           vertical: "bottom",
                           horizontal: "left",
                         },
                         transformOrigin: {
                           vertical: "top",
                           horizontal: "left",
                         },
                         
                       }}
                       
                     >
                       <MenuItem
                         style={{ display: "none" }}
                         value={"Please Specify"}
                       >
                         Please Specify
                       </MenuItem>
                       {this.state.aircrafts.map((element) => (
                         <MenuItemForSelect key={element} value={element}>
                         {element}
                       </MenuItemForSelect>
                       ))}
               </CustomFormSelect>
            </FormControl>
                  {this.ErrorBoxes({touched:touched.aircraftCategory, errors:errors.aircraftCategory, message:errors.aircraftCategory})}
                </Box>
                <Box>
            <Box pt={1}>
            {this.LabelCompWithFocus({label:"Price", activeField:activeFieldName, currentField:"price"})}
            <CustomTextField 
              padding={"0"}
              height={"3.2rem"}
              name="price"
              placeholder='Enter Price' 
              fullWidth
              actionType={"edit"}
              editInfo={true}
              value={values.price} 
              id="price"                
              onChange={handleChange}
              onFocus={()=>this.setActiveFieldName("price")}
              onBlur={()=>this.setActiveFieldName("")}
              showError={touched.price&& errors.price}
              isFocusedTypeInput = {activeFieldName==="price"}
              InputProps={{
                endAdornment: <InputAdornment position="start"><Box color={'white'}>$</Box></InputAdornment>,
              }}
            />
            {this.ErrorBoxes({touched:touched.price, errors:errors.price, message:errors.price})}
            </Box>
                  
            </Box>
  
                <Box pt={1}>
                <StyledGrid popperWidth = {document.querySelector('.react-datepicker-wrapper')?.clientWidth}>
                 <PaymentLabel isFocused = {activeFieldName === "validTo"} >Valid To</PaymentLabel>
                  <DatePicker
                    onChange={(date) => {
                      setFieldValue("validTo",date)
                      this.setActiveFieldName("")}}
                    onClickOutside={()=>this.setActiveFieldName("")}
                    name={"validTo"}
                    className='advance_search_date_pick'
                    timeInputLabel='Time'
                    dateFormat={"MM/yyyy | h:mm aa"}
                    showTimeInput={false}
                    placeholderText='Enter Date'
                    value={this.formatDate(values.validTo)}
                    disabled={false}
                    customInput={
                      <CustomTextField
                        height={"3.1rem"}
                        actionType={"edit"}
                        editInfo={true}
                        placeholder='Enter date'
                        className={`login_test_password`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <DateRangeOutlined />
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        isFocusedTypeInput={activeFieldName==="validTo"}
                        showError={touched.validTo && errors.validTo}
                        padding={true}
                      />
                    }
                    id="validTo"
                    onFocus={()=>this.setActiveFieldName("validTo")}
                    onBlur={()=>this.setActiveFieldName("")}
                  />
                  </StyledGrid>
                  {this.ErrorBoxes({touched:touched.validTo, errors:errors.validTo, message:errors.validTo})}
  
                </Box>
  
          </Grid>
          </Grid>
  
              </>)}
        </Formik>
      
  
      </Box>
    )
  }

  chargeExisting=()=>{
    const {selectedClient,activeFieldName} = this.state
    const init_values  = {
      amount_pay:null,
      card_id:null
    }
    return <Formik
    initialValues={init_values}
    onSubmit={(values) => {
      this.handleCreateCardWithPayment(undefined,"existing",Number(values.card_id))
    }}
    validationSchema={existingCardSchema}
    enableReinitialize
    validateOnChange
    validateOnBlur
  >
    {({handleChange,handleSubmit,errors,touched,setFieldValue,values}) => (<>
      <Box className="order_detail_view_cards_parent booking_section" style={{rowGap:"1.85rem",backgroundColor:'#1A1C1E',paddingBottom:"2rem"}}>
      <Box className="order_detail_view_cards_head" style={{fontSize:"1.4rem",fontWeight:600}}>Charge an Existing Card</Box>
      <Box>
            {this.PaymentFieldWithLabel({value:values.amount_pay,activeFieldName:activeFieldName,setActiveFieldName:this.setActiveFieldName,formName:"amount_pay",handleChange:handleChange,placeholderText:"Amount",label:"Amount(in $)"})}
            {this.ErrorBoxes({touched:touched.amount_pay,errors:errors.amount_pay,message:errors.amount_pay})}
      </Box>
      <Box className="order_detail_last_child" borderRadius={'10px'} overflow={'auto'} height={'16rem'} bgcolor={'black'}>
        {selectedClient.cards.map((cardSave)=>(
          
             this.paymentRadios({handleChangeCard:()=>setFieldValue('card_id',cardSave.id),cardDetails:cardSave,isChecked:cardSave.id===values.card_id})
          
        ))}
      </Box>
      <FormSubmitButton onClick={()=>handleSubmit()} disabled={!Boolean((values.amount_pay && values.card_id))} fullWidth>Process Payment</FormSubmitButton>
    </Box>
    </>)}</Formik>
  
  }

   paymentRadios=({cardDetails,isChecked,handleChangeCard}:{cardDetails:Card,isChecked:boolean,handleChangeCard:()=>void})=>{

    const {attributes:{cards:{card_number,card_type,expiry_date}}} = cardDetails;
      return <Box className="order_detail_view_radio_parent">
          <CustomRadio checked={isChecked} color="primary" onChange={handleChangeCard} data-testid = {'process_payment_existing_radio'}/>
          <Box className="order_detail_view_image_parent"><img style={{objectFit:"cover"}} src={master_image} height={'80%'} width={'100%'}/></Box>
          <Box className="order_detail_card_name_expiry_parent" marginLeft={'1rem'}>
            <Box>{card_type} ending in {card_number}</Box>
            <Box className="order_detail_expiry_font">Exp date: {expiry_date}</Box>
          </Box>
      </Box>
  }

  autoComplete = ({setFieldValue,field,placeholder}:{setFieldValue:(field:string,value:string)=>void,field:string,placeholder:string})=>{
    const {activeFieldName} = this.state
    return(
      <CustomDepartueAiportAutoCompplete
        bgColor={"inherit"}
        height={'3rem'}
        onChange={(_event: Event, newValue: ICountry) => {
          setFieldValue(field, newValue.name);
        }}
        data-test-id={field}
        isFocusedTypeInput={activeFieldName === field}
        onBlur={() => this.setActiveFieldName('')}
        onFocus={() => this.setActiveFieldName(field)}
        id='profile_country_selector'
        PopperComponent={(props: PopperProps) => (
          <CustomStyledPopper
            {...props}
            zIndex={1}
            marginTop="1px"
            highIndex={9999}
          />
        )}
        ListboxProps={{ id: "regsiter_option_style" }}
        getOptionLabel={(option: ICountry) => option.name}
        options={Country.getAllCountries()}
        renderOption={(
          option: ICountry,
          { selected }: { selected: boolean },
        ) => this.CountryOption({ option: option })}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <PaymentTexfields
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Box className="profile_hover_icons">
                  <KeyboardArrowDown />
                </Box>
              ),
              startAdornment: (
                <Box>
                  {field === "country" && <SearchOutlined />}
                </Box>
              ),
            }}
          />
        )}
      />
    )
  }

  renderTextfield = ({value,formName,handleChange,placeholderText,label,touched,errors}:CombinedTextfieldType )=>{
    const {activeFieldName} = this.state
    return(
      <Box>
            {this.PaymentFieldWithLabel({value:value,activeFieldName:activeFieldName, setActiveFieldName:this.setActiveFieldName, formName:formName, handleChange:handleChange, placeholderText:placeholderText, label:label})}
            {this.ErrorBoxes({touched:touched, errors:errors, message:errors})}
      </Box>
    )
  }

  renderPagination=()=>{
    const {totalPage, currentPage} = this.state;
    return <Box marginTop={'1rem'} alignSelf={"end"}>
      <StyledPagination 
        count={totalPage}
        page={currentPage}
        shape="rounded"
        className="pagination"
        onChange={(_event:React.ChangeEvent<unknown>,page:number)=>{this.handleCurrentPage(page);}}
        >
      </StyledPagination>
    </Box>
  }

   LabelCompWithFocus=({label,activeField,currentField}: PaymentWithLabelCompFocusType)=>{
    return <PaymentLabel isFocused={activeField === currentField}>{label}</PaymentLabel>
  }

  renderSkyCardsPurchasesRow = (rowInfo:any)=>{
    const { attributes: { id,order_id, account_id, client, sky_card_type, created_at, transaction_value } } = rowInfo;
    return(
      <>
      <TableRow className="dash_table_row order_details_row">
        <TableCell className="dash_rounded-row-first-cell">{order_id}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{client}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{account_id}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{sky_card_type}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{moment(created_at).format("DD/MM/YYYY")}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{transaction_value}</TableCell>
        <TableCell className={`dash_rounded-row-last-cell`} style={{ textTransform: 'capitalize', textAlign: "center" }}>{this.renderDownloadInvoiceButton(id)}</TableCell>
      </TableRow>
      <Box height={'0.5rem'} />
      </>
    )

  }

  renderDebitSkyCardsRow = (rowInfo:any)=>{
    const { attributes: { account_id,client_name,balance,last_top_up,name,aircraft_category,hours_sky_card_id}}=rowInfo
    const formattedBalance = this.getBalanceValue(balance)
    const isZero = formattedBalance === "$0" || formattedBalance === "0 Hrs"
    
    
    return(
      <>
      <TableRow className="dash_table_row order_details_row">
        <TableCell className="dash_rounded-row-first-cell">{account_id}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{name?name:client_name}</TableCell>
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{last_top_up!=="NA"?moment(last_top_up).format("MM/DD/YYYY"):last_top_up}</TableCell>
        {this.page === "/View25hourskycard" &&(
          <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden" }}>{aircraft_category}</TableCell>
        )}
        <TableCell style={{ textOverflow: 'ellipsis', maxWidth: "0rem", overflow: "hidden",color:isZero?"#64748b":"#20e70e"}}>{formattedBalance}</TableCell>
        <TableCell onClick={()=>this.getTopupData(account_id,aircraft_category,balance,hours_sky_card_id)} className={`dash_rounded-row-last-cell`} style={{ textTransform: 'capitalize', textAlign: "center" }}>{this.renderTopUpButton()}</TableCell>
      </TableRow>
      <Box height={'0.5rem'} />
      </>
    )

  }

  renderSkyCard = (elem:SkyCards)=>{
    return<>
    {this.page === "/Viewcustomskycards" &&  this.renderEachRowInfo(elem)}
    {this.page === "/Customskycardspurchases" && this.renderSkyCardsPurchasesRow(elem)}
    {this.page === "/Viewdebitskycard3" && this.renderDebitSkyCardsRow(elem)}
    {this.page === "/View25hourskycard" && this.renderDebitSkyCardsRow(elem)}
    </>   
  }

  renderApplyFilterButton = ()=>{
    return(
      <FormSubmitButton
        className='cf_avinode_apply_filter'
        fullWidth
        onClick={this.handleApplyFilter}
        disabled={this.state.appliedFilter.length === 0}
        data-test-id='apply_filter'
      >
        Apply Filters
      </FormSubmitButton>
    )
  }

  renderFilterModal = ()=>{
    const { isFilterModalVisible} = this.state
    const allFilters:any = this.handleAllFilters()
    return (
      <MuiModal
        open={isFilterModalVisible}
      >
        <StyledModalContent className='cf_avindode_filter_modal'>
          <CustomTypography>Filters</CustomTypography>
          {(allFilters.length > 1) && this.renderStopsOrAircraftFilter('date', allFilters[1])}
          {this.renderStopsOrAircraftFilter(
            "aircraft", allFilters[0]
          )}
          {this.renderSubmitFilter()}
        </StyledModalContent>
      </MuiModal>
    )
  }

  renderStopsOrAircraftFilter=(filterType : 'aircraft' | 'date',filters:SkyCardsFilter)=>{
    const isAircraftModalFilter = filterType === 'aircraft'; 
    const heading = isAircraftModalFilter ? 'Aircraft Type': 'Date';
    const {appliedFilter} = this.state
    
    return <GenralUsedAccordion expanded style={{overflow:'visible'}}>
    <MyAccordionSummary
        expandIcon={null}
      aria-controls='panel1a-content'
      id='panel1a-header'
      className="cf_avindode_accordion_header"
    >
      <Box>{heading}</Box>
    </MyAccordionSummary>
    <AccordionDetails className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
      {filters.items.map((elem:any)=><React.Fragment >
        <Box className="cf_avindode_option_container cf-avinode-search">
       <Box>{elem.label}</Box>
       <CustomCheckbox checked={appliedFilter.includes(isAircraftModalFilter?elem.label:elem.value)} data-test-id="check_box" className={`cf_stops_checks_`} 
       onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCheckboxChange(event as unknown as React.MouseEvent<HTMLElement, MouseEvent>, isAircraftModalFilter ? elem.label : elem.value)}/>
    </Box>
      </React.Fragment>)}
    </AccordionDetails>
    </GenralUsedAccordion>
  }


  renderSubmitFilter=()=>{

    return <>
      <FormSubmitButton className="cf_avinode_apply_filter" fullWidth onClick={this.handleApplyFilter}>Apply Filters</FormSubmitButton>
      <OutlineButton fullWidth onClick={() => {this.closeFilters()}} data-test-id="close_filter">Cancel</OutlineButton>
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <Box className="main_cont"> 
        {this.renderMainContainer()}
        <MuiModal open={this.state.addSkyCardModal}>
          <StyledModalContent 
          className='large_modal_styling booking_section' style={{paddingTop:"1.4rem"}}>
            
            {this.addCard()}
            {this.ChargeNewCard()}
            <Divider style={{margin:"1rem 0",backgroundColor:"#636e72",borderWidth:"1px"}}></Divider>
            {this.chargeExisting()}
            <FormSubmitButton style={{background:"none",border:"1px solid #fff",color:"#fff",margin:"1rem 0"}} onClick={()=>{this.handleCreateCard()}} data-test-id="createCard">Process Without Payment</FormSubmitButton>
            <FormSubmitButton style={{background:"none",border:"1px solid #FFD27E",color:"#FFD27E"}} onClick={()=>this.handleCardResponse("withoutPay")} data-test-id="cancel">Cancel</FormSubmitButton>
            
          </StyledModalContent>
        </MuiModal>
        {this.state.topupSkyCardModal && (
          <TopupSkyCardModal 
          topupModal={this.state.topupSkyCardModal}
          skycardType={this.handleSkyCardModalType()}
          activeField={this.state.activeFieldName}
          setActiveFieldName={this.setActiveFieldName}
          topupDetails={this.state.topupDetails}
          topupRef={this.topupRef}
          topup={this.handleTopup}
          topupCard={(this.page === "/Viewdebitskycard3" || this.page === "/Viewcustomskycards")?this.topupWithoutPayment:this.handleHourCardTopup}
          topupCardWithPayment={this.topupCardWithPayment}
          closeModal={this.closeTopupModal}
          topupNewCardRef={this.topupNewCardRef}/>
        )}
        {(this.page === "/Viewcustomskycards" || this.page === "/View25hourskycard") && this.renderFilterModal()}
       </Box>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  dropdown: {
    backgroundColor: '#3E4247',
    marginTop:'-0.5px',
    borderRadius:'5px',
    border:'1px solid white',
    color:'white',
    '&[data-option-selected="true"]': {
      backgroundColor: 'gold', 
      color: 'white',
    }
  },
  noOptions: {
    color: 'white',
  },
  option: {
    '&[data-option-selected="true"]': {
      backgroundColor: 'gold', 
      color: 'white',
    }
  },
};
// Customizable Area End